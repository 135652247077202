import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, Grid,
    Slide, Typography, Box, CircularProgress, FormControl, FormControlLabel, Checkbox, IconButton
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from '../../../../components/Form/TextInput';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import "react-color-palette/css";
import ColorPicker from '../../../../components/Popup/ColorPicker';
import { Lables } from '../../../../api/Endpoints/Label';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});







const Create = (props) => {



    const scheme = yup.object().shape({

        title: yup.string().required("label name is required"),
        // 
        // lead_type: yup.string().required("select lead type"),
        // email: yup.string().required("email is required"),

    })


    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm({
        resolver: yupResolver(scheme), defaultValues: {
            ip_address: '',

        },
        mode: 'all'
    })

    const [open, setOpen] = React.useState(false);
    const [color, setColor] = React.useState('');
    const [bgColor, setbgColor] = useState('')
    const [Loading, setLoading] = useState(false)
    const [checkbox, setCheckBox] = useState(false)
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 30)
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });







    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const dataToSubmit = {
            id: props?.editId,
            title: data.title,
            bg_color: bgColor && bgColor,
            text_color: color && color,
            is_private: checkbox ? 1 : 0

        }



        let action;
        if (props.editId > 0) {
            action = Lables.update(dataToSubmit)
        } else {

            action = Lables.add(dataToSubmit);
        }

        action.then(response => {

            if (response.data.message === "Updated") {
                toast.success("Label Successfully Updated!", { autoClose: 1000 })
            } else {
                if (response.data.status) {
                    toast.success("Label Successfully Saved!", { autoClose: 1000 })
                }

            }

            props.onUpdate();
            props.SidebarRefreshContext(Math.random)
            setOpen(false)
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }).catch(errors => {
            console.log(errors);
        })

    }

    const fetchDetailsAPI = (retryCount = 2) => {
        setLoading(true);

        const fetchDetails = () => {
            Lables.getDetails({ id: props.editId })
                .then((response) => {
                    if (response.data.data) {
                        const label = response.data.data
                        setValue('title', label?.title)
                        setbgColor(label?.bg_color)
                        setColor(label?.text_color)
                        setCheckBox(label?.is_private)
                        // setValue('name', lead?.name)
                        // setValue('phone_number', lead?.phone_number)

                        // setValue('width', lead.width)
                        // setValue('r_rim_size', lead.r_rim_size)
                        // setValue('r_width', lead.r_width)
                        // setValue('r_height', lead.r_height)
                        // setValue('rim_size', lead.rim_size)
                        // setValue('height', lead.height)
                        // setValue('make', lead.make)
                        // setValue('make_year', lead.make_year)
                        // setValue('vin_no', lead.vin_no)
                        // setValue('variant', lead.variant)
                        // setValue('service', lead.service)
                        // setValue('assigned_to_user', lead.assigned_to_user)
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err.message);

                    if (retryCount > 0) {
                        // Retry the request after a delay
                        setTimeout(() => {
                            fetchDetails(retryCount - 1);
                        }, 1000);
                    } else {
                        setLoading(false);
                        console.log("Failed to fetch details");
                    }
                });
        };

        fetchDetails();
    };

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetailsAPI()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);

        }

    }, [props.editId])


    return (
        <div>
            <Button class="create-btn2" variant="contained" onClick={handleClickOpen} >
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Label"}
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {Loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box >
                ) : (
                    <div class="create-frm" >

                        <DialogTitle class="form-title">
                            <Button onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="rgba(0,0,0,1)"></path></svg></Button>
                            {parseInt(props.editId) > 0 ? "Edit Label" : "Create Label"}</DialogTitle>

                        <DialogContent class="right-form">

                            <form onSubmit={handleSubmit(onSubmit)}>

                                <ErrorMessage
                                    errors={errors}
                                    name="multipleErrorInput"
                                    render={({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message}</p>
                                        ))
                                    }
                                />



                                <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>

                                    <Grid sx={{ p: 1 }} item xs={12} sm={12}>
                                        <TextInput control={control} name="title"
                                            label="Label Name"
                                            value={watch('title')} />
                                    </Grid>


                                </Grid>
                                <Grid container spacing={2} sx={{ display: 'flex', mt: 1, height: '50px' }}>
                                    {color || bgColor ?
                                        (
                                            <Grid sx={{ p: 1 }} item xs={12} sm={12} display={'flex'} justifyContent={'center'} >
                                                <Box sx={{ backgroundColor: bgColor && bgColor, padding: '5px', borderRadius: 1, width: '120px' }}>
                                                    <Typography fontWeight={600} color={color && color} textAlign={'center'}>Preview</Typography>
                                                </Box>

                                            </Grid>

                                        ) : null}
                                </Grid>
                                <Grid container spacing={2} sx={{ display: 'flex', mt: 3 }}>

                                    <Grid sx={{ p: 1 }} item xs={12} sm={3}>
                                        <Typography >Text Color :</Typography>
                                    </Grid>
                                    <Grid sx={{ p: 1 }} item xs={12} sm={2}>
                                        <ColorPicker color={color} setColor={setColor} />
                                    </Grid>



                                    <Grid sx={{ p: 1 }} item xs={12} sm={3}>
                                        <Typography>Bg Color :</Typography>
                                    </Grid>
                                    <Grid sx={{ p: 1 }} item xs={12} sm={1}>
                                        <ColorPicker color={bgColor} setColor={setbgColor} />
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkbox}
                                                    onChange={() => setCheckBox(!checkbox)}
                                                />
                                            }
                                            label="Is Private Label"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid sx={{ p: 1 }} item xs={12}>
                                    <LoadingButton class="main-btn2" loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>



                            </form>


                        </DialogContent>
                    </div >
                )
                }
            </Dialog >

        </div >
    );
};

export default Create
