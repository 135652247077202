import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { MenuItem, FormControl, InputLabel, Button, Typography, Popover, Badge } from '@mui/material';
import { Leads } from '../../api/Endpoints/Leads';
import { toast } from 'react-toastify';
import { Watch } from '@mui/icons-material';
import PaletteIcon from '@mui/icons-material/Palette';
import SelectX from './SelectX';
import LabelsList from '../CommonFunctions/LabelsList';
import Select from 'react-select';

const LabelsComponent = ({ lablesList, currentLabel, viewID, func }) => {

    const [label, setLabel] = useState(lablesList);
    const { fetchLabels } = LabelsList();
    const [options, setOptions] = useState([]);
    useEffect(async () => {
        const labelData = await fetchLabels();
        const loadedOptions = labelData && labelData.map((item) => ({
            value: item.id,
            label: item.title,
            color: {
                backgroundColor: item.bg_color,
                textColor: item.text_color,
            },
        }));
        setOptions(loadedOptions)
    }, [currentLabel])







    const handleLabelChange = async (data) => {


        try {
            const dataToSubmit = {
                id: viewID,
                labels_id: data.length > 0 ? data.map((item) => item.value) : null
            };


            const response = await Leads.update(dataToSubmit);

            if (response.data.status == true) {
                toast.success(
                    <span>
                        Label Successfully Updated
                    </span>,
                    { autoClose: 1000 }
                );
            } else {
                toast.error(
                    <span>
                        Something Went Wrong!
                    </span>,
                    { autoClose: 1000 }
                );
            }


            setLabel(response.data.data);
            func && func();
        } catch (err) {
            console.error(err);
        }
    };


    const defaultValues = currentLabel && currentLabel.map((item) => ({
        value: item.id,
        label: item.title,

        color: {
            backgroundColor: item.bg_color,
            textColor: item.text_color,
        },
    }));

    return (
        <>


            < Select
                isMulti
                defaultValue={
                    defaultValues && defaultValues
                }
                options={options}
                onChange={handleLabelChange}
                styles={{
                    multiValue: (base, state) => ({
                        ...base,
                        backgroundColor: state.data.color.backgroundColor,
                    }),
                    multiValueLabel: (base, state) => ({
                        ...base,
                        color: state.data.color.textColor,
                    }),
                    multiValueRemove: (base) => ({
                        ...base,
                        color: 'white'
                    }),
                }}
            />




        </>
    );
};

export default LabelsComponent;
