import { get, post } from './../Config/config';

export const Lables = {
    get: (data) => get(`/label/list`, { params: data }),
    add: (data) => post('/label/create', data),
    getDetails: (data) => get(`/label/details`, { params: data }),
    update: (data) => post('/label/update', data),

    //For filtered Leads 
    getLabelizedLeads: (data) => get(`/label/leads`, { params: data }),


} 