import React from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { Card } from '@mui/material'

function Index() {
    return (

        <div class="page-cntr">


            <div class="lead-table  closed-page-cntr">

                {/* Popup's  */}


                {/* <Restore RestoreID={RestoreID} setRestoreID={setRestoreID} fetchRecycleBin={fetchClosedLeads} /> */}

                <div class="title-sec">

                    <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >
                        <PageHeader title={'Settings'} />
                    </Card>


                </div>
            </div>
        </div>
    )
}

export default Index
