import { get, post } from './../Config/config';


export const CampaignsAPI = {

    get: (data) => get(`campaigns-list`, { params: data }),
    add: (data) => post('/campaigns/create', data),
    getDetails: (data) => get(`/campaigns/edit/${data.id}`,),
    update: (data) => post('/campaigns/update', data),
    getCampaignTypes: (data) => get('/campaign-types', { params: data }),
    getCampginNames: (data) => get('/campaign-dropdown-list?', { params: data }),

    //Campaign Options API
    getCampaignOptions: (data) => get('/campaigns-options-list', { params: data }),
    addCampaignOptions: (data) => post('/campaigns-options/create', data),
    getCampaignOptionsDetails: (data) => get(`/campaigns-options/edit/${data.id}`,),
    updateCampaignOptions: (data) => post('/campaigns-options/update', data),

}


