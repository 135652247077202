import { useEffect, useState } from 'react';
import { Leads } from '../../api/Endpoints/Leads';
import { DynamicdropdownsAPI } from '../../api/Endpoints/DynamicDropdown';

const StatusLIstFunction = () => {
    const [statusList, setStatusList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchStatusList = async () => {
        setIsLoading(true);
        try {
            const response = await DynamicdropdownsAPI.get();
            if (response) {
                setStatusList(response.data.crm_status);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    return { statusList, isLoading, fetchStatusList };
};

export default StatusLIstFunction;


