import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import useimg4 from '../assets/user.png';
import { useAuth } from '../hooks/use-auth';
import { Avatar } from '@mui/material';
import NavDropdown from './NavDropdown';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function PrimarySearchAppBar() {

    const auth = useAuth();


    let UserName = localStorage.getItem('UserName')


    const logoutHandler = () => {
        auth.logout();
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <div class="profile-drop">
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}

            >

                <div class="auth-drop">

                    <IconButton class="user-cntr"
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"

                    >   {UserName ? (
                        <Avatar sx={{ objectFit: 'cover', marginRight: '8px' }}>{UserName.slice(0, 2)}</Avatar>
                    ) : (
                        <Avatar src={useimg4} sx={{ width: 32, height: 32, objectFit: 'cover', marginRight: '8px' }} />
                    )}
                        <span style={{ width: '100%' }}>{UserName}</span>
                    </IconButton>

                    <hr />

                    <MenuItem onClick={logoutHandler}><svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.81837 0.592529H8.45512C9.20259 0.592529 9.91946 0.853249 10.448 1.31733C10.9766 1.78142 11.2735 2.41085 11.2735 3.06716V6.36667H10.334V3.06716C10.334 2.62962 10.1361 2.21 9.78371 1.90061C9.43134 1.59122 8.95343 1.41741 8.45512 1.41741H2.81837C2.32005 1.41741 1.84214 1.59122 1.48978 1.90061C1.13741 2.21 0.939457 2.62962 0.939457 3.06716V13.7906C0.939457 14.2281 1.13741 14.6477 1.48978 14.9571C1.84214 15.2665 2.32005 15.4403 2.81837 15.4403H8.45512C8.95343 15.4403 9.43134 15.2665 9.78371 14.9571C10.1361 14.6477 10.334 14.2281 10.334 13.7906V10.4911H11.2735V13.7906C11.2735 14.4469 10.9766 15.0763 10.448 15.5404C9.91946 16.0045 9.20259 16.2652 8.45512 16.2652H2.81837C2.07089 16.2652 1.35403 16.0045 0.825482 15.5404C0.296935 15.0763 0 14.4469 0 13.7906V3.06716C0 2.41085 0.296935 1.78142 0.825482 1.31733C1.35403 0.853249 2.07089 0.592529 2.81837 0.592529ZM5.63674 8.01642H16.2056L13.1524 5.33557L13.7724 4.71692L18 8.42886L13.7724 12.1408L13.1524 11.5222L16.2056 8.8413H5.63674V8.01642Z" fill="#3C3C3C" />
                    </svg>  Log Out  </MenuItem>
                </div>
            </Menu></div>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">

                <Toolbar>

                    <Box sx={{ flexGrow: 1 }} />
                    <NavDropdown />
                    <Box class="not-cntr" sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            class="user-cntr"
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            {UserName ? (
                                <Avatar sx={{ objectFit: 'cover', marginRight: '8px' }}>{UserName.slice(0, 2)}</Avatar>
                            ) : (
                                <Avatar src={useimg4} sx={{ width: 32, height: 32, objectFit: 'cover', marginRight: '8px' }} />
                            )}
                            <span style={{ width: '100%' }}>{UserName}</span>
                        </IconButton>

                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>

            </AppBar>

            {renderMobileMenu}
            {renderMenu}
        </Box >
    );
}