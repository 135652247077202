import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import PaletteIcon from '@mui/icons-material/Palette';
import { Button, IconButton } from '@mui/material';
function ColorPicker({ color, setColor }) {

    const [showColorPicker, setShowColorPicker] = useState(false);

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
    };

    const toggleColorPicker = (e) => {
        e.preventDefault()
        setShowColorPicker(!showColorPicker);
    };



    return (
        <div>
            <button onClick={toggleColorPicker} style={{
                backgroundColor: 'rgb(224,224,224)',
                color: 'black'

            }} id='color-btn' >
                {color ? color : < PaletteIcon />}
            </button>

            {
                showColorPicker && (
                    <div>
                        <ChromePicker color={color} onChange={handleColorChange} />
                    </div>
                )
            }
        </div >
    );
}

export default ColorPicker;
