
import { useEffect, useState } from 'react';
import { Badge, Box, Button, Card, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import PageHeader from '../../../components/PageHeader/PageHeader';
import CloseLeadPopup from '../../../components/Popup/ClosedLead';
import { Waveform } from '@uiball/loaders'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { GlobalRefreshContext } from '../../../contexts/GlobalRefresh/GlobalRefresh';
import { Fragment } from 'react';
import { FileDownload } from '../../../api/Endpoints/fileDowenold';
import FilterListIcon from '@mui/icons-material/FilterList';
import LeadFilter from '../../../components/Popup/LeadFilter';
import FilterBadges from '../../../components/FilterBadge/FilterBadges';
import { ReportsAPI } from '../../../api/Endpoints/ReportsAPI';
export default function LeadsTable() {

    const { watch, formState: { errors }, control, Controller, setValue, reset } = useForm({})

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    const [loading, setLoading] = useState(false);
    const [leads, setLeads] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [isMounted, setIsMounted] = useState(false);
    const [showClosePopup, setShowClosePopup] = useState(false)
    const [CloseID, setCloseID] = useState(0)
    const { GlobalRefresh } = GlobalRefreshContext();
    const [showFilterPopup, setShowFilterPopup] = useState(false)
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));

    const [filterData, setFilterData] = useState(
        {
            from: '',
            to: '',
            user_id: '',
            lead_source: '',
            campaign_name: ''

        }
    )

    const fetchLeads = async () => {
        try {
            setLoading(true);
            const response = await ReportsAPI.get({
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
                from_date: filterData.from,
                to_date: filterData.to,
                user_id: filterData?.user_id,
                source: filterData?.lead_source,
                campaign_name: filterData.campaign_name
            });
            setLeads(response.data.data);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleFilter = () => {
        setFilterData(
            {
                from: watch('from') ? moment(watch('from')).format('DD-MM-YYYY') : undefined,
                to: watch('to') ? moment(watch('to')).format('DD-MM-YYYY') : undefined,
                user_id: watch('user_id') && watch('user_id').id,
                lead_source: watch('lead_source') && watch('lead_source').map((leadSource) => leadSource.name),
                campaign_name: watch('campaign_name') && watch('campaign_name')?.name
            })
        setShowFilterPopup(false)
        setRefresh(Math.random)
    }







    useEffect(() => {
        setIsMounted(true)
        if (isMounted) {
            fetchLeads();
        }
        return () => {
            setIsMounted(false)
        }
    }, [paginationModel,
        isMounted,
        refresh,
        GlobalRefresh,

    ]);


    const showFilterPopupFunc = () => {
        setShowFilterPopup(true)
    }

    const closeFilterBadge = (filterName) => {
        setFilterData(prevFilterData => ({
            ...prevFilterData,
            [filterName]: null
        }));
        setValue(filterName, null)
        setRefresh(Math.random)
    }



    const fileDownloadHandler = async () => {

        const response = await FileDownload.get({
            from_date: watch('from') ? moment(watch('from')).format('DD-MM-YYYY') : undefined,
            to_date: watch('to') ? moment(watch('to')).format('DD-MM-YYYY') : undefined,
            project_id: projectData?.ID,
        });
        const url = response?.data.export;

        if (url) {
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.setAttribute('download', " ")
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        } else {
            console.error("Invalid URL for file download");
        }


    };


    return (

        <div class="page-cntr">



            <div class="lead-table  lead-page-cntr">

                {/* Popup */}




                {showClosePopup && <CloseLeadPopup id={CloseID} setShowClosePopup={setShowClosePopup} func={fetchLeads} />}

                {showFilterPopup && <LeadFilter open={showFilterPopup} setOpen={setShowFilterPopup} watch={watch} control={control} reset={reset} handleFilter={handleFilter} setFilterData={setFilterData} setValue={setValue} frmReportMenu={true} />}
                < div class="title-sec">
                    <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >
                        <PageHeader title={'Reports'} />

                        <Tooltip title="Filters" arrow>
                            <IconButton className='filter-btn-pop'
                                sx={{ mr: 2 }}

                                edge="end"

                                onClick={showFilterPopupFunc}

                            >

                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>




                        {/* 
                        <Tooltip title="download" arrow>

                            <IconButton className='export-icon'
                                sx={{ mr: 2 }}

                                edge="end"

                                onClick={fileDownloadHandler}

                            >
                                <FileDownloadIcon className='delete-icon' color='red' />
                            </IconButton>
                        </Tooltip> */}




                    </Card>
                    {/* {
                        leads?.data?.length > 0 && !loading ?

                        

                            : null
                    } */}



                </div>


                <Grid container sx={{ display: 'flex', justifyContent: 'end' }}>
                    {filterData?.from && (<FilterBadges label={"From"} value={filterData.from} handleClose={() => closeFilterBadge('from')} />)}

                    {filterData?.to && (<FilterBadges label={"To"} value={filterData.to} handleClose={() => closeFilterBadge('to')} />)}
                    {filterData?.user_id && (<FilterBadges label={"Users Under Me"} value={`${1} option selected`} handleClose={() => closeFilterBadge('user_id')} />)}
                    {filterData?.lead_source?.length > 0 && (<FilterBadges label={"Lead Source"} value={`${filterData?.lead_source.length} option selected`} handleClose={() => closeFilterBadge('lead_source')} />)}
                    {filterData?.campaign_name && (<FilterBadges label={"Campaign"} value={filterData.campaign_name} handleClose={() => closeFilterBadge('campaign_name')} />)}



                </Grid>

                {
                    loading ?
                        <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                        : (
                            <Fragment>

                                {
                                    leads?.status_lead_data
                                        ?.length > 0 ? (
                                        <Fragment>
                                            <Typography component={'h3'} fontWeight={500} mb={2}>Total Leads: {leads?.total_leads}</Typography>
                                            < TableContainer TableContainer component={Paper} className='reports-table'>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell align="left">Count</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {leads?.status_lead_data.map((row) => (
                                                            <TableRow
                                                                key={row.name} // Assuming name is unique, otherwise, use another unique identifier
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {row?.status_name}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.lead_count}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Fragment>
                                    ) : (

                                        <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Report not found</Typography>
                                        </Box>
                                    )
                                }
                            </Fragment>
                        )}
            </div >


        </div >

    )
};
