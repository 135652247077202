import EditIcon from '@mui/icons-material/Edit';
import { Fragment, useEffect, useState } from 'react';
import { Box, Card, IconButton, Menu, MenuItem, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomToolbar from '../../../../components/Tools/CustomeToolbar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import Create from './Create';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import Delete from '../../../../components/Popup/Delete';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { Waveform } from '@uiball/loaders'
import GroupsIcon from '@mui/icons-material/Groups';
import { Lables } from '../../../../api/Endpoints/Label';
import { GlobalRefreshContext } from '../../../../contexts/GlobalRefresh/GlobalRefresh';
import { useLocation } from 'react-router-dom';
import { MenuRefreshContext } from '../../../../contexts/Menurefresh/menuRefresh';


export default function LeadsTable() {
    const columns = [

        {
            field: 'label',
            headerName: 'Label Name',
            width: 300,
            renderCell: (params) => (
                <span
                    id='label_text'
                    style={{
                        backgroundColor: params.row.bg_color,
                        color: params.row.text_color,
                        padding: 5,
                        borderRadius: 12,
                        fontWeight: 600
                    }} > {params.row.title}</ span>

            )
        },


        {
            field: 'text_color', headerName: 'Text Color', width: 200
        },


        {
            field: 'bg_color', headerName: 'Background Color', width: 200
        },

        {
            field: 'isPrivateLabel', headerName: '', width: 100, renderCell: (params) => (
                params.row?.is_private ? <span span style={{ color: 'black', padding: 2, borderRadius: 8, fontWeight: 500 }}> <LockPersonIcon /></span > :
                    <span span style={{ color: 'black', padding: 2, borderRadius: 8, fontWeight: 500 }}> <GroupsIcon /></span >
            )
        },






        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown params={params} />,
        },

    ]


    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 16,
        page: 0,
    });
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [editId, setEditId] = useState();
    const [DeleteID, setDeleteID] = useState(0)
    const [isMounted, setIsMounted] = useState(false);
    const { GlobalRefresh } = GlobalRefreshContext();
    const { setmenuRefresh } = MenuRefreshContext()

    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));






    const MoreOptionsDropdown = ({ params }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = (id) => {
            setEditId(parseInt(id));
            setRefresh(Math.random)
        }

        const handleDelete = (id) => {
            setDeleteID(id)
            handleClose();
        };

        return (
            <>
                <IconButton size="small" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} sx={{ cursor: 'pointer' }}>

                    <MenuItem class="edit-nav-cntr" onClick={() => handleEdit(params.id)}>
                        <EditIcon sx={{ fontSize: 14, mr: 1, }} />
                        Edit
                    </MenuItem>
                    <MenuItem class="edit-nav-cntr delete-sec" onClick={() => handleDelete(params.id)}>
                        <DeleteIcon sx={{ fontSize: 14, mr: 1 }} />
                        Delete
                    </MenuItem>

                </Menu >
            </>
        );
    };
    const location = useLocation();


    useEffect(() => {
        setIsMounted(true)
        if (isMounted) {
            fetchLabels();
        }
        return () => {
            setIsMounted(false)
        }

    }, [paginationModel, isMounted, GlobalRefresh]);


    const fetchLabels = async () => {
        try {
            setLoading(true);
            const response = await Lables.get({ page: parseInt(paginationModel?.page) + 1, crm_lead_type_id: leadTypeData?.ID, project_id: projectData.ID });
            setList(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleCreate = () => {
        setEditId(0)
    }
    const handleUpdate = () => {
        setPaginationModel(0);
    }


    return (

        <div class="page-cntr">



            <div class="lead-table  lead-page-cntr">

                {/* Popup's  */}



                <Delete
                    DeleteID={DeleteID}
                    setDeleteID={setDeleteID}
                    Callfunc={fetchLabels}
                    url={'/label/delete'}
                    title={'Label'}
                    SidebarRefreshContext={setmenuRefresh}
                />





                < div class="title-sec">

                    {
                        list.data ?
                            <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >
                                <PageHeader title={'Labels'} total={list.data?.length} />
                                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} SidebarRefreshContext={setmenuRefresh} />
                            </Card>
                            : null
                    }
                </div>



                {loading ?
                    <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                        <Waveform
                            size={40}
                            lineWeight={3.5}
                            speed={1}
                            color="blue"
                        />
                    </Box>
                    : (


                        <Card sx={{
                            m: 0,
                        }} variant="outlined" class="lead-table" >
                            {typeof list.data === "object" &&
                                <DataGrid
                                    sx={{
                                        width: '100%',
                                    }}
                                    paginationMode="server"
                                    rows={list.data}
                                    paginationModel={paginationModel}
                                    columns={columns}
                                    rowCount={list?.total}
                                    rowHeight={70}
                                    autoHeight={true}
                                    scrollbarSize={10}
                                    density="comfortable"
                                    onPaginationModelChange={setPaginationModel}
                                    disableColumnMenu
                                    disableRowSelectionOnClick
                                    disableExtendRowFullWidth
                                />
                            }
                        </Card >
                    )}
            </div >


        </div >

    )
};
