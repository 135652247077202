import { get, post } from '../Config/config';


export const SiteVisitsAPI = {
    get: (data) => get('/site-visit/list?lead_id=', { params: data }),
    getSiteVisit: (data) => get('/site-visit-leads', { params: data }),
    add: (data) => post('/site-visit/create?', data),
    update: (data) => post('/site-visit/update?', data),
}

