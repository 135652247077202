import { createSvgIcon } from '@mui/material/utils';

export const HomeIcon = createSvgIcon(
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3662 12.0309C20.9727 12.0309 22.0554 11.0284 21.093 7.7401C20.4414 5.52454 18.5366 3.61976 16.321 2.96813C13.0328 2.00572 12.0303 3.08843 12.0303 5.69497V8.58222C12.0303 11.0284 13.0328 12.0309 15.0378 12.0309H18.3662Z" stroke="#FAAB51" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M20.0501 14.7376C19.1178 19.3793 14.6666 22.7477 9.60388 21.9257C5.80435 21.3141 2.74668 18.2565 2.12512 14.4569C1.31308 9.41428 4.66148 4.96312 9.28308 4.02075" stroke="#FAAB51" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


);

export const LeadsIcon = createSvgIcon(
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1354 11.178H7.47879C6.8472 11.178 6.33594 11.6892 6.33594 12.3208V17.4537H10.1354V11.178V11.178Z" stroke="#3B424A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.7932 6.61646H11.2693C10.6377 6.61646 10.1265 7.12775 10.1265 7.75934V17.4437H13.926V7.75934C13.926 7.12775 13.4247 6.61646 12.7932 6.61646Z" stroke="#3B424A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.5898 12.8821H13.9331V17.4436H17.7326V14.025C17.7226 13.3934 17.2113 12.8821 16.5898 12.8821Z" stroke="#3B424A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.02249 22.0554H15.0376C20.0502 22.0554 22.0552 20.0504 22.0552 15.0378V9.02273C22.0552 4.01016 20.0502 2.00513 15.0376 2.00513H9.02249C4.00991 2.00513 2.00488 4.01016 2.00488 9.02273V15.0378C2.00488 20.0504 4.00991 22.0554 9.02249 22.0554Z" stroke="#3B424A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


);
export const FollowUpsIcon = createSvgIcon(
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4 18.4004H2.8C2.46863 18.4004 2.2 18.1318 2.2 17.8004V2.20039C2.2 1.86902 2.46863 1.60039 2.8 1.60039H14.6544C14.8135 1.60039 14.9662 1.6636 15.0787 1.77613L18.8243 5.52171C18.9368 5.63423 19 5.78685 19 5.94598V17.8004C19 18.1318 18.7314 18.4004 18.4 18.4004ZM1 2.20039C1 1.20628 1.80589 0.400391 2.8 0.400391H14.6544C15.1318 0.400391 15.5896 0.590033 15.9272 0.927598L19.6728 4.67318C20.0104 5.01075 20.2 5.46859 20.2 5.94598V17.8004C20.2 18.7945 19.3941 19.6004 18.4 19.6004H2.8C1.80589 19.6004 1 18.7945 1 17.8004V2.20039ZM5.2 5.20039C4.86863 5.20039 4.6 5.46902 4.6 5.80039C4.6 6.13176 4.86863 6.40039 5.2 6.40039H12.4C12.7314 6.40039 13 6.13176 13 5.80039C13 5.46902 12.7314 5.20039 12.4 5.20039H5.2ZM4.6 10.0004C4.6 9.66902 4.86863 9.40039 5.2 9.40039H16C16.3314 9.40039 16.6 9.66902 16.6 10.0004C16.6 10.3318 16.3314 10.6004 16 10.6004H5.2C4.86863 10.6004 4.6 10.3318 4.6 10.0004ZM5.2 13.6004C4.86863 13.6004 4.6 13.869 4.6 14.2004C4.6 14.5318 4.86863 14.8004 5.2 14.8004H14.8C15.1314 14.8004 15.4 14.5318 15.4 14.2004C15.4 13.869 15.1314 13.6004 14.8 13.6004H5.2Z" fill="#3B424A" stroke="#3B424A" stroke-width="0.5" stroke-linecap="round" />
    </svg>



);
export const RecycleBinIcon = createSvgIcon(
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 4.99984V2.99984C6 2.64622 6.14048 2.30708 6.39052 2.05703C6.64057 1.80698 6.97971 1.6665 7.33333 1.6665H12.6667C13.0203 1.6665 13.3594 1.80698 13.6095 2.05703C13.8595 2.30708 14 2.64622 14 2.99984V4.99984M0 5.6665H20M2 5.6665V18.9998C2 19.3535 2.14048 19.6926 2.39052 19.9426C2.64057 20.1927 2.97971 20.3332 3.33333 20.3332H16.6667C17.0203 20.3332 17.3594 20.1927 17.6095 19.9426C17.8595 19.6926 18 19.3535 18 18.9998V5.6665M10 10.3332V16.9998M6 12.9998V16.9998M14 12.9998V16.9998" stroke="#3B424A" stroke-width="1.8" />
    </svg>


);

export const LabelIcons = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" space="preserve">
        <g><g><path fill="#000000" d="M66.2,36.8c-16.6,0-30,13.4-30,30s13.4,30,30,30s30-13.4,30-30C96.2,50.2,82.8,36.8,66.2,36.8z" />
            <path fill="#000000" d="M243.2,148.3L107.3,13.8c-1-1-2.1-1.7-3.4-2.2c-1.3-0.7-2.8-1.1-4.4-1.1h-80c-5.3,0-9.5,4.3-9.5,9.5v80c0,0.8,0.1,1.5,0.3,2.2c0.2,2.1,1.1,4.2,2.8,5.8L149,242.6c3.7,3.7,9.8,3.7,13.5,0l80.8-80.8C246.9,158.1,246.9,152.1,243.2,148.3z M217.2,159.2l-56.9,57.6c-2.6,2.6-5.7,3.3-9.5,0l-108-107.1h0L28.6,95.4V34.2c0-2.8,2.2-5,5-5h50.7c0.3,0,0.7,0,1,0.1l0.9-0.1l9.1,0.5l13.8,13.8v-0.1l108,107.1C221.1,154.3,219.8,156.6,217.2,159.2z" /><path fill="#000000" d="M200.6,139.2l-0.7-0.7c-3.7-3.7-9.8-3.7-13.5,0l-47.1,47.2c-3.7,3.7-3.7,9.8,0,13.5l0.7,0.7c3.7,3.7,9.8,3.7,13.5,0l47.2-47.2C204.4,149,204.4,142.9,200.6,139.2z M179.7,118.3l-0.7-0.7c-3.7-3.7-9.8-3.7-13.5,0l-47.1,47.2c-3.7,3.7-3.7,9.8,0,13.5l0.7,0.7c3.7,3.7,9.8,3.7,13.5,0l47.2-47.2C183.4,128,183.4,122,179.7,118.3z M157.8,96.4l-0.7-0.7c-3.7-3.7-9.8-3.7-13.5,0l-47.1,47.2c-3.7,3.7-3.7,9.8,0,13.5l0.7,0.7c3.7,3.7,9.8,3.7,13.5,0l47.2-47.2C161.5,106.1,161.5,100.1,157.8,96.4z" /></g></g>
    </svg>
)

export const SiteVisits = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 13" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.40654C0 2.41663 2.49852 0 5.52074 0C8.55148 0 11.05 2.41663 11.05 5.40654C11.05 6.9132 10.5021 8.31196 9.60018 9.49752C8.60522 10.8053 7.3789 11.9447 5.99855 12.8391C5.68263 13.0458 5.39752 13.0614 5.05079 12.8391C3.66258 11.9447 2.43626 10.8053 1.44983 9.49752C0.547289 8.31196 0 6.9132 0 5.40654ZM3.70093 5.57459C3.70093 6.57621 4.51826 7.36398 5.52042 7.36398C6.52324 7.36398 7.34844 6.57621 7.34844 5.57459C7.34844 4.58076 6.52324 3.75464 5.52042 3.75464C4.51826 3.75464 3.70093 4.58076 3.70093 5.57459Z" fill="#000" />
    </svg >
)


export const MetaCampaigns = createSvgIcon(
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.07444 5.10235C6.4798 4.99181 5.20991 5.91814 4.32234 7.08088C3.42957 8.25044 2.80006 9.80148 2.45302 11.3489C2.10608 12.8959 2.01938 14.5379 2.2986 15.913C2.56996 17.2493 3.26245 18.6227 4.71887 19.0581C6.10711 19.4731 7.35367 18.883 8.30541 18.0824C9.25925 17.28 10.0858 16.1364 10.7511 15.0309C11.273 14.1637 11.7191 13.2803 12.0688 12.5265C12.4186 13.2803 12.8647 14.1637 13.3866 15.0309C14.0519 16.1364 14.8784 17.28 15.8323 18.0824C16.784 18.883 18.0306 19.4731 19.4188 19.0581C20.8752 18.6227 21.5677 17.2493 21.8391 15.913C22.1183 14.5379 22.0316 12.8959 21.6847 11.3489C21.3376 9.80148 20.7081 8.25044 19.8153 7.08088C18.9278 5.91814 17.6579 4.99181 16.0632 5.10235C14.3295 5.22253 13.0931 6.57057 12.3767 7.58998C12.2665 7.74683 12.1638 7.90271 12.0688 8.05454C11.9739 7.90271 11.8712 7.74683 11.761 7.58998C11.0446 6.57057 9.80819 5.22253 8.07444 5.10235ZM10.9245 10.1265C10.6423 10.8417 9.95523 12.4747 9.03751 13.9996C8.41585 15.0325 7.72385 15.9581 7.01794 16.5519C6.30994 17.1474 5.75549 17.2805 5.29171 17.1419C4.89613 17.0236 4.47512 16.5812 4.2586 15.515C4.04995 14.4875 4.10099 13.1402 4.40455 11.7866C4.70801 10.4335 5.24175 9.1726 5.91211 8.29441C6.58766 7.40942 7.28078 7.05214 7.93614 7.09756C8.70237 7.15068 9.46596 7.80264 10.1246 8.7399C10.4922 9.26293 10.7668 9.79158 10.9245 10.1265ZM13.2132 10.1265C13.4954 10.8417 14.1825 12.4747 15.1002 13.9996C15.7218 15.0325 16.4138 15.9581 17.1197 16.5519C17.8278 17.1474 18.3822 17.2805 18.846 17.1419C19.2416 17.0236 19.6626 16.5812 19.8791 15.515C20.0877 14.4875 20.0367 13.1402 19.7331 11.7866C19.4297 10.4335 18.8959 9.1726 18.2256 8.29441C17.55 7.40942 16.8569 7.05214 16.2015 7.09756C15.4353 7.15068 14.6717 7.80264 14.0131 8.7399C13.6455 9.26293 13.3709 9.79158 13.2132 10.1265Z" fill="#09244B" />
    </svg>
)