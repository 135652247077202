import { useEffect, useState } from 'react';
import { Leads } from '../../api/Endpoints/Leads';

const useAssignees = () => {
    const [assignees, setAssignees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchAssignees = async () => {
        setIsLoading(true);
        try {
            const response = await Leads.getAssignees();
            setAssignees(response.data.users);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    return { assignees, isLoading, fetchAssignees };
};

export default useAssignees;


