import React, { useEffect, useState } from 'react';
import { createCustomTheme } from './theme';
import { Navigate, Outlet, Route, Routes, useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RTL } from './components/rtl';
import { useAuth } from './hooks/use-auth';
import { useSettings } from './contexts/Settings/settings-context';
import { Helmet } from 'react-helmet-async';
import { GlobalRefreshProvider } from './contexts/GlobalRefresh/GlobalRefresh';
import routes from './routes';
import { MenuRefreshProvider } from './contexts/Menurefresh/menuRefresh';


function App() {
  const { settings } = useSettings();
  const { isInitialized } = useAuth();



  // Define generatedRoutes at the top level
  // const generatedRoutes = LeadSubmenuLIst.map((item) => ({
  //   path: `${item.slug}`,
  //   element: <SubMenus />,
  // }));


  // // Dynamic dropdown logic
  // const foundRoute = routes.find((route) => route.path === "dashboard");
  // const childRoute = foundRoute.children.find((child) => child.path === "leads");
  // childRoute.children = [...childRoute.children, ...generatedRoutes];
  // const updatedRoutes = [...routes];
  // 
  const content = useRoutes(routes);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: 'light',
  });


  return (
    <>
      <Helmet>
        <title>Desai-CRM</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <GlobalRefreshProvider>
            <MenuRefreshProvider>
              {isInitialized && content}
            </MenuRefreshProvider>
          </GlobalRefreshProvider>
        </RTL >
      </ThemeProvider >
    </>
  );
}

export default App;
