import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid } from '@mui/material';
import * as yup from "yup";
import moment from 'moment';
import { CampaignsAPI } from '../../api/Endpoints/CampaignsAPI';
import MonthPicker from '../Form/MonthPicker';
import YearPicker from '../Form/YearPicker';
import TextInput from '../Form/TextInput';
import { LoadingButton } from '@mui/lab';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid none',
    boxShadow: 14,
    borderRadius: '12px',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
};

const buttonStyle = {
    marginTop: 'auto',
    alignSelf: 'flex-end',
};

export default function AddCampaignOptions({ open, id, setOpen, editId, setEditId, setCampaignId, onUpdate }) {

    const scheme = yup.object().shape({
        month: yup.string().required("month is required"),
        year: yup.string().required("year is required"),

    })
    const handleClose = () => {
        setEditId(0)
        setOpen(false);

    }

    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm({
        resolver: yupResolver(scheme), defaultValues: {
            ip_address: '',
        },
        mode: 'all'
    })
    const [ipAddress, setIpAddress] = React.useState('');
    const [loading, setLoading] = React.useState(false)
    const [formButtonStatus, setFormButtonStatus] = React.useState({
        label: "Add",
        loading: false,
        disabled: false,
    });




    React.useEffect(() => {
        if (parseInt(editId) > 0) {
            fetchLeadDetails()
            setOpen(true);
        }

    }, [editId])

    const fetchLeadDetails = () => {
        setLoading(true);

        CampaignsAPI.getCampaignOptionsDetails({ id: editId })
            .then((response) => {
                if (response.data.option) {
                    const options = response.data.option
                    setValue('year', options?.year)
                    setValue('month', options?.month)
                    setValue('spend_amount', options?.spend_amount)
                    setValue('reach', options?.reach)
                    setValue('views', options?.views)
                    setValue('Impressions', options?.impression)
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false);
            });



    };
    const onSubmit = (data) => {


        setFormButtonStatus({ ...formButtonStatus, loading: true });

        const Month = data.month != null ? moment(data.month).format("MMMM") : '';
        const Year = data.year != null ? moment(data.year).format("YYYY") : '';

        const dataToSubmit = {
            campaign_list_id: id,
            source_url: "CRM",
            ip_address: ipAddress,
            year: Year,
            spend_amount: data.spend_amount,
            reach: data.reach,
            views: data.views,
            impression: data.Impressions

        }

        if (Month !== "Invalid date") {
            dataToSubmit.month = Month
        }



        let action;
        if (id > 0 && editId == 0) {
            action = CampaignsAPI.addCampaignOptions(dataToSubmit)
        } else if (editId > 0) {
            dataToSubmit.id = editId
            action = CampaignsAPI.updateCampaignOptions(dataToSubmit)

        }

        action.then((response) => {
            if (response.data.status !== false) {
                toast.success("Option Successfully Updated!", { autoClose: 1000 });
            } else {
                try {

                    toast.error(response.data.message, { autoClose: 2000 });
                } catch (error) {
                    console.error("Error:", error);

                }
            }

            setFormButtonStatus({ label: "Add", loading: false, disabled: false });
            if (editId > 0) {
                setOpen(false)
                setEditId(0)
            } else {
                reset()
                setValue('month', '')
                setValue('year', '')
            }
            onUpdate()

        }).catch(errors => {
            toast.error("Internal Server Error, please try again later!", { autoClose: 1000 })
            setFormButtonStatus({ label: "Add", loading: false, disabled: false });
        })


    }


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {loading ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '30vh'
                        }}>
                            <CircularProgress />
                        </Box >
                    ) : (


                        <form onSubmit={handleSubmit(onSubmit)}>




                            <Grid container spacing={1} sx={{ display: 'flex', pt: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <MonthPicker
                                        control={control}
                                        name="month"
                                        label="Month"

                                        value={watch('month')}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <YearPicker
                                        control={control}
                                        name="year"
                                        label="Year"
                                        value={watch('year')}
                                    // readOnly={props.editId ? true : false}
                                    />
                                </Grid>

                            </Grid>

                            <Grid container spacing={2} mt={1}>
                                <Grid sx={{ pt: 5 }} item xs={12} sm={6}>
                                    <TextInput control={control} name="spend_amount"
                                        label="Spend Amount"
                                        type={"number"}
                                        value={watch('spend_amount')} />
                                </Grid>
                                <Grid sx={{ pt: 5 }} item xs={12} sm={6}>
                                    <TextInput control={control} name="reach"
                                        label="Reach"
                                        type={"number"}
                                        value={watch('reach')} />
                                </Grid>
                            </Grid>



                            <Grid container spacing={2} mt={1}>
                                <Grid sx={{ pt: 5 }} item xs={12} sm={6}>
                                    <TextInput control={control} name="Impressions"
                                        label="Impressions"
                                        type={"number"}
                                        value={watch('Impressions')} />
                                </Grid>
                                <Grid sx={{ pt: 2 }} item xs={12} sm={6}>
                                    <TextInput control={control} name="views"
                                        label="Views"
                                        value={watch('views')} />
                                </Grid>

                            </Grid>
                            <Grid sx={{ p: 1, mt: 2, display: 'flex', justifyContent: 'end' }} item xs={12}>
                                <Button variant='outlined' color='error' sx={{ mr: 1 }} onClick={handleClose}>Close</Button>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant='outlined' type="submit" sx={buttonStyle}>{formButtonStatus.label}</LoadingButton>

                            </Grid>



                        </form>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
