import { get, post } from './../Config/config';

export const Leads = {
    get: (data) => get(`/leads-list`, { params: data }),
    add: (data) => post('/lead/action/create', data),
    getDetails: (data) => get(`/lead-details/${data.id} `),

    // Dropdown List
    getDropdownList: (data) => get(`/crm-lead-types`, { params: data }),


    //Assignee and Status Direct Update API
    assign: (data) => post('/lead/action/assigned-to-update', data),
    updateStatus: (data) => post('/lead/action/status-update', data),


    //Followup update
    update: (data) => post('/lead/action/update', data),


    getAssignees: (data) => get('/users-list?', { params: data }),

    importLeads: (data) => post('/leads/import', data),



    delete: (data) => post('/lead/action/delete', data),
    restore: (data) => post('lead/action/restore', data),
    recyclebin: (data) => get('/deleted/leads', { params: data }),
    newLeads: (data) => get(`/leads-list?`, { params: data }),

    ConfirmedLeads: (data) => get(`/leads?status=${`Confirmed`}`, { params: data }),
    lost: (data) => get(`/leads?status=${`Lost`}`, { params: data }),
    future: (data) => get(`/leads?status=${`future`}`, { params: data }),
    invalid: (data) => get(`/leads?status=${`invalid`}`, { params: data }),
    closeLead: (data) => post('/lead/close', data),
    getClosedLeads: (data) => get('closed/leads', { params: data }),

    // for share the email while generate a lead
    sendEmail: (data) => post('/lead-mail?', data),


    // Quick Edit
    getQuickEditDetails: (data) => get(`/quick-edit?`, { params: data }),
    postQuickEdit: (data) => post(`/quick-update?`, data),

    getDuplicateLeads: (data) => get(`/lead-log-check/${data.id}`,),
}
