import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton, Grid, DialogTitle, Button, Tooltip } from '@mui/material';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import SelectX from '../Form/SelectX';
import { fetchAssignees, fetchLeads, fetchProjects } from '../CommonFunctions/DropdownLists';
import { useForm } from 'react-hook-form';
import { Lables } from '../../api/Endpoints/Label';
import DisconnectPrompt from '../Popup/DiconnectPrompt';
function CampignConnect(props) {
    const { watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm()
    const [open, setOpen] = useState(false);
    const [ID, setID] = useState(0)
    const [showPopup, setShowPopup] = useState(false)
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const fetchLabels = async (e) => {
        const response = await Lables.get({ keyword: e });
        if (typeof response.data.data) {
            return response.data?.data
        } else {
            return [];
        }
    }
    return (
        <div>
            {showPopup && <DisconnectPrompt setShowPopup={setShowPopup} ID={1} setID={setID} />}
            <Tooltip title={props.isConnected ? "connected" : "not connected"} arrow>
                <IconButton variant="outlined" onClick={handleOpen} >
                    {props.isConnected ? (
                        <WifiTetheringIcon style={{ color: 'green' }} />
                    ) : (
                        <WifiTetheringOffIcon style={{ color: 'red' }} />
                    )}
                </IconButton>
            </Tooltip >


            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '500px',
                        height: '350px'
                    },
                }}>
                <DialogTitle textAlign={'center'} padding={0} paddingBottom={3}>Connect Meta Campign</DialogTitle>
                <DialogContent>
                    <Grid container item xs={12} spacing={2} mt={0}>
                        <Grid item sm={6}>
                            <SelectX
                                options={fetchLeads}
                                label="Lead Type"
                                name={'crm_lead_type_id'}
                                defaultValue={watch('crm_lead_type_id')}
                                control={control}
                                error2={errors?.crm_lead_type_id?.message}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <SelectX
                                options={fetchProjects}
                                label="Project"
                                name={'project'}
                                defaultValue={watch('project')}
                                control={control}
                            />
                        </Grid>
                    </Grid>


                    <Grid container item xs={12} spacing={2} mt={0}>
                        <Grid item sm={6}>
                            <SelectX
                                // ismultiple
                                options={fetchAssignees}
                                label="Assign To"
                                name={'assign_to'}
                                defaultValue={watch('assign_to')}
                                control={control}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <SelectX
                                ismultiple
                                options={fetchLabels}
                                label="Labels"
                                name={'labels'}
                                defaultValue={watch('labels')}
                                control={control}
                            />
                        </Grid>
                    </Grid>
                    <Grid display={'flex'} width={'100%'} justifyContent={props.isConnected ? "space-between" : "end"} pt={4} alignItems={'end'}>
                        {props.isConnected && <li style={{ listStyle: 'none', color: 'red', cursor: 'pointer' }} onClick={() => setShowPopup(true)}>Disconnect</li>}
                        <Button variant='outlined' onClick={handleClose}>Save</Button>
                    </Grid>

                </DialogContent>
            </Dialog>
        </div >
    );
}

export default CampignConnect;
