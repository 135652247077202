import { Card, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
function FilterBadges({ label, value, handleClose }) {
    return (
        <Card sx={{ margin: 1 }}>
            <Grid container alignItems="center" padding={0.5} sx={{ backgroundColor: 'rgb(231,241,254)' }}>
                <Typography variant="body1">
                    <strong>{label}</strong>: {value}
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Card>
    )
}

export default FilterBadges