import React, { Fragment, useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { ReportsAPI } from '../../../../api/Endpoints/ReportsAPI';
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Waveform } from '@uiball/loaders';
import Paper from '@mui/material/Paper';
import { GlobalRefreshContext } from '../../../../contexts/GlobalRefresh/GlobalRefresh';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { fetchCampaginName } from '../../../../components/CommonFunctions/DropdownLists';
import SelectX from '../../../../components/Form/SelectX';
function Index() {
    const { GlobalRefresh } = GlobalRefreshContext();
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const [reports, setReports] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { watch, setValue, control } = useForm({})
    useEffect(() => {
        fetchStatusReports();
    }, [
        GlobalRefresh,
        selectedToDate,
        selectedFromDate,
        watch('lead_source'),
        watch('campaign_name')
    ]);

    useEffect(() => {
        const fromParam = queryParams.get('month');
        const toParam = queryParams.get('year');
        const campaignName = queryParams.get('campaign');


        // Set selectedFromDate and selectedToDate based on URL parameters
        setSelectedFromDate(fromParam ? fromParam : null);
        setSelectedToDate(toParam ? toParam : null);

        // Correctly set the form value for 'campaign_name'
        if (campaignName !== null) {
            setValue('campaign_name', {
                id: 0,
                name: campaignName || ''
            });
        }

    }, [location.search]);

    const fetchStatusReports = async () => {
        setLoading(true)
        try {

            const response = await ReportsAPI.getCostReport({
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
                month: selectedFromDate ? moment(selectedFromDate).format('MMMM') : null,
                year: selectedToDate ? moment(selectedToDate).format('YYYY') : null,
                campaign_name: watch('campaign_name') && watch('campaign_name')?.name
            });

            setReports(response.data.data)
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const clearDatePicker = () => {
        setValue('campaign_name', null)
        setValue('lead_source', null)
        setSelectedFromDate(null)
        setSelectedToDate(null)
    }

    const queryString = `${selectedFromDate !== null && selectedToDate !== null ? `?month=${selectedFromDate}&year=${selectedToDate}` : ''}${watch('campaign_name') ? `${selectedFromDate !== null && selectedToDate !== null ? '&' : '?'}campaign=${watch('campaign_name')?.name}` : ''}`;


    return (

        <div class="page-cntr">
            <div class="lead-cntr follo-up-cntr " >
                < div class="title-sec">
                    <PageHeader title={'Cost Report'} />
                    <LocalizationProvider dateAdapter={AdapterMoment} >
                        <Grid container direction="row"
                            mb={2}
                        >
                            <Grid item xs={3}  >
                                <div class="follw-dates">
                                    <DatePicker
                                        value={selectedFromDate}
                                        views={["month"]}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                variant="outlined"
                                                label="Month"
                                                value={selectedFromDate}
                                                fullWidth />
                                        )}
                                        onChange={(newValue) => setSelectedFromDate(newValue)}
                                        inputFormat="MMMM"
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={3}  >
                                <DatePicker
                                    value={selectedToDate}
                                    views={["year"]}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            variant="outlined"
                                            value={selectedFromDate}
                                            label="Year"

                                            fullWidth
                                        />
                                    )}
                                    onChange={(newValue) => setSelectedToDate(newValue)}
                                    inputFormat="YYYY"
                                    disableFuture
                                />

                            </Grid>
                            <Grid xs={3} ml={1} mt={1}>
                                <SelectX

                                    options={fetchCampaginName}
                                    name={'campaign_name'}
                                    placeholder={
                                        "Campaign"
                                    }
                                    control={control}


                                />
                            </Grid>

                            <Grid item xs={2}  >
                                <IconButton className=''
                                    sx={{ ml: 2, mt: 1, background: 'white' }}

                                    edge="end"

                                    onClick={clearDatePicker}

                                >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>

                        </Grid>
                    </LocalizationProvider>
                </div>
                {
                    loading ?
                        <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                        : (
                            <Fragment>

                                {
                                    reports
                                        ?.length > 0 ? (
                                        <Fragment>

                                            < TableContainer TableContainer component={Paper} className='reports-table'>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>

                                                                Campaign Name

                                                            </TableCell>

                                                            <TableCell align="left">Cost</TableCell>
                                                            <TableCell align="left">Cost Per Lead</TableCell>
                                                            <TableCell align="left">Cost Per Conversion</TableCell>
                                                            <TableCell align="left">Reach</TableCell>
                                                            <TableCell align="left">Views</TableCell>
                                                            <TableCell align="left">Impressions</TableCell>
                                                            <TableCell align="left">Total +ve</TableCell>
                                                            <TableCell align="left">Total -ve</TableCell>
                                                            <TableCell align="left">Total Lead Count</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {reports?.map((row) => (
                                                            <TableRow
                                                                key={row.name} // Assuming name is unique, otherwise, use another unique identifier
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >

                                                                <TableCell component="th" scope="row">
                                                                    <Link
                                                                        to={`/dashboard/reports/cost-report/${row?.campaign_id}${queryString}`}
                                                                        style={{ textDecoration: 'none', color: 'black' }}>
                                                                        {row?.campaign_name}
                                                                    </Link>


                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    {row?.total_cost}

                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.cost_per_lead}

                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.cost_per_conversion}

                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    {row?.reach}

                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    {row?.views}

                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.impression}

                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    {row?.positive_lead_count}

                                                                </TableCell>

                                                                <TableCell component="th" scope="row">
                                                                    {row?.negative_lead_count}

                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.lead_total_count}

                                                                </TableCell>



                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Fragment>
                                    ) : (

                                        <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Status Report not found</Typography>
                                        </Box>
                                    )
                                }
                            </Fragment>
                        )}
            </div>
        </div >

    )
}

export default Index