import React, { createContext, useState, useContext, useEffect } from 'react';

const StateContext = createContext();

export const MenuRefreshContext = () => useContext(StateContext);

export const MenuRefreshProvider = ({ children }) => {

    const [menuRefresh, setmenuRefresh] = useState(0);


    return (
        <StateContext.Provider value={{ menuRefresh, setmenuRefresh }}>
            {children}
        </StateContext.Provider>
    );
};
