import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Leads } from '../../api/Endpoints/Leads';
import { toast } from 'react-toastify';


const StatusSelector = (props) => {
    const { control } = useForm();

    const [selectedStatus, setSelectedStatus] = useState('');



    useEffect(() => {
        const find = props.statusList.find((ismatchedstatus) => ismatchedstatus.slug == props.options?.slug)
        setSelectedStatus(find)
    }, [props.options, props.statusList]);



    const handleStatusChange = (event) => {
        let status = event.target.value;
        const dataToSubmit = {
            id: props.id,
            crm_status: status?.slug
        };

        Leads.updateStatus(dataToSubmit)
            .then((response) => {
                toast.success(
                    <span>
                        Status Updated
                    </span>,
                    { autoClose: 1000 }
                );
                setSelectedStatus(status)
                props.func && props.func();
                props.setRefresh && props.setRefresh(Math.random)
            })
            .catch((err) => {
                console.log(err);
            });
    };



    // CSS class based on the selected status
    return (
        <div className={`status-cntr`} >
            <Controller
                control={control}
                name="status"
                render={({ field }) => (
                    <FormControl fullWidth>
                        <Select className='btn-rds'
                            {...field}
                            onChange={handleStatusChange}
                            value={selectedStatus && selectedStatus}
                            disabled={props.isDisabled}
                            sx={{
                                backgroundColor: selectedStatus && selectedStatus.bg_color,
                                color: selectedStatus ? selectedStatus.text_color : 'black'


                            }}
                        >
                            {
                                props.statusList && props.statusList.map((option) => (
                                    <MenuItem key={option.id} value={option}
                                        sx={{
                                            backgroundColor: option.bg_color,
                                            color: option.text_color,
                                            ":hover": {
                                                backgroundColor: option.bg_color,


                                            }
                                        }}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                )
                }
            />
        </div >
    );
};

export default StatusSelector;
