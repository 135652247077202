import React, { useEffect, useState } from 'react';
import { Dialog, Grid, Typography, Card, Box, DialogTitle, IconButton, CircularProgress } from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Leads } from '../../api/Endpoints/Leads';
import { Link } from 'react-router-dom';

const DataCard = ({ field, og_lead, ID }) => (
    <Card style={{ margin: '1em', padding: 3 }} >
        {field.email ? (
            <Typography >
                {field.email} <span
                    style={{
                        fontStyle: 'oblique',
                        fontSize: '11px',
                        fontWeight: 600,
                        color: 'red',
                    }}>-Duplicate</span></Typography>
        ) : (

            <Typography >
                {field.phone_number} <span
                    style={{
                        fontStyle: 'oblique',
                        fontSize: '11px',
                        fontWeight: 600,
                        color: 'red',
                    }}>-Duplicate</span></Typography>
        )}
        <Box marginTop={0.5} sx={{ background: ' #ecfff5' }}>
            <Typography variant="h6" >Orginal Lead</Typography>
            <Typography variant="body2">Project: {og_lead.project}</Typography>
            {
                og_lead.assigned_to && (
                    <Typography variant="body2">Assign To: {og_lead.assigned_to}</Typography>
                )
            }
            <Typography variant="body2">Created Date: {moment(og_lead.created_at).format('DD-MM-YYYY')}</Typography>

            <Grid display={'flex'} justifyContent={'end'}
            >
                <Link to={`/dashboard/lead-details/${ID}`} target='_blank'>View More</Link>
            </Grid>
        </Box>

    </Card >
);

const DataList = ({ open, setOpen, ID, setID }) => {
    const dialogStyle = {
        '& .MuiDialog-paper': {
            width: '700px', // Set fixed width here
            maxWidth: '400px', // Ensure maxWidth is the same to keep it fixed
            maxHeight: '50vh', // Added maxHeight to control the height of the dialog
        }
    };
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const handleClose = () => {
        setOpen(false);
        setID(0)
    };


    useEffect(() => {
        if (ID > 0) {
            fetchDuplicateLeads()
        }

    }, [ID])


    const fetchDuplicateLeads = async () => {
        setLoading(true)
        const res = await Leads.getDuplicateLeads({ id: ID })
        setData(res?.data)
        setLoading(false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            sx={dialogStyle}
            aria-describedby="dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
                {loading ?
                    (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '20vh'
                        }}>
                            <CircularProgress />
                        </Box >
                    ) : (
                        <Grid container>
                            {data && data.map((item, index) => (
                                <Grid item xs={12} sm={12} key={index} >
                                    <DataCard
                                        field={item.field}
                                        project={item.project}
                                        og_lead={item.og_lead}
                                        ID={ID}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}

            </Box>
        </Dialog >
    );
};

export default DataList;
