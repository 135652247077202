import React from 'react';
// import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs'

import Homeleads from './home-lead';

import { Breadcrumbs, Grid } from '@mui/material';
import { useState, useEffect } from 'react';

function Home() {




  return (
    <div className="page-cntr">
      <div className="breadcrumbs">
        <Breadcrumbs />
      </div>



      <Homeleads />

    </div>
  );
}

export default Home;
