import { get, post, put, destroy } from './../Config/config';
import axios from "axios";



export const Auth = {
    login: (data) => post('/login', data),

}

