import PropTypes from 'prop-types';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box } from '@mui/material';
import './Dashboard.css';
import PrimarySearchAppBar from '../containers/Navbar'
import logoimg from '../assets/desai-logo.jpg'
import AlignVerticalBottomOutlinedIcon from '@mui/icons-material/AlignVerticalBottomOutlined';
import { HomeIcon, LeadsIcon, FollowUpsIcon, RecycleBinIcon, LabelIcons, SiteVisits } from '../icons/DashboardIcons';
import SettingsIcon from '@mui/icons-material/Settings';
import { DynamicdropdownsAPI } from '../api/Endpoints/DynamicDropdown';
import { Lables } from '../api/Endpoints/Label';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { MenuRefreshContext } from '../contexts/Menurefresh/menuRefresh';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
export const DashboardSidebar = () => {

  const [LabelsList, setLabelsList] = useState([])

  const [leadsList, setLeadsList] = useState(() => {
    const storedLeadsList = localStorage.getItem('leadsList');
    return storedLeadsList ? JSON.parse(storedLeadsList) : [];
  });

  const items = [
    // {
    //   icon: HomeIcon,
    //   title: 'Dashboard',
    //   href: '/dashboard/home',
    // },

    //Dynamic Routing

    {
      icon: LeadsIcon,
      title: 'Leads',
      href: '/dashboard/leads',
      dropdownItems: leadsList && leadsList.map((route) => ({
        title: route.name,
        href: `/dashboard/leads/${route.slug}`,
      })),
    },
    {
      icon: WarningAmberOutlinedIcon,
      title: 'Triage Leads',
      href: '/dashboard/triage-leads',
    },


    {
      icon: AlignVerticalBottomOutlinedIcon,
      title: 'Campaigns',
      href: '/dashboard/campaigns',
    },

    {
      icon: FollowUpsIcon,
      title: 'Follow Ups',
      href: '/dashboard/follow-ups',
    },
    {
      icon: PinDropOutlinedIcon,
      title: 'Site Visits',
      href: '/dashboard/site-visits',
    },
    {
      icon: LibraryBooksOutlinedIcon,
      title: 'Reports',
      href: '/dashboard/reports',
      dropdownItems: [
        {
          title: 'Status report',
          href: '/dashboard/reports/status-report',
        },
        {
          title: 'Cost report',
          href: '/dashboard/reports/cost-report',
        },
        {
          title: 'Country report',
          href: '/dashboard/reports/country-report',
        },
        {
          title: 'Age report',
          href: '/dashboard/reports/age-report',
        },
        {
          title: 'Profession report',
          href: '/dashboard/reports/profession-report',

        },
        {
          title: 'Referral  report',
          href: '/dashboard/reports/referral-report',

        },
        {
          title: 'Site Visit Report',
          href: '/dashboard/reports/site-visit-report',

        },

      ]

    },
    {
      icon: LabelIcons,
      title: 'Labels',
      href: '/dashboard/labels',
      dropdownItems: LabelsList && LabelsList.map((item) => ({
        title: item.title,
        href: `/dashboard/labels/${item.id}`,
      })),
    },

    // {
    //   icon: GroupAddOutlinedIcon,
    //   title: 'Agents',
    //   href: '/dashboard/agents',
    // },
    {
      icon: ArchiveOutlinedIcon,
      title: 'Archived Leads',
      href: '/dashboard/archived-leads',
    },
    {
      icon: RecycleBinIcon,
      title: 'Recycle Bin',
      href: '/dashboard/recycle-bin',
    },

    // {
    //   icon: SettingsIcon,
    //   title: 'Settings',
    //   href: '/dashboard/settings',
    //   dropdownItems: [
    //     {
    //       title: 'Labels',
    //       href: '/dashboard/settings/labels',
    //     },
    //   ]

    // },
  ];


  const { pathname } = useLocation();

  const [activeItem, setActiveItem] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openSubDropdown, setOpenSubdropdowm] = useState(false)
  const { menuRefresh } = MenuRefreshContext()
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  useEffect(() => {
    const storedActiveItem = localStorage.getItem('activeItem');
    if (storedActiveItem) {
      setActiveItem(JSON.parse(storedActiveItem));
      setOpenDropdown(true);
    }
    items.forEach((item) => {
      const active = !!matchPath({ path: item.href }, pathname);
      if (active) {
        setActiveItem(item);
        localStorage.setItem('activeItem', JSON.stringify(item));
      }
    });
  }, [location]);


  useEffect(() => {
    fetchLabelsList()
  }, [menuRefresh])


  useEffect(() => {
    fetchLeadDropdownStatus()
  }, [])


  async function fetchLabelsList() {
    const response = await Lables.get()
    if (response.data.status) {
      setLabelsList(response.data.data)
    }
  }


  const fetchLeadDropdownStatus = async () => {
    setLoading(true);
    try {
      const response = await DynamicdropdownsAPI.get();
      if (response.data.status) {
        const simplifiedData = response.data.crm_status.map(({ name, slug }) => ({ name, slug }));
        setLeadsList(simplifiedData);
        localStorage.setItem('leadsList', JSON.stringify(simplifiedData));
      }
    } catch (error) {
      console.error('Error fetching lead dropdown status:', error);
    } finally {
      setLoading(false);
    }
  };



  const handleDropdownToggle = () => {
    const newOpenDropdown = !openDropdown;
    setOpenDropdown(newOpenDropdown);

    if (!newOpenDropdown) {
      localStorage.removeItem('activeItem');
    }
    setOpenDropdown(false);
  };






  const handleSubDropdownToggle = () => {
    setOpenSubdropdowm(!openSubDropdown);
  };



  return (
    <div style={{ display: 'flex' }}>
      <div className="cnt-cntr">
        <div className="fixe-menu">{<PrimarySearchAppBar />}</div>
      </div>
      <Drawer class="left-menu"
        open

        variant="permanent"
        PaperProps={{
          onMouseOver: () => { },
          onMouseLeave: () => { },
          sx: {
            backgroundColor: 'background.paper',
            height: '100%',
            overflowX: 'hidden',
            transition: 'width 322ms ease-in-out',
            width: 322,
            '& .simplebar-content': {
              height: '100%',
            },
            '& span, p': {
              display: 'flex',
            },
          },
        }}
      >
        <div class="left-menu-cntr">
          <div class="brand-logo">
            <img src={logoimg} sx={{ objectFit: 'cover', }} style={{ width: 60, height: 60 }} />
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              p: 2,
            }}
          >
            <h3>Pages</h3>
            <List>
              {items.map((item) => {


                return (

                  <div div class="nav-list-cntr" key={item.title} >
                    <Link to={item.href} style={{ textDecoration: 'none', color: 'purple' }}>

                      <ListItem
                        className={activeItem?.title === item.title ? 'active-menu' : ''}
                        onClick={item.dropdownItems ? handleDropdownToggle : null}
                      >

                        <ListItemIcon>
                          <item.icon sx={{ color: 'inherit' }} />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                        {item.dropdownItems ? (
                          openDropdown && activeItem?.title === item.title ? (
                            <ArrowDropUpIcon onClick={handleDropdownToggle} />
                          ) : (
                            <ArrowDropDownIcon onClick={handleDropdownToggle} />
                          )
                        ) : null}

                      </ListItem>
                    </Link>

                    {openDropdown && activeItem?.title === item.title && item.dropdownItems ? (
                      <List className="drop-cntr" disablePadding>
                        {item.dropdownItems.map((dropdownItem) => (

                          <Link class="nav-list" Link
                            to={dropdownItem.href}
                            key={dropdownItem.title}
                            style={{
                              textDecoration: 'none',
                              color: 'black',
                              paddingLeft: '32px',
                            }}
                          >
                            <ListItem
                              className={activeItem?.title === item.title ? 'active-menu2' : ''}

                            >
                              <ListItemIcon>
                                <div style={{ width: '24px' }} />
                              </ListItemIcon>
                              <Typography
                                style={{
                                  color: pathname === dropdownItem.href ? '#FAAB53' : 'white',
                                }}
                              >
                                {dropdownItem.title}

                                {dropdownItem?.subDropdown && (

                                  < div >


                                    {openSubDropdown && activeItem?.title === item.title ? (
                                      <ArrowDropUpIcon onClick={handleSubDropdownToggle} />
                                    ) : (
                                      <ArrowDropDownIcon onClick={handleSubDropdownToggle} />
                                    )}

                                    {openSubDropdown && dropdownItem?.subDropdown.map((subItem) => (
                                      <Link to={subItem.href}
                                        key={subItem.title}>
                                        <ListItemText primary={subItem.title} />
                                      </Link>
                                    ))}

                                  </div>
                                )}

                              </Typography>
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    ) : null}
                  </div>

                )
              })}
            </List>


          </Box>
        </div >
      </Drawer >
    </div >
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool,
};


