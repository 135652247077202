import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Button, CircularProgress, Dialog, } from '@mui/material';

import { Link } from 'react-router-dom';
import QuickEdit from './QuickEdit';

import Notes from '../../project-portal/pages/Leads/FullDetailsPage/AddNotes';
const dialogStyle = {
    '& .MuiDialog-paper': {
        width: '700px', // Set fixed width here
        maxWidth: '700px', // Ensure maxWidth is the same to keep it fixed
    }
};
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={2}>
                    <Typography>{children}</Typography>
                </Box>
            )
            }
        </div >
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function BasicTabs({ id, open, setOpen }) {
    const [value, setNewValue] = React.useState(0);
    const [data, setData] = React.useState()
    const [loading, setLoading] = React.useState(false)

    const handleClose = () => setOpen(false);

    const handleChange = (event, newValue) => {
        setNewValue(newValue);
    }


    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                sx={dialogStyle}
                aria-describedby="dialog-description"
            >
                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box >
                ) : (
                    <>

                        <Box sx={{ width: '100%', mt: 1, height: '70vh' }}>


                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: 3 }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Quick Edit" {...a11yProps(0)} />
                                    <Tab label="Notes" {...a11yProps(1)} />

                                </Tabs>
                            </Box>

                            <TabPanel value={value} index={0}>
                                <QuickEdit id={id} open={open} setOpen={setOpen} />
                            </TabPanel>
                            <TabPanel value={value} index={1} >
                                <Box padding={2} pt={0}>
                                    <Notes viewID={id} />
                                </Box>

                            </TabPanel>

                        </Box >
                    </>
                )
                }
            </Dialog>
        </>
    );
}
