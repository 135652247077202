import React, { useEffect, useState } from 'react'
import DetailPageNavBar from '../../../../containers/DetailPageNavbar'
import { Link, useLocation, useParams } from 'react-router-dom';
import { Leads } from '../../../../api/Endpoints/Leads';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import LabelsList from '../../../../components/CommonFunctions/LabelsList';
import LabelsComponent from '../../../../components/Form/LabelComponent';
import AssignTo from '../../../../components/Form/AssignTo';
import moment from 'moment';
import StatusSelector from '../../../../components/Form/Status';
import StatusLIstFunction from '../../../../components/CommonFunctions/statusLIst';
import DateInput from '../../../../components/Form/DateInput';
import { useForm } from 'react-hook-form';
import useAssignees from '../../../../components/CommonFunctions/Usres';
import { IconButton } from '@mui/material';
import AddNotes from './AddNotes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Source from '../Tabs/Source'
import SiteVisitTab from '../Tabs/SiteVisit';
import ExtraData from '../Tabs/ExtraData';
function Index() {
    const { leadid } = useParams();
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})
    const { statusList, fetchStatusList, } = StatusLIstFunction();
    const { labels, isLoading } = LabelsList();

    const location = useLocation();

    const getQueryParameter = (key) => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(key);
    };

    const pageNumber = getQueryParameter('page');
    const slug = getQueryParameter('slug');
    const home = getQueryParameter('home')

    const navigate = useNavigate();

    useEffect(async () => {
        setLoading(true)
        await fetchStatusList()
        fetchDetails()
    }, [leadid])



    const fetchDetails = () => {
        Leads.getDetails({ id: leadid })
            .then((response) => {
                setData(response.data.lead);
                setLoading(false)


            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    const hanldeNavigate = () => {
        if (slug !== null) {
            let url = `/dashboard/leads/${slug}`;
            if (pageNumber !== null) {
                url += `?page=${pageNumber}`;
            }
            navigate(url);
            return;
        } else if (home !== null) {
            const url = `/dashboard/home?${pageNumber !== null ? `page=${pageNumber}` : ''
                } `;
            navigate(url);
        } else {
            navigate(-1)
            // if (window.history.length > 3) {
            //     const url = `/dashboard/leads?${pageNumber !== null ? `page=${pageNumber}` : ''
            //         } `;
            //     navigate(url);
            // } else {
            //     navigate('/')
            // };
        }


    }
    return (

        <>



            <div className="cnt-cntr-details">
                <div className="fixe-menu-detail">{<DetailPageNavBar />}</div>
            </div>
            <div className='go-back-btn'>

                {/* <IconButton style={{ background: 'rgba(80, 97, 118, 0.04)' }} onClick={() => hanldeNavigate()}>
                    <ArrowBackIcon />
                </IconButton> */}


            </div >

            {
                loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }} >
                        <CircularProgress />
                    </Box >
                ) : (

                    < div className='details-page-block-content'>

                        <div class="detail-page-left-block">
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Lead Type:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.crm_lead_type?.name}
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Project:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.project?.name}
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Preferred Project:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.preferred_project?.name}
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Customer Name:

                                </Typography>

                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.name}
                                </Typography>
                            </div>

                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Phone Number:

                                </Typography>

                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.phone_number}
                                </Typography>
                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Alternative Phone Number:

                                </Typography>

                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.alt_phone_number}
                                </Typography>
                            </div>

                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Email:

                                </Typography>

                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.email}
                                </Typography>
                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Age:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.age_range}
                                </Typography>

                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Profession:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.profession}
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Location:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.city}
                                </Typography>

                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Country:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.country?.name}
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    City:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Requsted Date:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {moment(data?.created_at).format('DD-MM-YYYY')}

                                </Typography>

                            </div>




                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Lead Source:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>
                            {/* <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Agent:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div> */}
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Referred By:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.referred_by}
                                </Typography>

                            </div>




                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Description:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.remarks}
                                </Typography>

                            </div>


                            {/* <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Description:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.remarks}
                                </Typography> */}


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Campaign Name:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.campaign_name}

                                </Typography>

                            </div>
                            <Source func={fetchDetails} viewID={leadid}
                                sources={data} hideEditBtn={true} />


                        </div >

                        <div className='detail-page-right-block'>
                            <div className='detail-page-right-block-inner'>
                                <div class="pop-det-text"  >
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Status:
                                    </Typography>
                                    <StatusSelector
                                        statusList={statusList}
                                        options={data?.crm_status_details}
                                        id={data?.id} />
                                </div>


                                <div class="pop-det-text"  >
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}
                                    >
                                        Next Follow Up Date:

                                    </Typography>
                                    <DateInput
                                        id={leadid} // Pass the unique identifier for this specific date field
                                        name={`follow_up_date - ${leadid} `}
                                        // Provide a unique name for each date field
                                        setValue={setValue}
                                        control={control}
                                        value={data?.follow_up_date}
                                    />
                                </div>

                                <div class="pop-det-text"  >
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Assign To:
                                    </Typography>
                                    <Button size='small' style={{ color: '#6f6f6f' }}>
                                        <AssignTo
                                            id={leadid}
                                            isAssigned={data?.assigned_to_details}
                                            // isDisable={ }
                                            DtView={data?.assigned_to_details?.id ? true : false}
                                            control={control}
                                        />
                                    </Button>
                                </div>
                                <div class="pop-det-text"  >
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Label:
                                    </Typography>
                                    <LabelsComponent
                                        viewID={leadid}
                                        currentLabel={data?.label}
                                        isLoading={isLoading}
                                        lablesList={labels}
                                        func={fetchDetails}

                                    />
                                </div>

                            </div>
                            <div >
                                <ExtraData data={data} />
                            </div>
                            <div style={{
                                padding: '14px',
                                border: '1px solid #cfcfcf'
                            }}>
                                <SiteVisitTab data={data} func={fetchDetails} viewID={leadid} />
                            </div>


                            <div className='full-detail-note-block' style={{
                                padding: '14px',
                                border: '1px solid #cfcfcf',
                                marginTop: 14
                            }}>
                                <AddNotes viewID={leadid} />
                            </div>

                        </div>


                    </div >
                )
            }
        </ >

    )
}

export default Index