import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = (props) => {
    // Extracting labels from the data
    const labels = props.data.map(item => item.name || item.day);

    // // Extracting counts from the data
    const counts = props.data.map(item => item.count);


    const data = {
        labels: labels,
        datasets: [
            {
                label: `${props.label}`,
                data: counts,
                backgroundColor: `${props.color}`,
            }
        ],
    };

    return <Line options={props.options} data={data} />;
}

export default LineChart;
