import React from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'

function index() {
    return (

        <div className='page-cntr'>

            <div class="lead-table  lead-page-cntr">
                <div className="title-sec">

                    <PageHeader title={'Agents'} />


                </div>
            </div>

        </div >
    )
}

export default index