import { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CustomToolbar from '../../../components/Tools/CustomeToolbar';
import moment from 'moment';
import View from './View'
import DateInput from '../../../components/Form/DateInput';
import { useForm } from 'react-hook-form';
import StatusSelector from '../../../components/Form/Status';
import PageHeader from '../../../components/PageHeader/PageHeader';
import AssignTo from '../../../components/Form/AssignTo';
import useAssignees from '../../../components/CommonFunctions/Usres';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Delete from '../../../components/Popup/Delete';
import { FollowUps } from '../../../api/Endpoints/FollowUps';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Waveform } from '@uiball/loaders';
import Create from '../Leads/create';
import { GlobalRefreshContext } from '../../../contexts/GlobalRefresh/GlobalRefresh';
import StatusLIstFunction from '../../../components/CommonFunctions/statusLIst';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseLeadPopup from '../../../components/Popup/ClosedLead';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import SearchField from '../../../components/TableElements/SearchField'
export default function FolloUps() {


    const columns = [

        {
            field: 'name', headerName: 'Customer Name', width: 250, renderCell: (params) => (
                <Typography
                    size="small"
                    sx={{ cursor: 'pointer', whiteSpace: 'break-spaces' }}
                    onClick={() => viewTabs(params)}
                >
                    {params.row.name}

                </Typography>
            )

        },


        {
            field: 'assigned_to_details',
            headerName: 'Assign To',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button size='small' style={{ color: '#6f6f6f' }}>
                        <AssignTo id={params.id}
                            isAssigned={params.row.assigned_to_details}
                            name={`assigned_to_details`}
                            assignees={assignees}
                            isLoading={isLoading}
                            setValue={setValue}
                            func={() => { fetchFollowUpsForTableUpdate() }}
                            watch={watch} />

                    </Button>
                </>
            ),



        },

        {
            field: 'created_at',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => {
                const formattedDate = moment(params.value).format('DD-MM-YYYY');
                return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;
            },
        },
        {
            field: 'project_name',
            headerName: 'Project Name',
            width: 150,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.project?.name}</Typography>;
            }
        }, {
            field: 'source_name',
            headerName: 'Source',
            width: 120,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.source}</Typography>;
            }
        },
        {
            field: 'follow_up_date',
            headerName: 'Follow Up',
            width: 180,
            renderCell: (params) => {
                const { id } = params;
                return (
                    <DateInput
                        id={id} // Pass the unique identifier for this specific date field
                        name={`follow_up_date-${id}`}
                        // Provide a unique name for each date field
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        value={params.row?.follow_up_date}
                        // Update the watch function call here
                        func={() => { fetchFollowUpsForTableUpdate() }
                        }
                    />
                );
            }
        },

        {
            field: 'status',
            headerName: 'Status',
            width: 180,
            renderCell: (params) => {
                return (
                    <StatusSelector
                        statusList={statusList}
                        options={params.row?.crm_status_details}
                        id={params.id}
                        func={fetchFollowUpsForTableUpdate}
                    />
                )
            }
        },

        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown params={params} />,
        },


    ]
    const { watch, formState: { errors }, control, Controller, setValue, reset } = useForm({})



    const [loading, setLoading] = useState(false);
    const [leads, setLeads] = useState([])
    const [ViewID, setViewID] = useState(0)
    const [refresh, setRefresh] = useState(0)
    const { assignees, isLoading, fetchAssignees } = useAssignees();
    const [Modal, setModal] = useState(false);
    const [editId, setEditId] = useState(undefined);
    const [DeleteID, setDeleteID] = useState(0)
    const [serachEntry, setSearchEntry] = useState('')
    const { GlobalRefresh } = GlobalRefreshContext();
    const [selectedRow, setSelectedRow] = useState([])
    const { statusList, fetchStatusList, } = StatusLIstFunction();
    const [showClosePopup, setShowClosePopup] = useState(false)
    const [CloseID, setCloseID] = useState(0)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const controller = new AbortController();
    const signal = controller.signal;

    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);

    useEffect(() => {
        const fromParam = queryParams.get('from');
        const toParam = queryParams.get('to');

        // Set selectedFromDate and selectedToDate based on URL parameters
        setSelectedFromDate(fromParam ? fromParam : null);
        setSelectedToDate(toParam ? toParam : null);

    }, [location.search]);


    useEffect(() => {
        fetchFollowUps();
    }, [paginationModel, selectedToDate, selectedFromDate, GlobalRefresh, serachEntry]);

    const fetchFollowUps = async () => {
        try {
            setLoading(true);
            const response = await FollowUps.get({
                page: parseInt(paginationModel?.page) + 1,
                from: selectedFromDate ? moment(selectedFromDate).format('YYYY-MM-DD') : '',
                to: selectedToDate ? moment(selectedToDate).format('YYYY-MM-DD') : '',
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
                filter: serachEntry || null,
            });
            setLeads(response.data.leads);
            setLoading(false);
        } catch (error) {
            console.log(error.message);
            setLoading(false);
        }
    };

    const fetchFollowUpsForTableUpdate = async () => {
        try {

            const response = await FollowUps.get({
                page: parseInt(paginationModel?.page) + 1,
                from: selectedFromDate ? moment(selectedFromDate).format('YYYY-MM-DD') : null,
                to: selectedToDate ? moment(selectedToDate).format('YYYY-MM-DD') : null,
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
                filter: serachEntry || null,

            });
            setLeads(response.data.leads);

        } catch (error) {
            console.log(error.message);

        }
    };


    useEffect(() => {
        fetchStatusList()
    }, [])



    // More option Funciton

    const MoreOptionsDropdown = ({ params }) => {
        const [anchorEl, setAnchorEl] = useState(null);


        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };


        const handleClose = () => {
            setAnchorEl(null);
        };


        const handleEdit = (id) => {
            setEditId(parseInt(id));
            setModal(true)
            setRefresh(Math.random)
        }

        const handleDelete = (id) => {
            setDeleteID(id)
            handleClose();
        };

        const handleCloseLead = (id) => {
            setCloseID(id)
            setShowClosePopup(true)
        }

        return (
            <>
                <IconButton size="small" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem class="edit-nav-cntr" onClick={() => handleEdit(params.id)}>
                        <EditIcon sx={{ fontSize: 14, mr: 1 }} />
                        Edit
                    </MenuItem>

                    <MenuItem class="edit-nav-cntr delete-sec" onClick={() => handleDelete(params.id)}>
                        <DeleteIcon sx={{ fontSize: 14, mr: 1 }} />
                        Delete
                    </MenuItem>

                    <MenuItem class="edit-nav-cntr" onClick={() => handleCloseLead(params.id)}>
                        <ArchiveIcon sx={{ fontSize: 14, mr: 1 }} />
                        Archive
                    </MenuItem>
                </Menu >
            </>
        );
    };

    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }
    const handleUpdate = () => {
        setPaginationModel(0);
    }

    const viewTabs = (params) => {
        setViewID(params.id)
        setRefresh(Math.random)
    }


    const handleRowSelection = (newSelection) => {
        setSelectedRow(newSelection)
    };

    const handleRowSelectionDelete = () => {
        if (selectedRow.length > 0) {
            setDeleteID(selectedRow)
        } else {
            toast.warning("Please select a lead to delete", { autoClose: 3000 });
        }

    }

    const clearDatePicker = () => {
        setSelectedFromDate(null)
        setSelectedToDate(null)
        setSearchEntry('')
    }


    const handlePageChange = (page) => {
        setPaginationModel(page)
    }

    const handleSearchEntry = (event) => {

        setSearchEntry(event.target.value);
        setPaginationModel({
            pageSize: 15,
            page: 0,
        })




    }

    return (
        <div class="page-cntr">




            {showClosePopup && <CloseLeadPopup id={CloseID} setShowClosePopup={setShowClosePopup} func={fetchFollowUps} />}
            <div class="lead-cntr follo-up-cntr " >

                <PageHeader title={'Follow Ups'} total={!loading && leads.total} />

                <LocalizationProvider dateAdapter={AdapterMoment} >
                    <Grid container direction="row"
                        mb={2}
                    >
                        <Grid item xs={3}  >
                            <div class="follw-dates">
                                <DatePicker

                                    value={selectedFromDate}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="From" fullWidth />
                                    )}
                                    onChange={(newValue) => setSelectedFromDate(newValue)}
                                    inputFormat="DD/MM/YYYY"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={3}  >
                            <DatePicker
                                value={selectedToDate}

                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="To" fullWidth
                                    />
                                )}
                                onChange={(newValue) => setSelectedToDate(newValue)}
                                inputFormat="DD/MM/YYYY"
                            />
                        </Grid>
                        <Grid item xs={5}  >
                            <TextField
                                variant="outlined"
                                placeholder={"Search"}
                                color="primary"
                                onChange={handleSearchEntry}
                                style={{ marginBottom: 4, marginLeft: 10 }}
                                value={serachEntry && serachEntry}
                            />

                            <IconButton className=''
                                sx={{ ml: 2, mt: 1, background: 'white' }}

                                edge="end"

                                onClick={clearDatePicker}

                            >
                                <ClearIcon />
                            </IconButton>

                        </Grid>

                    </Grid>

                </LocalizationProvider>

                <IconButton className='dlt-icon-dash followup'
                    sx={{ mr: 2, background: 'white' }}

                    edge="end"

                    onClick={handleRowSelectionDelete}

                >
                    <DeleteIcon className='delete-icon' color='red' />
                </IconButton>



                <Card sx={{ display: 'flex', justifyContent: 'start' }}  >

                    {/* Props Passing  */}
                    <View
                        from={selectedFromDate && selectedFromDate}
                        to={selectedToDate && selectedToDate}
                        viewID={ViewID}
                        setViewID={setViewID}
                        watch={watch}
                        control={control}
                        isDisable={true}
                        setFormValue={setValue}
                        statusList={statusList}
                        assignees={assignees} //Assignees list ,From AssignUser Api
                        func={fetchFollowUpsForTableUpdate} searchIsEnabled={serachEntry && true} //Fetch leads funcion to Update the Dom
                    />



                    <Delete
                        DeleteID={DeleteID}
                        setDeleteID={setDeleteID}
                        Callfunc={fetchFollowUps}
                        url={DeleteID.length > 0 ? '/lead/action/bulk-delete' : '/lead/action/delete'}
                        title={DeleteID.length > 0 ? "these Leads" : "this Lead"}
                    />


                    {Modal &&
                        <Create
                            key={refresh}
                            onNew={handleCreate}
                            onUpdate={handleUpdate}
                            editId={editId}
                            setEditId={setEditId}
                            setModal={setModal}
                            disableCtnBtn={true} />}

                </Card >




                {/* // {showClosePopup && <CloseLeadPopup id={CloseID} setShowClosePopup={setShowClosePopup} func={fetchLeads} />} */}
                {
                    loading ? (
                        <Box sx={{ width: '100%', marginTop: 10, height: 20, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                    ) : (
                        <>

                            {leads.data && leads.data.length === 0 ? (
                                <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'cent' }}>No follow ups.</Typography>
                            ) : (
                                <Card sx={{ m: 0 }} variant="outlined" class="lead-table followup-table">
                                    {typeof leads.data === "object" && (
                                        <DataGrid
                                            paginationMode="server"
                                            rows={leads?.data}
                                            paginationModel={paginationModel}
                                            columns={columns}
                                            rowCount={leads?.total}
                                            rowHeight={70}
                                            autoHeight={true}
                                            scrollbarSize={10}
                                            density="comfortable"
                                            checkboxSelection
                                            onPaginationModelChange={handlePageChange}
                                            onRowSelectionModelChange={handleRowSelection}
                                            disableColumnMenu
                                            disableRowSelectionOnClick
                                            disableExtendRowFullWidth

                                        />
                                    )}
                                </Card>
                            )}

                        </>
                    )}
            </div >
        </div >

    )
};


