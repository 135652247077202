import EditIcon from '@mui/icons-material/Edit';
import { Leads } from '../../../api/Endpoints/Leads'
import { useEffect, useState } from 'react';
import { Badge, Box, Button, Card, Grid, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DateInput from '../../../components/Form/DateInput';
import { useForm } from 'react-hook-form';
import StatusSelector from '../../../components/Form/Status';
import DeleteIcon from '@mui/icons-material/Delete';
import Create from './create';
import PageHeader from '../../../components/PageHeader/PageHeader';
import View from './View';
import AssignTo from '../../../components/Form/AssignTo';
import useAssignees from '../../../components/CommonFunctions/Usres';
import Delete from '../../../components/Popup/Delete';
import CloseLeadPopup from '../../../components/Popup/ClosedLead';
import { Waveform } from '@uiball/loaders'
import ImportLead from './ImportLead/Index';
import { GlobalRefreshContext } from '../../../contexts/GlobalRefresh/GlobalRefresh';
import { Fragment } from 'react';
import StatusLIstFunction from '../../../components/CommonFunctions/statusLIst';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FileDownload } from '../../../api/Endpoints/fileDowenold';
import ArchiveIcon from '@mui/icons-material/Archive';
import { toast } from 'react-toastify';
import FilterListIcon from '@mui/icons-material/FilterList';
import LeadFilter from '../../../components/Popup/LeadFilter';
import FilterBadges from '../../../components/FilterBadge/FilterBadges';
import SearchField from '../../../components/TableElements/SearchField'
import TextInput from '../../../components/Form/TextInput';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import QuickEdit from '../../../components/Popup/QuickEditTab';
import WarningIcon from '@mui/icons-material/Warning';
import DataList from '../../../components/Popup/RepeatedLead';
export default function LeadsTable() {

    const [isHovered, setIsHovered] = useState(false);
    const [rowId, setRowID] = useState()
    const [quickEditOpen, setQuickEditOpen] = useState(false)
    const columns = [
        {
            field: 'name',
            headerName: 'Customer Name',
            width: 230,
            renderCell: (params) => {


                return (
                    <div
                        onMouseEnter={() => {
                            setIsHovered(true)
                            setRowID(params.row.id)
                        }}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ display: 'flex', alignItems: 'center', width: '100%', whiteSpace: 'break-spaces' }}
                    >
                        <span
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => viewTabs(params)}
                        >
                            {params.row.name}

                        </span>
                        {isHovered && rowId === params.row.id && (
                            <IconButton size="small" onClick={() => handleQuickEdit(params.row)}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        )}
                        {params.row.duplicate_flag === true && (
                            <Button onClick={() => hanldeRepeatLeadClick(params.row)} >
                                < WarningIcon sx={{
                                    fontSize: 18,
                                    color: "#FAAB53",
                                    marginLeft: 1
                                }} />
                            </Button>

                        )}



                    </div >
                );
            },
        },

        {
            field: 'assigned_to_details',
            headerName: 'Assign To',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button size='small' style={{ color: '#6f6f6f' }}>
                        <AssignTo id={params.id}
                            isAssigned={params.row.assigned_to_details}
                            name={`assigned_to_details`}
                            assignees={assignees}
                            isLoading={isLoading}
                            setValue={setValue}
                            // func={() => { fetchLeadsForTableUpdate() }}
                            watch={watch} />

                    </Button>
                </>
            ),



        },


        {
            field: 'created_at',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => {
                const formattedDate = moment.utc(params.row.created_at).format('DD-MM-YYYY');
                return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;
            },
        },
        {
            field: 'project_name',
            headerName: 'Project Name',
            width: 150,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.project?.name}</Typography>;
            }
        }, {
            field: 'source_name',
            headerName: 'Source',
            width: 120,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.source}</Typography>;
            }
        },
        {
            field: 'follow_up_date',
            headerName: 'Follow Up',
            width: 150,
            renderCell: (params) => {
                const { id } = params;
                return (
                    <DateInput
                        id={id} // Pass the unique identifier for this specific date field
                        name={`follow_up_date-${id}`}
                        // Provide a unique name for each date field
                        control={control}
                        setValue={setValue}
                        // watch={watch}
                        value={params.row?.follow_up_date}
                        // Update the watch function call here
                        func={() => { fetchLeadsForTableUpdate() }
                        }
                    />
                );
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            renderCell: (params) => {
                return (
                    <StatusSelector
                        statusList={statusList}
                        options={params.row?.crm_status_details}
                        id={params.id}
                        func={fetchLeads}
                    />
                )
            }
        },

        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown params={params} />,
        },


    ]


    const { watch, formState: { errors }, control, Controller, setValue, reset } = useForm({})


    const [loading, setLoading] = useState(false);
    const [leads, setLeads] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [editId, setEditId] = useState(undefined);
    const [ViewID, setViewID] = useState(0)
    const [DeleteID, setDeleteID] = useState(0)
    const [isMounted, setIsMounted] = useState(false);
    const [showClosePopup, setShowClosePopup] = useState(false)
    const [CloseID, setCloseID] = useState(0)
    const { GlobalRefresh } = GlobalRefreshContext();
    const [selectedRow, setSelectedRow] = useState([])
    const [searchKeyword, setSearchKeyword] = useState('');
    const [showFilterPopup, setShowFilterPopup] = useState(false)
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const [TableUpdate, setTableUpdate] = useState(false)
    const [serachEntry, setSearchEntry] = useState('')
    const [repatedLeadOpen, setRepeatedLeadOpen] = useState(false)
    const [ID, setID] = useState(0)

    const [filterData, setFilterData] = useState(
        {
            from: '',
            to: '',
            assing_to_users: '',
            lead_source: '',
            campaign_name: ''

        }
    )

    const location = useLocation();

    const navigate = useNavigate();
    const getPageNumber = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('page');
    };

    const pageNumber = getPageNumber();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: pageNumber || 0
        ,
    });

    const fetchLeads = async () => {

        try {
            if (!TableUpdate) {
                setLoading(true);
            }
            const response = await Leads.get({
                page: parseInt(paginationModel?.page) + 1,
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
                from_date: filterData.from,
                to_date: filterData.to,
                assigned_users: filterData?.assing_to_users,
                lead_source: filterData.lead_source,
                filter: serachEntry || watch('searchbynameorphoneoremail'),
                campaign_name: filterData?.campaign_name,
            });
            setLeads(response.data.leads);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        finally {
            if (TableUpdate) {
                setTableUpdate(false);
            }
        }
    };

    const handleFilter = () => {



        const queryParams = new URLSearchParams(window.location.search);
        const page = queryParams.get('page');


        if (page) {
            queryParams.delete('page');

            setPaginationModel({
                pageSize: 15,
                page: 0
            })
            window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
        }
        setFilterData(
            {
                from: watch('from') ? moment(watch('from')).format('DD-MM-YYYY') : undefined,
                to: watch('to') ? moment(watch('to')).format('DD-MM-YYYY') : undefined,
                assing_to_users: watch('assing_to_users') && watch('assing_to_users').map((assignUser) => assignUser.id),
                lead_source: watch('lead_source') && watch('lead_source').map((leadSource) => leadSource.name),
                searchbynameorphoneoremail: watch('searchbynameorphoneoremail') || null,


            })



        setShowFilterPopup(false)

    }

    const fetchLeadsForTableUpdate = async () => {
        try {
            const response = await Leads.get(
                {
                    page: parseInt(paginationModel?.page) + 1,
                    crm_lead_type_id: leadTypeData?.ID,
                    project_id: projectData.ID,
                    from_date: filterData.from,
                    to_date: filterData.to,
                    assigned_users: filterData.assigned_users,
                    lead_source: filterData.lead_source,
                    filter: searchKeyword

                });
            setLeads(response.data.leads);

        } catch (error) {
            console.log(error);

        }
    };


    const { assignees, isLoading, fetchAssignees, } = useAssignees();
    const { statusList, fetchStatusList, } = StatusLIstFunction();

    const handlePageChange = (page) => {
        setPaginationModel(page)
        const queryParams = new URLSearchParams(location.search);
        if (page && page.page > 0) {
            queryParams.set('page', page.page);
        } else {
            queryParams.delete('page');
        }
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        setRefresh(Math.random)
    }


    const handleQuickEdit = (row) => {
        setRowID(row?.id)
        setQuickEditOpen(true)
    }

    const MoreOptionsDropdown = ({ params }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = (id) => {
            setEditId(parseInt(id));

        }

        const handleDelete = (id) => {
            setDeleteID(id)
            handleClose();
        };
        const handleCloseLead = (id) => {

            setCloseID(id)
            setShowClosePopup(true)
        }

        return (
            <>
                <IconButton size="small" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>

                    <MenuItem class="edit-nav-cntr" onClick={() => handleEdit(params.id)}>
                        <EditIcon sx={{ fontSize: 14, mr: 1 }} />
                        Edit
                    </MenuItem>
                    <MenuItem class="edit-nav-cntr delete-sec" onClick={() => handleDelete(params.id)}>
                        <DeleteIcon sx={{ fontSize: 14, mr: 1 }} />
                        Delete
                    </MenuItem>
                    <MenuItem class="edit-nav-cntr" onClick={() => handleCloseLead(params.id)}>
                        <ArchiveIcon sx={{ fontSize: 14, mr: 1, }} />
                        Archive
                    </MenuItem>
                </Menu >
            </>
        );
    };

    useEffect(() => {


        fetchLeads();


    }, [
        serachEntry,
        refresh,
        GlobalRefresh,
        filterData,
    ]);

    useEffect(async () => {
        await fetchStatusList()
    }, [])

    const handleCreate = () => {
        setEditId(0)
    }
    const handleUpdate = () => {
        setRefresh(Math.random)
    }
    const showFilterPopupFunc = () => {
        setShowFilterPopup(true)
    }

    const closeFilterBadge = (filterName) => {
        setFilterData(prevFilterData => ({
            ...prevFilterData,
            [filterName]: null
        }));
        setValue(filterName, null)
        setRefresh(Math.random)
    }

    const viewTabs = (params) => {
        setViewID(params.id)
    }

    const handleRowSelection = (newSelection) => {
        setSelectedRow(newSelection)
    };

    const handleRowSelectionDelete = () => {
        if (selectedRow.length > 0) {
            setDeleteID(selectedRow)
        } else {
            toast.warning("Please select a lead to delete", { autoClose: 3000 });
        }

    }


    const fileDownloadHandler = async () => {

        const response = await FileDownload.get({
            from_date: watch('from') ? moment(watch('from')).format('DD-MM-YYYY') : undefined,
            to_date: watch('to') ? moment(watch('to')).format('DD-MM-YYYY') : undefined,
            project_id: projectData?.ID,
            assigned_users: watch('assing_to_users') && watch('assing_to_users').map((assignUser) => assignUser.id),
            lead_source: watch('lead_source') && watch('lead_source').map((leadSource) => leadSource.name),

        });
        const url = response?.data.export;

        if (url) {
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.setAttribute('download', " ")
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        } else {
            console.error("Invalid URL for file download");
        }


    };
    const clearSerachInput = () => {
        setSearchEntry('');
        setPaginationModel({
            pageSize: 15,
            page: 0,
        })


    }
    const handleSearchEntry = (event) => {

        setPaginationModel({
            pageSize: 15,
            page: 0,
        })
        // closeFilterBadge('searchbynameorphoneoremail', null)

        setSearchEntry(event.target.value);
        setTableUpdate(true)
    }



    const hanldeRepeatLeadClick = (id) => {

        setID(id?.id)
        setRepeatedLeadOpen(true)
    }

    return (
        <>
            <QuickEdit open={quickEditOpen} setOpen={setQuickEditOpen} id={rowId} />
            <DataList open={repatedLeadOpen} setOpen={setRepeatedLeadOpen} ID={ID} setID={setID} />
            <div class="page-cntr">



                <div class="lead-table  lead-page-cntr">

                    {/* Popup */}

                    { } <Delete
                        DeleteID={DeleteID}
                        setDeleteID={setDeleteID}
                        Callfunc={fetchLeadsForTableUpdate}
                        url={DeleteID.length > 0 ? '/lead/action/bulk-delete' : '/lead/action/delete'}
                        title={DeleteID.length > 0 ? "Leads" : "this Lead"}
                    />



                    {showClosePopup && <CloseLeadPopup id={CloseID} setShowClosePopup={setShowClosePopup} func={fetchLeads} />}

                    {showFilterPopup && <LeadFilter open={showFilterPopup} setOpen={setShowFilterPopup} watch={watch} control={control} reset={reset} handleFilter={handleFilter} setFilterData={setFilterData} setValue={setValue} />}
                    < div class="title-sec">
                        <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >


                            <PageHeader title={'Leads'} total={!loading && leads.total} />


                            <SearchField
                                clearSerachInput={clearSerachInput}
                                name={'search'}
                                placeholder="Name,Email or Phone"
                                serachEntry={serachEntry}
                                handleSearchEntry={handleSearchEntry}
                            />

                            <Tooltip title="Filters" arrow>
                                <IconButton className='filter-btn-pop'
                                    sx={{ mr: 2 }}

                                    edge="end"

                                    onClick={showFilterPopupFunc}

                                >

                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                            <ImportLead handleUpdate={handleUpdate} />


                            <IconButton className='dlt-icon'
                                sx={{ mr: 2 }}

                                edge="end"

                                onClick={handleRowSelectionDelete}

                            >
                                <DeleteIcon className='delete-icon' color='red' />
                            </IconButton>



                            <Tooltip title="download" arrow>

                                <IconButton className='export-icon'
                                    sx={{ mr: 2 }}

                                    edge="end"

                                    onClick={fileDownloadHandler}

                                >
                                    <FileDownloadIcon className='delete-icon' color='red' />
                                </IconButton>
                            </Tooltip>



                            <Create
                                onNew={handleCreate}
                                onUpdate={handleUpdate}
                                editId={editId}
                                setEditId={setEditId} />

                        </Card>
                        {/* {
                        leads?.data?.length > 0 && !loading ?

                        

                            : null
                    } */}



                        <View
                            viewID={ViewID}
                            setViewID={setViewID}
                            watch={watch}
                            control={control}
                            page={paginationModel.page}
                            setFormValue={setValue}
                            assignees={assignees}
                            setValue={setValue}
                            statusList={statusList}
                            func={fetchLeadsForTableUpdate}
                            searchIsEnabled={serachEntry && true} />


                    </div>



                    <Grid container sx={{ display: 'flex', justifyContent: 'end' }}>
                        {filterData?.from && (<FilterBadges label={"From"} value={filterData.from} handleClose={() => closeFilterBadge('from')} />)}

                        {filterData?.to && (<FilterBadges label={"To"} value={filterData.to} handleClose={() => closeFilterBadge('to')} />)}
                        {filterData?.assing_to_users?.length > 0 && (<FilterBadges label={"Assigned User"} value={`${filterData?.assing_to_users.length} option selected`} handleClose={() => closeFilterBadge('assing_to_users')} />)}
                        {filterData?.lead_source?.length > 0 && (<FilterBadges label={"Lead Source"} value={`${filterData?.lead_source.length} option selected`} handleClose={() => closeFilterBadge('lead_source')} />)}
                        {filterData?.searchbynameorphoneoremail && (<FilterBadges label={"Search by"} value={filterData.searchbynameorphoneoremail} handleClose={() => closeFilterBadge('searchbynameorphoneoremail')} />)}



                    </Grid>
                    {!TableUpdate && loading ?
                        <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                        : (
                            <>
                                {
                                    leads.data?.length > 0 ? (
                                        <Fragment>
                                            < Card sx={{
                                                m: 0,
                                            }} variant="outlined" class="lead-table" >

                                                {
                                                    typeof leads.data === "object" &&
                                                    <DataGrid

                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        paginationMode="server"
                                                        rows={leads?.data}
                                                        paginationModel={paginationModel}
                                                        columns={columns}
                                                        rowCount={leads?.total}
                                                        rowHeight={70}
                                                        autoHeight={true}
                                                        scrollbarSize={10}
                                                        density="comfortable"
                                                        checkboxSelection
                                                        onPaginationModelChange={handlePageChange}
                                                        onRowSelectionModelChange={handleRowSelection}
                                                        disableColumnMenu
                                                        disableRowSelectionOnClick
                                                        disableExtendRowFullWidth
                                                    />
                                                }

                                            </ Card>
                                        </Fragment>
                                    ) : (

                                        <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Leads not found</Typography>
                                        </Box>
                                    )
                                }
                            </>
                        )}
                </div >


            </div >
        </>

    )
};
