import { useEffect, useState } from 'react';
import { Leads } from '../../api/Endpoints/Leads';
import { Lables } from '../../api/Endpoints/Label';


const LabelsList = () => {
    const [labels, setLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchLabels = async () => {
        setIsLoading(true);
        try {
            const response = await Lables.get();
            setLabels(response.data.data)
            return response.data.data
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    return { labels, isLoading, fetchLabels };
};

export default LabelsList;
