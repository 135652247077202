import React, { useEffect, useState } from 'react'
import DetailPageNavBar from '../../../containers/DetailPageNavbar'
import { Link, useParams } from 'react-router-dom';
import { TriageLeadsAPI } from '../../../api/Endpoints/TriageLeadsApi';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import moment from 'moment';

import { useForm } from 'react-hook-form';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Source from '../Leads/Tabs/Source';
import ExtraData from '../Leads/Tabs/ExtraData';
import AssignProject from '../../../components/Form/AssignProject';
function TriageDetails() {
    const { triageid } = useParams();

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})



    const navigate = useNavigate();

    useEffect(async () => {
        setLoading(true)
        fetchDetails()
    }, [triageid])



    const fetchDetails = () => {
        TriageLeadsAPI.details({ id: triageid })
            .then((response) => {
                setData(response.data.triage);
                setLoading(false)

            })
            .catch((err) => {
                console.log("err", err);
            });
    }
    const hanldeNavigate = () => {
        navigate(-1);
    };


    return (

        <>



            <div className="cnt-cntr-details">
                <div className="fixe-menu-detail">{<DetailPageNavBar />}</div>
            </div>
            <div className='go-back-btn'>

                <IconButton style={{ background: 'rgba(80, 97, 118, 0.04)' }} onClick={() => hanldeNavigate()}>
                    <ArrowBackIcon />
                </IconButton>


            </div >

            {
                loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }} >
                        <CircularProgress />
                    </Box >
                ) : (

                    < div className='details-page-block-content'>

                        <div class="detail-page-left-block">
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Lead Type:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.crm_lead_type?.name}
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Project:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    <Button size='small' style={{ color: '#6f6f6f' }}>
                                        <AssignProject
                                            id={triageid}

                                            name={`assigned_to_details`}
                                            setValue={setValue}
                                            watch={watch}
                                            func={fetchDetails}
                                            frmDetailsPage={true} />

                                    </Button>
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Customer Name:

                                </Typography>

                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.name}
                                </Typography>
                            </div>

                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Phone Number:

                                </Typography>

                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.phone_number}
                                </Typography>
                            </div>

                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    Email:

                                </Typography>

                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.email}
                                </Typography>
                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Age:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Profession:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Location:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.location}
                                </Typography>

                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Country:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.country?.name}
                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    City:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Requsted Date:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {moment(data?.created_at).format('DD-MM-YYYY')}

                                </Typography>

                            </div>




                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Lead Source:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Agent:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>
                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Referred By:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.referred_by}
                                </Typography>

                            </div>

                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Campaign:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>

                                </Typography>

                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Campaign Name:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                </Typography>

                            </div>


                            <div class="pop-det-text"  >
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Description:</Typography>
                                <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                    {data?.remarks}
                                </Typography>

                            </div>

                            <Source func={fetchDetails} viewID={triageid}
                                sources={data} hideEditBtn={true} />


                        </div >

                        <div className='detail-page-right-block'>

                            <div>
                                <ExtraData data={data} />
                            </div>


                        </div>


                    </div >
                )
            }
        </ >

    )
}

export default TriageDetails