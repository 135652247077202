import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Details from './Tabs/Details';
import DataTable from './Tabs/Notes';
import Source from './Tabs/Source';
import { Button, CircularProgress } from '@mui/material';
import SiteVisits from '../../../components/Popup/SiteVisits';
import { Leads } from '../../../api/Endpoints/Leads';
import { useEffect } from 'react';
import SiteVisitTab from './Tabs/SiteVisit';
import ExtraData from './Tabs/ExtraData';
import { Link } from 'react-router-dom';
import Notes from './FullDetailsPage/AddNotes'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={2}>
                    <Typography>{children}</Typography>
                </Box>
            )
            }
        </div >
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function BasicTabs({ viewID, control, watch, assignees, func, setViewID, statusList, setValue, page, frmSubMen, slug, fromHome }) {
    const [value, setNewValue] = React.useState(0);
    const [data, setData] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const handleChange = (event, newValue) => {
        setNewValue(newValue);
    };
    const fetchDetails = (retryCount = 2) => {

        setLoading(true)
        const fetchData = () => {
            Leads.getDetails({ id: viewID })
                .then((response) => {
                    setData(response.data.lead);
                    setLoading(false)

                })
                .catch((err) => {
                    console.log(err.message);

                    if (retryCount > 0) {
                        setTimeout(() => {
                            fetchData(retryCount - 1);
                        }, 1000);
                    } else {
                        setLoading(false)
                        console.log("Failed to fetch details");
                    }
                });
        };

        fetchData();
    };


    useEffect(() => {
        fetchDetails()
    }, [])


    let url;
    if (frmSubMen) {
        url = `/dashboard/lead-details/${viewID}${page !== 0 ? `?page=${page}&slug=${slug}` : `?slug=${slug}`}`

    } else {
        if (fromHome) {
            url = `/dashboard/lead-details/${viewID}${page !== 0 ? `?page=${page}&home=${true}` : `?home=${true}`} `;
        } else {
            url = `/dashboard/lead-details/${viewID}${page !== 0 ? `?page=${page}` : ''} `;
        }

    }
    return (
        <>
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <CircularProgress />
                </Box >
            ) : (
                <>

                    <Box sx={{ width: '100%', mt: 1 }}>
                        <div class="pop-det-text" style={{ marginLeft: 5 }} >
                            <Link to={url} style={{ fontSize: 15 }} target='_blank'>
                                View Full Details
                            </Link>

                        </div >

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Details" {...a11yProps(0)} />
                                <Tab label="Source" {...a11yProps(1)} />
                                {data?.extra_data !== null && <Tab label="Extra Data" {...a11yProps(2)} />}
                                <Tab label="Site Visits" {...a11yProps(data?.extra_data !== null ? 3 : 2)} />
                                <Tab label="Notes" {...a11yProps(data?.extra_data !== null ? 4 : 3)} />
                            </Tabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <Details
                                viewID={viewID}
                                watch={watch}
                                data={data}
                                control={control}
                                assignees={assignees}
                                func={func}
                                setViewID={setViewID}
                                setValue={setValue}
                                statusList={statusList}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Source viewID={viewID} sources={data} func={fetchDetails} />
                        </TabPanel>
                        {data?.extra_data !== null && (
                            <TabPanel value={value} index={2}>
                                <ExtraData data={data} />
                            </TabPanel>
                        )}
                        <TabPanel value={value} index={data?.extra_data !== null ? 3 : 2}>
                            <SiteVisitTab viewID={viewID} data={data} func={fetchDetails} />
                        </TabPanel>
                        <TabPanel value={value} index={data?.extra_data !== null ? 4 : 3}>
                            <Notes viewID={viewID} />
                        </TabPanel>

                    </Box >
                </>
            )
            }
        </>
    );
}
