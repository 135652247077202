import { Suspense, lazy, useState } from 'react';
import { GuestGuard } from "./guards/GuestGuard";
import { LoadingScreen } from './components/loading-screen';
import AuthGuard from "./guards/AuthGuard";

import { Navigate, Outlet } from "react-router-dom";
import ForgotPassword from './components/login/forgot-password';
import ButtonAppBar from './containers/Navbar';
// pages
import Home from './project-portal/pages/Home/Home'
import Leads from './project-portal/pages/Leads/Leads'
import FollowUps from './project-portal/pages/Followups/Followups'
import SubMenus from './project-portal/pages/Leads/SubMenus/SubMenus';
import Recyclebin from './project-portal/pages/RecycleBin/Recyclebin'
import { DashboardSidebar } from './components/dashboard-sidebar';
import ArchivedLeads from './project-portal/pages/ArchivedLeads/ArchivedLeads';
import Settigns from './project-portal/pages/settings'
import Labels from './project-portal/pages/settings/Label'
import LabelLeads from './project-portal/pages/settings/LabelLeads/Index'
import SiteVisitsPage from './project-portal/pages/SiteVisits';
import Campaigns from './project-portal/pages/Campaigns';
import Reports from './project-portal/pages/Reports'
import Agent from './project-portal/pages/Agents'
import FullDetailsPage from './project-portal/pages/Leads/FullDetailsPage'
import TriageLeads from './project-portal/pages/TriageLeads';
import TriageDetails from './project-portal/pages/TriageLeads/Details';
import CostReport from './project-portal/pages/Reports/CostReport'
import StatusReport from './project-portal/pages/Reports/StatusReport'
import CostDetails from './project-portal/pages/Reports/CostReport/Details';
import FollowupsFullDetailsPage from './project-portal/pages/Followups/FullDetailsPage/index'
import SiteVisitDetails from './project-portal/pages/SiteVisits/FullDetailsPage'
import CountryReport from './project-portal/pages/Reports/CountryReport';
import AgeReport from './project-portal/pages/Reports/AgeReport';
import ProfessionReport from './project-portal/pages/Reports/ProfessionReport';
import RefferedReport from './project-portal/pages/Reports/RefferalReport';
import SiteVisitReport from './project-portal/pages/Reports/SiteVisitsReport';
const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));




const routes = [
    {
        path: '',
        element: (
            <GuestGuard>
                <Login />
            </GuestGuard>
        )
    },
    {
        path: 'forgot-password',
        element: (
            <GuestGuard>
                <ForgotPassword />
            </GuestGuard>
        )
    },
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardSidebar />
                <Outlet />
            </AuthGuard>
        ),
        children: [
            {
                path: "",
                element: <Navigate to="/dashboard/home" replace />,
            },
            {
                path: "home",
                element: <Home />,
            },
            {
                path: "leads",
                element: (
                    <>
                        <Outlet />
                    </>
                ),
                children: [
                    {
                        path: "",
                        element: <Leads />
                    },
                    {
                        path: ":slug",
                        element: <SubMenus />,
                    },

                ],
            },
            {
                path: "labels",
                element: (
                    <>
                        <Outlet />
                    </>
                ),
                children: [
                    {
                        path: "",
                        element: <Labels />
                    },
                    {
                        path: ":slug",
                        element: <LabelLeads />
                    },
                ],
            },


            {
                path: "follow-ups",
                element: (
                    <>
                        <FollowUps />
                    </>
                ),

            },
            {
                path: "site-visits",
                element: <SiteVisitsPage />,
            },
            {
                path: "triage-leads",
                element: <TriageLeads />,
            },
            {
                path: "campaigns",
                element: <Campaigns />,
            },
            {
                path: "reports",
                element: (
                    <>
                        <Outlet />
                    </>
                ),
                children: [
                    {
                        path: "",
                        element: <Reports />
                    },
                    {
                        path: "status-report",
                        element: <StatusReport />
                    },
                    {
                        path: "cost-report",
                        element: <CostReport />
                    },
                    {
                        path: "country-report",
                        element: <CountryReport />
                    },
                    {
                        path: "age-report",
                        element: <AgeReport />
                    },
                    {
                        path: "profession-report",
                        element: <ProfessionReport />
                    },
                    {
                        path: "referral-report",
                        element: <RefferedReport />
                    },
                    {
                        path: "site-visit-report",
                        element: <SiteVisitReport />
                    },

                ],
            },

            {
                path: "agents",
                element: <Agent />,
            },

            {
                path: "recycle-bin",
                element: <Recyclebin />,
            },

            {
                path: "archived-leads",
                element: <ArchivedLeads />,
            },
            {
                path: "settings",
                element: (
                    <>
                        <Outlet />
                    </>
                ),
                children: [

                    {
                        path: "",
                        element: <Settigns />
                    },
                    {
                        path: "labels",
                        element: <Labels />
                    },

                ]
            }
        ],
    },

    {
        path: '*',
        element: <NotFound />
    },
    {
        path: "/dashboard/lead-details/:leadid",
        element: (
            <AuthGuard>
                <FullDetailsPage />
            </AuthGuard>
        ),
    },
    {
        path: "/dashboard/follow-ups/detail/:followupId",
        element: (
            <AuthGuard>
                <FollowupsFullDetailsPage />
            </AuthGuard>
        ),
    },
    {
        path: "/dashboard/site-visits/detail/:siteid",
        element: (
            <AuthGuard>
                <SiteVisitDetails />
            </AuthGuard>
        ),
    },
    {
        path: "/dashboard/triage/triage-details/:triageid",
        element: (
            <AuthGuard>
                <TriageDetails />
            </AuthGuard>
        ),
    },
    {
        path: "/dashboard/lead/lead-details/:leadid",
        element: (
            <AuthGuard>
                <FullDetailsPage />
            </AuthGuard>
        ),
    },
    {
        path: "/dashboard/reports/cost-report/:reportid",
        element: (
            <AuthGuard>
                < CostDetails />
            </AuthGuard>
        )
    },

];

export default routes;
