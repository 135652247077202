import React, { Fragment, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    TextField,
    Slide, Typography, Box, CircularProgress
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from '../../../components/Form/TextInput';
import DateIn from '../../../components/Form/Date';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import SelectX from '../../../components/Form/SelectX';
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import CurrentDateInput from '../../../components/Form/CurrenDateInput';
import { Leads } from '../../../api/Endpoints/Leads'
import moment from 'moment';
import { fetchAssignees, fetchProjects, fetchLeads, fetchStatus, fetchLeadSources, fetchCampaignTypes } from '../../../components/CommonFunctions/DropdownLists';
import LabelsList from '../../../components/CommonFunctions/LabelsList';
import ReactSelect from 'react-select';
import { CampaignsAPI } from '../../../api/Endpoints/CampaignsAPI';
import { status } from 'nprogress';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});







const Create = (props) => {


    const scheme = yup.object().shape({
        campaign_name: yup.string().required("campaign name is required"),



    })


    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm({
        resolver: yupResolver(scheme), defaultValues: {
            ip_address: '',

        },
        mode: 'all'
    })

    const [open, setOpen] = React.useState(false);
    const [ipAddress, setIpAddress] = useState('');
    const [Loading, setLoading] = useState(false)
    const [statusLIst, setStatusList] = useState([])


    const { fetchLabels } = LabelsList();

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setModal && props.setModal(false)
        }, 200)
        props.setEditId(undefined)
        setValue('campaign_name', '')
        setValue('end_date', '')
        setValue('start_date', yup.date())
        setValue('total_budget', '')
        setValue('status', null)
        setValue('remarks', '')
        clearErrors()
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });





    const priority = [
        { id: 1, name: "ACTIVE" },
        { id: 0, name: "INACTIVE" },
    ]



    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        const startDate = data.start_date != null ? moment(data.start_date).format("YYYY-MM-DD") : '';
        const endDate = data.end_date != null ? moment(data.end_date).format("YYYY-MM-DD") : '';

        const dataToSubmit = {
            id: props?.editId,
            source_url: "CRM",
            ip_address: ipAddress,
            name: data.campaign_name,
            description: data.remarks,
            start_date: startDate,
            end_date: endDate,
            total_budget: data.total_budget,
            status: data.status?.id,
            campaign_type_id: data.campaign_type?.id

        }

        let action;
        if (props.editId > 0) {
            action = CampaignsAPI.update(dataToSubmit)
        } else {

            action = CampaignsAPI.add(dataToSubmit);
        }

        action.then(async (response) => {
            if (response.data.message === "Updated") {
                toast.success("Campaign Successfully Updated!", { autoClose: 1000 });
            } else {
                try {
                    toast.success("Campaign Successfully Saved!", { autoClose: 1000 });
                } catch (error) {
                    console.error("Error:", error);

                }
            }


            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
            handleClose()
            props.onUpdate();
        }).catch(errors => {
            toast.error("Internal Server Error, please try again later!", { autoClose: 1000 })
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
        })


    }


    async function getIP(retryCount = 0) {
        try {
            const response = await fetch('https://api.ipify.org/?format=json');
            const data = await response.json();
            setIpAddress(data.ip);
        } catch (err) {
            console.log(err);
            if (retryCount < 2) {
                retryCount++;
                getIP(retryCount);
            } else {
                console.log("Something went wrong. Failed to retrieve IP address.");
            }
        }
    }




    const fetchLeadDetails = (retryCount = 2) => {
        setLoading(true);

        const fetchData = async () => {
            try {
                const fetchCampaignTypes = await CampaignsAPI.getCampaignTypes();
                CampaignsAPI.getDetails({ id: props.editId })
                    .then((response) => {
                        if (response.data.campaign) {
                            const campaign = response.data.campaign;
                            setValue('campaign_name', campaign.name);
                            setValue('start_date', campaign.start_date);
                            setValue('end_date', campaign.end_date);
                            setValue('total_budget', campaign.total_budget);
                            setValue('is_auto_added', campaign.is_auto_added);
                            setValue('remarks', campaign.description);
                            if (campaign.status === 1) {
                                setValue('status', { id: 1, name: "ACTIVE" });
                            } else {
                                setValue('status', { id: 0, name: "INACTIVE" });
                            }

                        }
                        let campaign_id = fetchCampaignTypes.data?.campaign_types

                        const foundCampaignType = campaign_id.find(campaignType => campaignType.id === response.data?.campaign?.campaign_type_id);
                        if (foundCampaignType) {
                            setValue('campaign_type', foundCampaignType)
                        } else {
                            console.log("Campaign type not found for ID:");
                        }

                        setLoading(false);
                    })
                    .catch((err) => {
                        console.error(err.message);

                    });
            } catch (error) {
                console.error("Error fetching data:", error.message);
                setLoading(false);
            }
        };

        fetchData();
    };

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchLeadDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        } else {
            return
        }

    }, [props.editId])


    useEffect(() => {
        getIP()
    }, []);



    return (
        <div>

            {!props.disableCtnBtn &&


                <Button class="create-btn2" variant="contained" onClick={handleClickOpen} >
                    {props.icon ? <AddIcon style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }} /> : "Create Campaign"}
                </Button>

            }
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {Loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box >
                ) : (
                    open && (
                        <div class="create-frm" >



                            <DialogTitle class="form-title">
                                <Button onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="rgba(0,0,0,1)"></path></svg></Button>
                                {parseInt(props.editId) > 0 ? "Edit Campaign" : "Create Campaign"}</DialogTitle>

                            <DialogContent class="right-form" sx={{ mt: 2 }}>

                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <ErrorMessage
                                        errors={errors}
                                        name="multipleErrorInput"
                                        render={({ messages }) =>
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message}</p>
                                            ))
                                        }
                                    />
                                    <Grid container spacing={2} sx={{ display: 'flex' }}>

                                        <Grid sx={{ p: 1 }} item xs={12} sm={12}>
                                            <TextInput control={control}
                                                name="campaign_name"
                                                label="Campaign Name"
                                                value={watch('campaign_name')}
                                                disabled={watch('is_auto_added') == 1 ? true : false}
                                            />
                                        </Grid>

                                    </Grid>


                                    <Grid container spacing={1} sx={{ display: 'flex', pt: 1 }}>
                                        <Grid item xs={12} sm={6}>
                                            <CurrentDateInput
                                                control={control}
                                                name="start_date"
                                                label="Start Date"
                                                value={watch('start_date')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <DateIn
                                                control={control}
                                                name="end_date"
                                                label="End Date"
                                                value={watch('end_date')}
                                            // readOnly={props.editId ? true : false}
                                            />
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2} mt={1}>
                                        <Grid item xs={6}>
                                            <SelectX
                                                options={fetchCampaignTypes}
                                                label="Campaign Type"
                                                name={'campaign_type'}
                                                defaultValue={watch('campaign_type')}
                                                control={control}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} >
                                            <InputLabel sx={{
                                                color: "black",
                                                fontWeight: 500,
                                                fontSize: "14px",

                                            }}>Status</InputLabel>
                                            <ReactSelect
                                                options={priority}
                                                getOptionLabel={priority => priority.name}
                                                getOptionValue={priority => priority.id}
                                                value={watch('status')}
                                                name='status'

                                                styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                                onChange={(selectedOption) => {
                                                    // setValue('projects_id', selectedOption.name)
                                                    setValue('status', selectedOption)
                                                }}
                                            />
                                        </Grid>
                                        <Grid sx={{ pt: 5 }} item xs={12} sm={12}>
                                            <TextInput control={control} name="total_budget"
                                                label="Total budget"
                                                value={watch('location')} />
                                        </Grid>
                                    </Grid>



                                    < Grid sx={{ mt: 2 }} item xs={12}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 1
                                        }}>Description </InputLabel>
                                        <TextField
                                            {...register('remarks')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            sx={{ width: '100%', }}

                                        />

                                    </Grid>
                                    <Grid sx={{ p: 1 }} item xs={12}>
                                        <LoadingButton class="main-btn2" loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                    </Grid>



                                </form>


                            </DialogContent>
                        </div >
                    )
                )
                }
            </Dialog >

        </div >
    );
};

export default Create
