import React, { Fragment, useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { ReportsAPI } from '../../../../api/Endpoints/ReportsAPI';
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Waveform } from '@uiball/loaders';
import Paper from '@mui/material/Paper';
import { GlobalRefreshContext } from '../../../../contexts/GlobalRefresh/GlobalRefresh';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import SelectX from '../../../../components/Form/SelectX';
import { fetchCampaginName, fetchLeadSources } from '../../../../components/CommonFunctions/DropdownLists';
function Index() {
    const { GlobalRefresh } = GlobalRefreshContext();
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const [reports, setReports] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const { watch, formState: { errors }, control, Controller, setValue, reset } = useForm({})
    useEffect(() => {

        fetchStatusReports();

    }, [
        GlobalRefresh,
        selectedToDate,
        selectedFromDate,
        watch('lead_source'),
        watch('campagin_name')
    ]);


    const fetchStatusReports = async () => {
        setLoading(true)
        try {

            const response = await ReportsAPI.getProfessionReport({
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
                from: selectedFromDate ? moment(selectedFromDate).format('YYYY-MM-DD') : null,
                to: selectedToDate ? moment(selectedToDate).format('YYYY-MM-DD') : null,
                source: watch('lead_source') && watch('lead_source').map((leadSource) => leadSource.name),
                campaign_name: watch('campagin_name') && watch('campagin_name').name
            });

            setReports(response?.data)
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const clearDatePicker = () => {
        setValue('campagin_name', null)
        setValue('lead_source', null)
        setSelectedFromDate(null)
        setSelectedToDate(null)
    }


    return (

        <div class="page-cntr">
            <div class="lead-cntr follo-up-cntr " >
                < div class="title-sec">
                    <PageHeader title={'Profession Report'} />
                    <LocalizationProvider dateAdapter={AdapterMoment} >
                        <Grid container direction="row"
                            mb={2}
                        >
                            <Grid item xs={3}  >
                                <div class="follw-dates">
                                    <DatePicker
                                        value={selectedFromDate}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="From" fullWidth />
                                        )}
                                        onChange={(newValue) => setSelectedFromDate(newValue)}
                                        inputFormat="DD/MM/YYYY"
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={3}  >
                                <DatePicker
                                    value={selectedToDate}

                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="To" fullWidth
                                        />
                                    )}
                                    onChange={(newValue) => setSelectedToDate(newValue)}
                                    inputFormat="DD/MM/YYYY"
                                />
                            </Grid>
                            <Grid xs={2} ml={1} mt={1}>
                                <SelectX
                                    ismultiple
                                    options={fetchLeadSources}
                                    name={'lead_source'}
                                    placeholder={
                                        "Lead Source"
                                    }
                                    defaultValue={watch('lead_source')}
                                    control={control}


                                />
                            </Grid>
                            <Grid xs={2} ml={1} mt={1}>
                                <SelectX

                                    options={fetchCampaginName}
                                    name={'campagin_name'}
                                    placeholder={
                                        "Campaign"
                                    }
                                    defaultValue={watch('campagin_name')}
                                    control={control}


                                />
                            </Grid>


                            <Grid item xs={1} >
                                <IconButton className=''
                                    sx={{ ml: 2, mt: 1, background: 'white' }}

                                    edge="end"

                                    onClick={clearDatePicker}

                                >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>

                        </Grid>
                    </LocalizationProvider>
                </div>
                {
                    loading ?
                        <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                        : (
                            <Fragment>

                                {
                                    reports
                                        ?.length > 0 ? (
                                        <Fragment>

                                            < TableContainer TableContainer component={Paper} className='reports-table'>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID</TableCell>
                                                            <TableCell>Name</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {reports?.map((row) => (
                                                            <TableRow
                                                                key={row.name} // Assuming name is unique, otherwise, use another unique identifier
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {row?.id
                                                                    }
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.name
                                                                    }
                                                                </TableCell>

                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Fragment>
                                    ) : (

                                        <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Status Report not found</Typography>
                                        </Box>
                                    )
                                }
                            </Fragment>
                        )}
            </div>
        </div>

    )
}

export default Index