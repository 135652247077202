import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Notes } from '../../../../api/Endpoints/Notes';
import { toast } from 'react-toastify';
import moment from 'moment';
import Delete from '../../../../components/Popup/Delete';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import CurrentDateInput from '../../../../components/Form/CurrenDateInput';
import { SiteVisitsAPI } from '../../../../api/Endpoints/SiteVisitsAPI';
import EditIcon from '@mui/icons-material/Edit';
import DateIn from '../../../../components/Form/Date';

export default function SiteVisitTab({ viewID }) {
    const schema = yup.object().shape({
        notes: yup.string().required('Field is required'),
    });


    const { register, handleSubmit, formState: { errors }, reset, control, setValue, watch } = useForm({
        resolver: yupResolver(schema),
    });

    const [listNotes, setListNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [DeleteID, setDeleteID] = useState(0)
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [refresh, setRefresh] = useState()
    const [editedNote, setEditedNote] = useState("");
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const fetchNotes = () => {
        setLoading(true);
        SiteVisitsAPI.get({ lead_id: viewID })
            .then((response) => {
                setListNotes(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const TableUpdate = () => {
        setRefresh(Math.random)
    }
    useEffect(() => {
        fetchNotes();
    }, [viewID, refresh]);

    const onSubmit = (data) => {
        const siteVisitDate = data.note_date != null ? moment(data.note_date).format("YYYY-MM-DD") : '';
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const dataToSubmit = {
            lead_id: viewID,
            site_visit_remarks: data.notes,
            site_visit_date: siteVisitDate
        };

        SiteVisitsAPI.add(dataToSubmit)
            .then((response) => {
                if (response.data.status === true) {
                    toast.success('Site Visit Added');
                    setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                    reset(); // Reset the form after successful submission
                    TableUpdate()
                }
            })
            .catch((err) => {
                console.error(err);
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            });
    };

    const handleSaveEdit = (id) => {

        const siteVisitDate = watch('edit_date') != null ? moment(watch('edit_date')).format("YYYY-MM-DD") : '';
        SiteVisitsAPI.update({ id: id, notes: editedNote, site_visit_date: siteVisitDate, })
            .then((response) => {
                if (response.data.status === true) {
                    fetchNotes();
                    setEditingNoteId(null);
                    setEditedNote("");
                    toast.success('Site Visit updated successfully');
                }
            })
            .catch((err) => {
                console.error(err);
                setEditingNoteId(null);
                setEditedNote("");
            });
    };
    const handleDelete = (id) => {
        setDeleteID(id)

    };

    const handleEdit = (id, note, date) => {

        setValue('edit_date', date)
        setEditingNoteId(id);
        setEditedNote(note);
    };

    return (
        <>

            <Delete
                DeleteID={DeleteID}
                setDeleteID={setDeleteID}
                Callfunc={fetchNotes}
                url={'/site-visit/delete?id='}
                title={'Note'}
            />

            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (

                <div className="pop-note" style={{ marginTop: 0 }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container alignItems="center" item xs={12}>
                            <Grid item xs={12} sm={6}>
                                <CurrentDateInput
                                    control={control}
                                    name="note_date"
                                // readOnly={props.editId ? true : false}
                                />
                            </Grid>

                            <TextField
                                sx={{ width: '70%', mt: 2 }}
                                {...register('notes')}
                                label="Site Visit Remark"
                                size="medium"
                                multiline
                                rows={4}

                                id="outlined-multiline-static"
                                error={Boolean(errors.notes)

                                }
                            />
                            <LoadingButton id="notes-add-btn" loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>
                    </form>
                    <div className='talk-bubble-block'>
                        {loading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '30vh',
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer className='note-result-block'>
                                <Table>
                                    <TableBody>
                                        {listNotes && listNotes.length > 0 && <Typography textTransform={'uppercase'}>Site Visits</Typography>}
                                        {listNotes && listNotes.map((item) => (
                                            <TableRow key={item.id}>

                                                <TableCell className='note-content'>

                                                    {editingNoteId === item.id ? (
                                                        <>
                                                            <TextField
                                                                value={editedNote}
                                                                onChange={(e) => setEditedNote(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                                variant="outlined"
                                                            />
                                                            <Grid item xs={12} sm={6}>
                                                                <DateIn
                                                                    control={control}
                                                                    name="edit_date"
                                                                    value={watch('edit_date')}
                                                                />
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        item.site_visit_remarks
                                                    )}

                                                </TableCell>
                                                <TableCell className='note-name'>
                                                    <TableCell>{moment(item.site_visit_date).format('DD MMM, YYYY')}</TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    {editingNoteId === item.id ?
                                                        <Button onClick={() => handleSaveEdit(item.id, item?.site_visit_date)} variant='outlined'>Save</Button> :
                                                        <IconButton onClick={() => handleEdit(item.id, item.site_visit_remarks, item?.site_visit_date)}>
                                                            <EditIcon className="edit-icon" style={{ color: 'blue' }} />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleDelete(item.id)}>
                                                        <DeleteIcon className="delete-icon" style={{ color: 'red' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer >
                        )}
                    </div >

                </div >
            )
            }
        </>
    );
}
