import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from 'react';
import BasicTabs from '../Leads/TabMenus';

const View = ({ viewID, setViewID, watch, control, setValue, fetchLeads, assignees, isAssigned, statusList, page, fromHome }) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (viewID > 0) {
            setOpen(true)
        }

    })


    const handleClose = () => {
        fetchLeads()
        setOpen(false)
        setViewID(0)
    }


    return (
        <div>

            {/* Modal Compoenent */}


            <Dialog
                open={open}
                PaperProps={{
                    sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' },
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent >


                    {/* Passing props to tabs Menu Compoent  */}

                    <BasicTabs viewID={viewID}
                        setViewID={setViewID}
                        watch={watch}
                        page={page}
                        setValue={setValue}
                        control={control}
                        fromHome={fromHome}
                        fetchLeads={fetchLeads}
                        isAssigned={isAssigned}
                        assignees={assignees}
                        statusList={statusList} />



                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default View; 
