import React, { Fragment, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    TextField,
    Slide, Typography, Box, CircularProgress
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from '../../../components/Form/TextInput';
import DateIn from '../../../components/Form/Date';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import SelectX from '../../../components/Form/SelectX';
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import CurrentDateInput from '../../../components/Form/CurrenDateInput';
import { Leads } from '../../../api/Endpoints/Leads'
import moment from 'moment';
import { fetchAssignees, fetchProjects, fetchLeads, fetchStatus, fetchLeadSources } from '../../../components/CommonFunctions/DropdownLists';
import LabelsList from '../../../components/CommonFunctions/LabelsList';
import ReactSelect from 'react-select';
import YearPicker from '../../../components/Form/YearPicker';
import MonthPicker from '../../../components/Form/MonthPicker';
import { CampaignsAPI } from '../../../api/Endpoints/CampaignsAPI';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});







const AddLisits = (props) => {


    const scheme = yup.object().shape({


    })


    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm({
        resolver: yupResolver(scheme), defaultValues: {
            ip_address: '',

        },
        mode: 'all'
    })

    const [open, setOpen] = React.useState(false);
    const [ipAddress, setIpAddress] = useState('');
    const [Loading, setLoading] = useState(false)
    const [statusLIst, setStatusList] = useState([])


    const { fetchLabels } = LabelsList();

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setListID(undefined)
            props.setModal && props.setModal(false)
        }, 200)

        reset()
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });






    const onSubmit = (data) => {

        // setFormButtonStatus({ ...formButtonStatus, loading: true });
        const Month = data.month != null ? moment(data.month).format("MMMM") : '';
        const Year = data.year != null ? moment(data.year).format("YYYY") : '';

        const dataToSubmit = {
            campaign_list_id: props.listId,
            source_url: "CRM",
            ip_address: ipAddress,
            month: Month,
            year: Year,
            spend_amount: data.spend_amount,
            reach: data.reach,
            views: data.views



        }

        let action;
        if (props.editId > 0) {
            action = CampaignsAPI.addCampaignOptions(dataToSubmit)
        } else {

            action = CampaignsAPI.add(dataToSubmit);
        }

        action.then(async (response) => {
            if (response.data.message === "Updated") {
                toast.success("Campaign Successfully Updated!", { autoClose: 1000 });
            } else {
                try {
                    toast.success("Campaign Successfully Saved!", { autoClose: 1000 });
                } catch (error) {
                    console.error("Error:", error);

                }
            }


            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
            handleClose()
            props.onUpdate();
        }).catch(errors => {
            toast.error("Internal Server Error, please try again later!", { autoClose: 1000 })
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
        })


    }


    async function getIP(retryCount = 0) {
        try {
            const response = await fetch('https://api.ipify.org/?format=json');
            const data = await response.json();
            setIpAddress(data.ip);
        } catch (err) {
            console.log(err);
            if (retryCount < 2) {
                retryCount++;
                getIP(retryCount);
            } else {
                console.log("Something went wrong. Failed to retrieve IP address.");
            }
        }
    }




    const fetchLeadDetails = (retryCount = 2) => {
        setLoading(true);

        const fetchData = () => {
            Leads.getDetails({ id: props.editId })
                .then((response) => {
                    if (response.data.lead) {
                        const lead = response.data.lead
                        let labels;
                        if (lead.label) {
                            labels = lead.label.map((item) => item);
                        }


                        const find = statusLIst.find((ismatchedstatus) => ismatchedstatus.slug == lead.crm_status)
                        setValue('email', lead?.email)
                        setValue('crm_lead_type_id', lead?.crm_lead_type)
                        setValue('name', lead?.name)
                        setValue('phone_number', lead?.phone_number)
                        setValue('project', lead?.project)
                        setValue('source_url', lead.source_url)
                        setValue('location', lead?.location)
                        setValue('remarks', lead?.remarks)
                        setValue('created_at', lead.created_at)
                        setValue('follow_up_date', lead.follow_up_date)
                        setValue('referred_by', lead.referred_by)
                        setValue('assign_to', lead.assigned_to_details)
                        setValue('crm_status', find)
                        if (lead.label) {
                            setValue("lables_id", labels)
                        }

                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err.message);

                    if (retryCount > 0) {
                        setTimeout(() => {
                            fetchData(retryCount - 1);
                        }, 1000);
                    } else {
                        setLoading(false);
                        console.log("Failed to fetch details");
                    }
                });
        };

        fetchData();
    };

    useEffect(() => {
        if (parseInt(props.listId) > 0) {
            fetchLeadDetails()
            setOpen(true);
        } else if (Number(props.listId) === 0) {
            setOpen(true);
        } else {
            return
        }

    }, [props.listId])


    useEffect(() => {
        getIP()
    }, []);



    return (
        <div>


            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {Loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box >
                ) : (
                    <div class="create-frm" >

                        <DialogTitle class="form-title">
                            <Button onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="rgba(0,0,0,1)"></path></svg></Button>
                            {parseInt(props.editId) > 0 ? "Edit Campaign" : "Add Campaign Options"}</DialogTitle>

                        < DialogContent class="right-form">

                            <form onSubmit={handleSubmit(onSubmit)}>

                                <ErrorMessage
                                    errors={errors}
                                    name="multipleErrorInput"
                                    render={({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message}</p>
                                        ))
                                    }
                                />



                                <Grid container spacing={1} sx={{ display: 'flex', pt: 1 }}>
                                    <Grid item xs={12} sm={6}>
                                        <MonthPicker
                                            control={control}
                                            name="month"
                                            label="Month"
                                            value={watch('month')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <YearPicker
                                            control={control}
                                            name="year"
                                            label="Year"
                                            value={watch('year')}
                                        // readOnly={props.editId ? true : false}
                                        />
                                    </Grid>

                                </Grid>

                                <Grid container spacing={2} mt={1}>
                                    <Grid sx={{ pt: 5 }} item xs={12} sm={6}>
                                        <TextInput control={control} name="spend_amount"
                                            label="Spend Amount"
                                            value={watch('spend_amount')} />
                                    </Grid>
                                    <Grid sx={{ pt: 5 }} item xs={12} sm={6}>
                                        <TextInput control={control} name="reach"
                                            label="Reach"
                                            value={watch('reach')} />
                                    </Grid>
                                </Grid>



                                <Grid container spacing={2} mt={1}>
                                    <Grid sx={{ pt: 2 }} item xs={12} sm={8}>
                                        <TextInput control={control} name="views"
                                            label="Views"
                                            value={watch('views')} />
                                    </Grid>

                                </Grid>
                                <Grid sx={{ p: 1, mt: 2 }} item xs={12}>
                                    <LoadingButton class="main-btn2" loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>



                            </form>


                        </DialogContent>
                    </div >
                )
                }
            </Dialog >

        </div >
    );
};

export default AddLisits
