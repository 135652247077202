import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useEffect, useMemo } from 'react';
import { Leads } from '../../api/Endpoints/Leads';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { RecycleAPI } from '../../api/Endpoints/RecycleBin';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function Restore({ RestoreID, setRestoreID, fetchRecycleBin }) {
    const [open, setOpen] = React.useState(false)
    const [formButtonStatus, setFormButtonStatus] = React.useState({
        label: 'Restore',
        loading: false,
        disabled: false,
    });

    const handleClose = () => {
        setRestoreID(0);
        setOpen(false);
    };

    useEffect(() => {
        if (RestoreID > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [RestoreID]);

    const RestoreHandler = async () => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

        try {
            const response = await RecycleAPI.restore({ id: RestoreID });

            if (response.data.status === true) {
                toast.success("Lead restored successfully", { autoClose: 2000 });
                setOpen(false);
                await fetchRecycleBin(); // Assuming fetchRecycleBin is an async function
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            } else {
                toast.error("An error occurred while restoring the customer.", { autoClose: 2000 });
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            }
        } catch (error) {
            toast.error("An error occurred. Please try again later.", { autoClose: 2000 });
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }
    };

    const memoizedOpen = useMemo(() => open, [open]);

    return (
        <div>
            <Dialog
                open={memoizedOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        transform: 'translateY(-75%)',
                    },
                }}
            >
                <DialogTitle>Confirm Restore</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to restore this Lead ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='inherit'>Cancel</Button>
                    <LoadingButton onClick={() => RestoreHandler()} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" color='primary' type="submit">{formButtonStatus.label}</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
