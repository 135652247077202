import { Leads } from '../../../api/Endpoints/Leads'
import { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import CustomToolbar from '../../../components/Tools/CustomeToolbar';
import { useForm } from 'react-hook-form';
import PageHeader from '../../../components/PageHeader/PageHeader';
import StatusSelector from '../../../components/Form/Status';
import { Waveform } from '@uiball/loaders';
import StatusLIstFunction from '../../../components/CommonFunctions/statusLIst';



export default function ClosedLeads() {


    const columns = [

        {
            field: 'closed_remarks', headerName: 'Customer Name', width: 250, renderCell: (params) => (
                <Typography class="custom-name archived"
                    style={{ whiteSpace: 'break-spaces' }}
                    size="small"
                >
                    {params.row.name}

                </Typography>
            )


        },



        {
            field: 'assigned_to_user',
            headerName: 'Assign To',
            width: 150,
            renderCell: (params) => (
                <>
                    <Button size='small' style={{ color: '#6f6f6f' }}>
                        {params.row?.assigned_to_user?.name ? params.row?.assigned_to_user?.name : "Not Assigned"}
                    </Button>
                </>
            ),



        },

        {
            field: 'created_at',
            headerName: 'Date',
            width: 110,
            renderCell: (params) => {
                const formattedDate = moment(params.value).format('DD-MM-YYYY');
                return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;
            },
        },
        {
            field: 'project_name',
            headerName: 'Project Name',
            width: 110,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.project?.name}</Typography>;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <StatusSelector
                        statusList={statusList}
                        options={params.row?.crm_status_details}
                        id={params.id}
                        isDisabled={true}
                    />
                )
            }
        },


    ]


    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    const [loading, setLoading] = useState(false);
    const [ClosedLeads, setClosedLeads] = useState([])
    const { statusList, fetchStatusList, } = StatusLIstFunction();

    const fetchClosedLeads = () => {
        setLoading(true);
        Leads.get({ page: parseInt(paginationModel.page) + 1, is_closed: 1 }).then(response => {
            setClosedLeads(response.data)
            setLoading(false);
        }).catch(error => {
            console.log(error.message);
            setLoading(false);
        })
    }



    useEffect(() => {
        fetchClosedLeads();
        fetchStatusList()
    }, [paginationModel]);


    return (

        <div class="page-cntr">



            <div class="lead-table archived-table  closed-page-cntr">

                {/* Popup's  */}


                {/* <Restore RestoreID={RestoreID} setRestoreID={setRestoreID} fetchRecycleBin={fetchClosedLeads} /> */}

                <div class="title-sec">
                    <PageHeader title={'Archived Leads'} total={ClosedLeads?.leads?.total} />
                    {/* {
                        ClosedLeads ?
                            <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >

                            </Card>
                            : null
                    } */}
                </div>

                {
                    loading ?
                        <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                        : (
                            <>

                                <Card sx={{
                                    m: 0,
                                }} variant="outlined" class="lead-table" >
                                    {typeof ClosedLeads?.leads?.data === "object" &&
                                        <DataGrid
                                            sx={{
                                                width: '100%',
                                            }}
                                            paginationMode="server"
                                            rows={ClosedLeads?.leads?.data}
                                            paginationModel={paginationModel}
                                            columns={columns}
                                            rowCount={ClosedLeads?.leads.total}
                                            rowHeight={70}
                                            autoHeight={true}
                                            scrollbarSize={10}
                                            density="comfortable"
                                            onPaginationModelChange={setPaginationModel}
                                            disableColumnMenu
                                            disableRowSelectionOnClick
                                            disableExtendRowFullWidth
                                        />
                                    }
                                </Card >
                            </>
                        )}
            </div >
        </div>

    )
};
