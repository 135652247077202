import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Backdrop, Button, Card, CircularProgress, Dialog, DialogContent, Slide, TextField } from "@mui/material";
import { useState } from 'react';
import DetailsView from './Tabs/Details';
import Options from './Tabs/Options';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(true);


    const handleClose = () => {
        setTimeout(() => {
            props.handleClose(false)
        }, 200);
        setOpen(false)

    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Add Options" {...a11yProps(1)} />


                </Tabs>

                <DialogContent>

                    <TabPanel value={value} index={0}>
                        <DetailsView viewID={props.id} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Options viewID={props.id} />
                    </TabPanel>




                </DialogContent>
            </Dialog>
        </>
    );
}
