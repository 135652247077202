import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, FormGroup, FormControlLabel, Radio, RadioGroup, Grid, Typography, Box } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Leads } from '../../api/Endpoints/Leads';
import { toast } from 'react-toastify';
import { fetchStatus } from '../CommonFunctions/DropdownLists';
const schema = yup.object().shape({
    option: yup.string().required('Status is requried'),
    remark: yup.string().required('Remark is required'),
});

const CloseLeadPopup = ({ id, setShowClosePopup, func }) => {
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSave = async (data) => {

        const dataToSubmit = {
            id: id,
            remarks: data.remark,
            crm_status: parseInt(data.option)
        };
        try {
            await Leads.closeLead(dataToSubmit);
            toast.success('Lead Archived successfully');
        } catch (err) {
            console.log("this is err", err);
        }

        // Close the popup

        setShowClosePopup(false);

        func();

    };

    const handleClose = () => {
        setShowClosePopup(false)
    };

    const [StatusLIst, setStatusList] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        const Tofind = ["Confirmed", "Invalid", "Lost", "Future"]
        fetchStatus().then(res => {
            setStatusList(res.filter(item => Tofind.includes(item.name)));
            setLoading(false)
        });
    }, [])

    return (
        <Dialog open={true} onClose={handleClose}>

            <DialogContent sx={{ maxWidth: '500px' }}>
                {!loading ? (
                    <Fragment>
                        <form onSubmit={handleSubmit(handleSave)}>
                            <Controller
                                control={control}
                                name="option"
                                render={({ field }) => (
                                    <FormControl component="fieldset" error={!!errors.option} sx={{ mt: 2, ml: 5 }}>
                                        <RadioGroup {...field} >
                                            <Grid container spacing={2}>
                                                {StatusLIst.map((option) => (
                                                    <Grid item xs={6} key={option.id}>
                                                        <FormControlLabel
                                                            value={option.id}
                                                            control={<Radio />}
                                                            label={option.name}

                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </RadioGroup>
                                        {errors.option && <Typography sx={{ color: 'red', fontSize: '14px', mt: 1 }}>{errors.option.message}</Typography>}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="remark"
                                render={({ field }) => (
                                    <TextField
                                        sx={{ mt: 5 }}
                                        {...field}
                                        label="Remark"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        helperText={errors.remark?.message}
                                        margin="normal"
                                        error={!!errors.remark}
                                    />
                                )}

                            />

                            <DialogActions>
                                <Button onClick={handleClose} variant='outlined' color="error">
                                    Cancel
                                </Button>
                                <Button type="submit" variant='outlined' color="primary">
                                    Submit
                                </Button>
                            </DialogActions>

                        </form>
                    </Fragment>
                ) : (
                    <Box sx={{ height: 300, width: 300, display: 'flex', justifyContent: 'center' }}>
                        <Typography textAlign={'center'} alignSelf={'center'}>Loading....</Typography>
                    </Box>

                )
                }
            </DialogContent>

        </Dialog >
    );
};

export default CloseLeadPopup;
