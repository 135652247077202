import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { CampaignsAPI } from '../../../../api/Endpoints/CampaignsAPI';
import { Button, Card, IconButton, Typography } from '@mui/material';
import AddCampaignOptions from '../../../../components/Popup/AddCampaignOptions';
import EditIcon from '@mui/icons-material/Edit';
import { Waveform } from '@uiball/loaders';




export default function Options({ viewID }) {
    const columns = [



        {
            field: 'month',
            headerName: 'Month',
            width: 100,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer'
                    }}

                >

                    {params.row.month}
                </span>

            ),
        },
        {
            field: 'year',
            headerName: 'Year',
            width: 100,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer'
                    }}

                >

                    {params.row.year}
                </span>

            ),
        },
        {
            field: 'reach',
            headerName: 'Reach',
            width: 100,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer'
                    }}

                >

                    {params.row.reach}
                </span>

            ),
        },
        {
            field: 'view',
            headerName: 'Views',
            width: 100,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer'
                    }}

                >

                    {params.row.views}
                </span>

            ),
        },
        {
            field: 'impressions',
            headerName: 'Impressions',
            width: 100,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer'
                    }}

                >

                    {params.row.impression}
                </span>

            ),
        },
        {
            field: 'spend_amount',
            headerName: 'Spend Amount',
            width: 100,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer'
                    }}

                >

                    {params.row.spend_amount}
                </span>

            ),
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 30,
            renderCell: (params) => (
                <IconButton onClick={() => handleEditBtnClick(params.id)} >
                    < EditIcon sx={{ color: 'blue', fontSize: 20 }} />
                </IconButton >



            )

        },

    ];
    const [campaingOptions, setCampaignOptions] = React.useState([])
    const [loading, setLoading] = React.useState()
    const [showAddOptionsPopup, setshowAddOptionsPopup] = React.useState(false)
    const [editId, setEditId] = React.useState(0)
    const [campaingId, setCampaignId] = React.useState(viewID)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 15,
        page: 0,
    })
    React.useEffect(() => {
        fetchOptions()
    }, [])

    const fetchOptions = async () => {
        try {
            setLoading(true);
            const response = await CampaignsAPI.getCampaignOptions({
                page: parseInt(paginationModel?.page) + 1,
                campaign_list_id: viewID
            });

            setCampaignOptions(response.data
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const fetchOptionsForTableUpdate = async () => {
        try {

            const response = await CampaignsAPI.getCampaignOptions({
                page: parseInt(paginationModel?.page) + 1,
                campaign_list_id: viewID
            });

            setCampaignOptions(response.data
            );

        } catch (error) {
            console.log(error);

        }
    };


    const handleShowBtnClick = () => {
        setshowAddOptionsPopup(true)
    }

    const handleEditBtnClick = (id) => {
        setEditId(id)
        setshowAddOptionsPopup(true)
    }


    return (
        <div >
            {
                loading ?
                    <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                        <Waveform
                            size={40}
                            lineWeight={3.5}
                            speed={1}
                            color="blue"
                        />
                    </Box>
                    : (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}  >
                                <Button sx={{
                                    background: '#FAAB53',
                                    color: 'white !important',
                                    '&:hover': {
                                        backgroundColor: '#FAAB53',
                                    },

                                }}
                                    onClick={handleShowBtnClick}>Add Option</Button>

                            </Box>

                            {showAddOptionsPopup &&
                                <AddCampaignOptions open={showAddOptionsPopup} id={campaingId} setOpen={setshowAddOptionsPopup} editId={editId} setEditId={setEditId} setCampaignId={setCampaignId} onUpdate={fetchOptionsForTableUpdate} />
                            }
                            <Box className="campaign-box" sx={{ height: 400, width: '100%' }}>
                                {
                                    campaingOptions?.options?.length > 0 ? (
                                        <React.Fragment>
                                            < Card sx={{
                                                m: 0,
                                            }} variant="outlined" >

                                                {
                                                    typeof campaingOptions?.options === "object" &&
                                                    <DataGrid

                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                        paginationMode="server"
                                                        rows={campaingOptions?.options}
                                                        paginationModel={paginationModel}
                                                        columns={columns}
                                                        rowCount={campaingOptions?.total}
                                                        rowHeight={70}
                                                        autoHeight={true}
                                                        scrollbarSize={10}
                                                        density="comfortable"
                                                        onPaginationModelChange={setPaginationModel}
                                                        disableColumnMenu
                                                        disableRowSelectionOnClick
                                                        disableExtendRowFullWidth
                                                    />
                                                }

                                            </ Card>
                                        </React.Fragment>
                                    ) : (

                                        <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Options not found</Typography>
                                        </Box>
                                    )
                                }
                            </Box>
                        </>
                    )}
        </div>

    );
}