import axios from "axios";
import AbortController from 'abort-controller'; // Import AbortController
import toast from "react-hot-toast";
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
});


const token = localStorage.getItem('accessToken');

if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

apiClient.interceptors.request.use((config) => {

    config.params = {
        api_token: localStorage.getItem('accessToken'),
        ...config.params,
    };

    return ({
        ...config,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            "Access-Control-Allow-Origin": process.env.REACT_APP_API_PATH,
        },
    });
}, error => Promise.reject(error));

apiClient.interceptors.response.use(
    async (response) => response,
    async (error) => {
        if (error?.response?.status === 500) {
            return;
        }

        // Handle specific error responses or add custom logic here

        return Promise.reject(error.response);
    }
);

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy, AbortController };

