import React, { Fragment, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Card, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import CampignConnect from '../../../components/TableElements/CampignConnect';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Create from './create';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AddLisits from './AddLists';
import Badge from '@mui/material/Badge';
import { CampaignsAPI } from '../../../api/Endpoints/CampaignsAPI';

import { Waveform } from '@uiball/loaders';
import moment from 'moment';
import BookmarkOutlinedIcon from '@mui/icons-material/AutoModeOutlined';
import DetailsView from './Tabs/Details';
import BasicTabs from './TabMenus';
import { GlobalRefreshContext } from '../../../contexts/GlobalRefresh/GlobalRefresh';

const Index = () => {

    const [refresh, setRefresh] = useState(0)
    const [editId, setEditId] = useState(undefined);
    const [listID, setListID] = useState(undefined)
    const [campaings, setCampaigns] = useState([])
    const [loading, setLoading] = useState(false)
    const [ViewID, setViewID] = useState(0)
    const [campainglistID, setCampaingListID] = useState(undefined)
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const [detailViewId, setDetailViewId] = useState(false)
    const { GlobalRefresh } = GlobalRefreshContext();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    useEffect(() => {

        fetchCampaigns();


    }, [,
        GlobalRefresh,
        paginationModel,
        refresh
    ]);


    const fetchCampaigns = async () => {
        try {
            setLoading(true);
            const response = await CampaignsAPI.get({
                page: parseInt(paginationModel?.page) + 1,
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
            });

            setCampaigns(response.data.campaigns
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const viewTabs = (params) => {
        setDetailViewId(params.id)
    }


    const columns = [


        {
            field: 'campign',
            headerName: 'Campaigns',
            width: 400,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer',

                    }}
                    onClick={() => viewTabs(params)}
                >

                    {params.row.name}
                </span>

            ),
        },
        {
            field: 'create_at', headerName: 'Created Date', width: 200,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer'
                    }}

                >
                    {moment(params.row.created_at).format('DD-MM-YYYY')}
                </span>

            ),
        },

        {
            field: 'auto',
            headerName: 'Type',
            width: 90,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.is_auto_added == 1 && (
                            <Tooltip title='Auto Added' arrow
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                <BookmarkOutlinedIcon />
                            </Tooltip >
                        )
                        }
                    </>

                );
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            renderCell: (params) => {
                return (
                    <span
                        style={{
                            backgroundColor: params.row.status === 1 ? '#95ffd2' : 'rgb(206 255 75)',
                            padding: '3px',
                            borderRadius: '15px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: params.row.status === 1 ? 'green' : '#ff0025',
                        }}
                    >
                        {params.row.status === 1 ? 'ACTIVE' : 'INACTIVE'}
                    </span>
                );
            }
        },
        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown params={params} />,
        },
    ];


    const handleCreate = () => {
        setEditId(0)
    }
    const handleUpdate = () => {
        setRefresh(Math.random)
    }

    const handleListcreate = () => {
        setListID(0)
    }
    const handleListUpdate = () => {
        setRefresh(Math.random)
    }
    const MoreOptionsDropdown = ({ params }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = (id) => {
            setEditId(parseInt(id));

        }

        const addLists = (id) => {
            setListID(parseInt(id))
        }

        return (
            <>
                <IconButton size="small" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>

                    <MenuItem class="edit-nav-cntr" onClick={() => handleEdit(params.id)}>
                        <EditIcon sx={{ fontSize: 5, mr: 1, color: 'blue' }} />
                        Edit
                    </MenuItem>

                </Menu >
            </>
        );
    };

    return (
        <div className='page-cntr'>

            <div class="lead-table  lead-page-cntr">
                <div className="title-sec">
                    <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >
                        <PageHeader title={'Campaigns'} />

                        <Create
                            onNew={handleCreate}
                            onUpdate={handleUpdate}
                            editId={editId}
                            setEditId={setEditId} />
                    </Card>

                </div>
            </div>
            <AddLisits
                onNew={handleListcreate}
                onUpdate={handleListUpdate}
                listId={listID}
                setListID={setListID} />



            {
                detailViewId &&
                <BasicTabs id={detailViewId} handleClose={setDetailViewId} setDetailViewId={setDetailViewId} />
            }

            {
                loading ?
                    <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                        <Waveform
                            size={40}
                            lineWeight={3.5}
                            speed={1}
                            color="blue"
                        />
                    </Box>
                    : (
                        <>
                            {
                                campaings.data?.length > 0 ? (
                                    <Fragment>
                                        < Card sx={{
                                            m: 0,
                                        }} variant="outlined" class="lead-table" >

                                            {
                                                typeof campaings.data === "object" &&
                                                <DataGrid

                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                    paginationMode="server"
                                                    rows={campaings?.data}
                                                    paginationModel={paginationModel}
                                                    columns={columns}
                                                    rowCount={campaings?.total}
                                                    rowHeight={70}
                                                    autoHeight={true}
                                                    scrollbarSize={10}
                                                    density="comfortable"
                                                    onPaginationModelChange={setPaginationModel}
                                                    disableColumnMenu
                                                    disableRowSelectionOnClick
                                                    disableExtendRowFullWidth
                                                />
                                            }

                                        </ Card>
                                    </Fragment>
                                ) : (

                                    <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                                        <Typography>Leads not found</Typography>
                                    </Box>
                                )
                            }
                        </>
                    )}
        </div >
    );
};

export default Index;
