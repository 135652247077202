import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useEffect } from 'react';
import BasicTabs from './TabMenu';

const View = ({ from, to, viewID, setViewID, watch, control, setFormValue, assignees, func, isDisable, statusList, isSiteVisitComponent }) => {
    const [open, setOpen] = useState(false);


    useEffect(() => {
        if (viewID > 0) {
            setOpen(true)
        }

    })

    const handleClose = () => {
        func()
        setOpen(false)
        setViewID(0)
    }


    return (
        <div>
            {/* Dialog Box Component */}

            <Dialog
                open={open}
                PaperProps={{
                    sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' },
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent >

                    {/* Passing props to tabs Menu Compoent  */}
                    <BasicTabs
                        from={from}
                        to={to}
                        viewID={viewID}
                        setViewID={setViewID}
                        watch={watch}
                        setFormValue={setFormValue}
                        control={control}
                        func={func}
                        statusList={statusList}
                        assignees={assignees}
                        isDisable={isDisable} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default View;
