import { get, post } from './../Config/config';

export const ReportsAPI = {
    get: (data) => get(`/reports`, { params: data }),
    getStatusReport: (data) => get(`/campaigns-status-report`, { params: data }),
    getCostReport: (data) => get(`/campaigns-cost-report`, { params: data }),
    getCostReportDetails: (data) => get(`/campaigns-cost-detail-report?id=`, { params: data }),
    getCountryReport: (data) => get(`/country-lead-stats?`, { params: data }),
    getAgeReport: (data) => get(`/age-leads-stats?`, { params: data }),
    getProfessionReport: (data) => get(`/professions?`, { params: data }),
    getRefferedReport: (data) => get(`/referred-by-report`, { params: data }),
    getSiteVisitReport: (data) => get(`/site-visit-reports`, { params: data }),
}
