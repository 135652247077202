import React, { Fragment, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List, ListItem, ListItemText, IconButton, Alert, Box, Typography, Tooltip } from '@mui/material';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectX from '../../../../components/Form/SelectX';
import { useForm } from 'react-hook-form';
import { Projects } from '../../../../api/Endpoints/project';
import TextInput from '../../../../components/Form/TextInput';
import { Lables } from '../../../../api/Endpoints/Label';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Leads } from '../../../../api/Endpoints/Leads';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchLeadSources } from '../../../../components/CommonFunctions/DropdownLists';
function Index({ handleUpdate }) {

    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isValidated, setIsValidated] = useState(false)
    const [ValidationButton, setValidationButton] = useState({
        label: "Validate File",
        successIcon: null,
        ErrorIcon: null,
        loading: false,
        disabled: false,
    });
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Import",
        loading: false,
        disabled: false,
    });


    const scheme = yup.object().shape({
        projects: yup.object().required("Please choose a Project").typeError("Please choose a Project"),
        crm_lead: yup.object().required("Please choose a Lead Type").typeError("Please choose a Lead Type"),
        assign_to_user: yup.object().required("Please choose a User").typeError("Please choose a User"),
        select_file: yup
            .mixed()
            .test('fileSelected', 'file is requried', () => {
                return selectedFile !== null;
            }),
    })
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
        setIsValidated(false)
        setValue('labels', null)
        setTimeout(() => {
            setSelectedFile(null)
            setValidationButton({ ...ValidationButton, loading: false, disabled: false, ErrorIcon: null, successIcon: null })
            reset()
        }, 100)


    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setValidationButton({ ...ValidationButton, loading: false, disabled: false, ErrorIcon: null, successIcon: null })
        // clearing the value form input field
        // document.getElementById('file-upload').value = '';
    };



    const fetchAssignees = async (e) => {
        const response = await Leads.getAssignees({ keyword: e });
        if (typeof response.data.status) {
            return response.data?.users;
        } else {
            return [];
        }
    }


    const fetchProjects = async (e) => {
        const response = await Projects.get({ keyword: e });
        if (typeof response.data.projects) {
            return response.data?.projects;
        } else {
            return [];
        }
    }


    const fetchLeads = async (e) => {
        const response = await Leads.getDropdownList({ keyword: e });
        if (typeof response.data.status) {
            return response.data?.crm_lead_types
        } else {
            return [];
        }
    }
    const fetchLabels = async (e) => {
        const response = await Lables.get({ keyword: e });
        if (typeof response.data.data) {
            return response.data?.data
        } else {
            return [];
        }
    }


    //Validation Logic

    const handleValidateFile = () => {
        // ensure that users can only select one specific file type, you need to use the XOR (^) operator to check that the file name ends with either ".csv" or ".xlsx," but not both

        if (!(selectedFile.name.endsWith('.csv') ^ selectedFile.name.endsWith('.xlsx'))) {
            toast.error('Please select either a .csv or .xlsx file only');
            return;
        }

        if (ValidationButton.ErrorIcon !== null || ValidationButton.successIcon != null) {
            return;
        }




        setValidationButton({ ...ValidationButton, loading: true });


        // Logic of Read XLSX file 

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            if (selectedFile.name.endsWith('.csv')) {
                fileReader.readAsText(selectedFile)
            } else {
                fileReader.readAsArrayBuffer(selectedFile)
            }
            fileReader.onload = (e) => {
                const fileContent = e.target.result;

                // Logic for CSV file
                if (selectedFile.name.endsWith('.csv')) {
                    const rows = fileContent.split('\n');
                    const headerRow = rows[0].trim();
                    const TottalRow = rows.length
                    const headers = headerRow.split('\t' || ',');
                    resolve({ headers, TottalRow })
                    return;
                }
                //Reading the EXCEL sheet and return the data
                const wb = XLSX.read(fileContent, { type: "buffer" });
                //Picking the Sheet Name result: sheet Name:['Worksheet']
                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];
                //Converting the Leads data in to jsone format
                const data = XLSX.utils.sheet_to_json(ws);

                // Returing the data as a resolve
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
                setValidationButton({ ...ValidationButton, loading: false });
            };

        });
        promise.then((result) => {

            const expectedHeaders = ["name", "phone_number", "email"];
            let missingHeaders;
            let RowCount;
            if (selectedFile.name.endsWith('.csv') && result.headers.length > 0) {
                missingHeaders = expectedHeaders.filter((header) => !result.headers.includes(header));
                RowCount = result.TottalRow;
            } else {
                const firstRow = result[0];
                missingHeaders = expectedHeaders.filter(header => !Object.keys(firstRow).includes(header));
                RowCount = result?.length;
                if (!missingHeaders.length > 0) {
                }
            }



            if (missingHeaders.length > 0) {
                const headerText = missingHeaders.length > 1 ? "headers" : "header";
                setTimeout(() => {
                    setValidationButton({
                        label: '', loading: false, ErrorIcon: < HighlightOffIcon />,
                    },
                        setIsValidated(true)
                    );
                    toast.error(
                        <div>
                            The following {headerText} are missing: <span style={{ fontWeight: 'bold' }}> <br />{missingHeaders.join(', ')} </span>

                        </div>);
                }, 2000)

            } else {

                setTimeout(() => {
                    setValidationButton({
                        label: '', loading: false, successIcon: < CheckCircleOutlineIcon />,
                    },
                        setIsValidated(true)

                    );
                    toast.success(
                        <div>
                            Total : <span style={{ fontWeight: 'bold' }}>{RowCount}</span>  Rows are fetched!
                        </div>
                        , { autoClose: 2000 }
                    );

                }, 2000)

            }


        });



    }




    const onSubmit = async (data) => {

        if (!isValidated) {
            toast.error("Please validate the file before importing.", { autoClose: 2000 });
            return;
        }
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('crm_lead_type_id', data.crm_lead?.id)
        formData.append('project_id', data?.projects?.id)
        formData.append('assigned_to', data.assign_to_user?.id)
        formData.append('lead_source', data?.lead_source?.id)
        let labelsIdsArray;
        if (data.label && data.label.length > 0) {
            labelsIdsArray = data.label.map((item) => item.id);
            labelsIdsArray.forEach((labelId) => {
                formData.append('labels_id[]', labelId);
            });
        }

        let action = await Leads.importLeads(formData)

        if (action?.data?.status) {
            let importedLeadsCount = action.data.imported[0].length
            handleUpdate()
            handleClose()
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            setTimeout(() => {
                toast.success(`${importedLeadsCount} Leads imported`, { autoClose: 2000 });
            }, 2000);

        } else {
            toast.error("Internal Server Error.");
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }



    }


    return (
        <div>
            <Tooltip title="import" arrow>
                <Button id='import-lead' onClick={handleOpen}>
                    <AssignmentReturnedIcon />
                </Button>
            </Tooltip>


            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                PaperProps={{
                    style: {
                        minHeight: '500px',
                        minWidth: '100px',
                    },
                }}
            > <form onSubmit={handleSubmit(onSubmit)}>

                    <DialogTitle textAlign={'center'}>Import Lead</DialogTitle>

                    <DialogContent>


                        {/* file upload started */}
                        {selectedFile == null
                            &&
                            <Grid item container alignItems="center" xs={12} mt={1}>
                                <input
                                    type="file"
                                    accept=".csv, .xlsx"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                    id="file-upload"
                                />
                                <label htmlFor="file-upload" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Button variant="contained" component="span"
                                        sx={{
                                            backgroundColor: '#FAAB53',
                                            '&:hover': {
                                                backgroundColor: '#FAAB53',
                                            },
                                        }}>
                                        Select File
                                    </Button>
                                    <p style={{ marginLeft: '10px', color: 'black', fontSize: '12px' }}>Accepted file types: .CSV, .XLSX</p>
                                </label>
                                {errors.select_file && (
                                    <p style={{ color: 'red', fontSize: '14px', marginTop: 10 }} >
                                        {errors.select_file.message}
                                    </p>
                                )}
                            </Grid>


                        }
                        {/* selected file */}

                        <Grid item xs={6} sm={6} className='file-list-box'  >
                            {selectedFile && (
                                <List sx={{ maxHeight: 200, overflowY: 'auto', width: 500 }} className='file'>
                                    <ListItem sx={{
                                        backgroundColor: ' rgb(245, 248, 255)',
                                        borderRadius: '17px'
                                    }}>
                                        <ListItemText primary={selectedFile.name} sx={{ padding: 1 }} />
                                        <IconButton
                                            sx={{ mr: 2 }}
                                            startIcon={<CloudUploadIcon />}
                                            edge="end"
                                            onClick={() => handleRemoveFile()}
                                        >
                                            <DeleteIcon className='delete-icon' color='red' />
                                        </IconButton>
                                        <LoadingButton
                                            sx={{
                                                backgroundColor: `${ValidationButton.successIcon
                                                    ? '#4CAF50' // Green background for success
                                                    : ValidationButton.ErrorIcon
                                                        ? '#FF5733' // Red background for error
                                                        : '#FAAB53' // Default background
                                                    } `,
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: `${ValidationButton.successIcon
                                                        ? '#4CAF50'
                                                        : ValidationButton.ErrorIcon
                                                            ? '#FF5733'
                                                            : '#FAAB53'
                                                        } `,
                                                },
                                            }}
                                            onClick={handleValidateFile}
                                            variant="contained"
                                            disabled={ValidationButton.disabled}
                                            loading={ValidationButton.loading}


                                        >
                                            {ValidationButton.successIcon ? (
                                                <>
                                                    <CheckCircleOutlineIcon />
                                                </>
                                            ) : ValidationButton.ErrorIcon ? (
                                                <>
                                                    <HighlightOffIcon />
                                                </>
                                            ) : (
                                                'Validate File'
                                            )}
                                        </LoadingButton>

                                    </ListItem >
                                </List >
                            )
                            }
                        </Grid >


                        {/* End */}


                        {/* Other fields */}

                        <Grid container item xs={12} spacing={2} mt={0}>
                            <Grid item sm={6}>
                                <SelectX
                                    // ismultiple
                                    options={fetchLeads}
                                    label="Lead Type"
                                    name={'crm_lead'}
                                    defaultValue={watch('crm_lead')}
                                    control={control}
                                    error2={errors?.crm_lead?.message}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <SelectX
                                    options={fetchProjects}
                                    label="Assign to Project"
                                    name={'projects'}
                                    defaultValue={watch('projects')}
                                    control={control}
                                    error2={errors?.projects?.message}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <SelectX
                                    options={fetchAssignees}
                                    label="Assign to User"
                                    name={'assign_to_user'}
                                    defaultValue={watch('assign_to_user')}
                                    control={control}
                                    error2={errors?.assign_to_user?.message}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <SelectX
                                    options={fetchLeadSources}
                                    label="Lead Source"
                                    name={'lead_source'}
                                    defaultValue={watch('lead_source')}
                                    control={control}
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <SelectX
                                    ismultiple
                                    options={fetchLabels}
                                    label="Labels"
                                    name={'label'}
                                    defaultValue={watch('label')}
                                    control={control}
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <TextInput
                                    name="notify_email"
                                    label="Notify to Users"
                                    control={control}
                                    value={watch('notify_email')}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent >

                    <DialogActions>
                        <Button
                            color='error'
                            variant='outlined'
                            onClick={handleClose}>

                            Cancel
                        </Button>

                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                    </DialogActions>
                </form >
            </Dialog >

        </div >
    );
}

export default Index;
