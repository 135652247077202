import React, { createContext, useState, useContext, useEffect } from 'react';

const StateContext = createContext();

export const GlobalRefreshContext = () => useContext(StateContext);

export const GlobalRefreshProvider = ({ children }) => {

    const [GlobalRefresh, setGlobalRefresh] = useState(0);


    return (
        <StateContext.Provider value={{ GlobalRefresh, setGlobalRefresh }}>
            {children}
        </StateContext.Provider>
    );
};
// import React, { createContext, useState, useContext, useEffect } from 'react';


// const StateContext = createContext();


// export const useStateContext = () => useContext(StateContext);


// export const ProjectStateProvider = ({ children }) => {


//     const [selectedProject, setselectedProject] = useState(
//         {
//             ID: localStorage.getItem('P$wID') || null,
//             projectName: localStorage.getItem('P$wNme') || null,
//         }
//     );


//     useEffect(() => {
//         localStorage.setItem('P$wID', selectedProject.ID);
//         localStorage.setItem('P$wNme', selectedProject.projectName);
//     }, [selectedProject]);



//     return (
//         <StateContext.Provider value={{ selectedProject, setselectedProject }}>
//             {children}
//         </StateContext.Provider>
//     );
// };