import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Leads } from '../../api/Endpoints/Leads';
import SelectX from '../Form/SelectX';
import { fetchCountries, fetchLeads, fetchProjects, fetchStatus, fetchProfessionals, fetchAgeList } from '../CommonFunctions/DropdownLists';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Grid } from '@mui/material';
import TextInput from '../Form/TextInput';
import DateIn from '../Form/Date';
import { DynamicdropdownsAPI } from '../../api/Endpoints/DynamicDropdown';
import moment from 'moment';
import { toast } from 'react-toastify';
import { LoadingButton } from "@mui/lab";
const dialogStyle = {
    '& .MuiDialog-paper': {
        width: '700px', // Set fixed width here
        maxWidth: '700px', // Ensure maxWidth is the same to keep it fixed
    }
};

export default function QuickEdit({ open, setOpen, id }) {

    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false)
    const [statusLIst, setStatusList] = React.useState([])

    const scheme = yup.object().shape({

        crm_status: yup.object().required("status is required").typeError("status is required"),

    })
    const [formButtonStatus, setFormButtonStatus] = React.useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });



    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm({
        resolver: yupResolver(scheme), defaultValues: {
            ip_address: '',

        },
        mode: 'all'
    })


    React.useEffect(() => {
        if (open) {
            fetchQuickEditDetails()

        }

    }, [open])

    const fetchQuickEditDetails = async (retryCount = 2) => {
        setLoading(true);



        const fetchData = async (retries) => {
            try {

                const statusLIst = await DynamicdropdownsAPI.get();
                const response = await Leads.getQuickEditDetails({ id: id });

                if (response.data.data) {
                    const lead = response.data.data;
                    const crmStatus = statusLIst.data.crm_status;

                    const matchedStatus = crmStatus.find(status => status.id === Number(lead.crm_status));
                    setValue('email', lead?.email)
                    setValue('phone', lead?.phone_number)
                    setValue('alt_phone_number', lead?.alt_phone_number);
                    setValue('preferred_project', lead?.preferred_project);
                    setValue('source_url', lead.source_url);
                    setValue('city', lead?.city);
                    setValue('remarks', lead?.remarks);
                    setValue('created_at', lead.created_at);
                    setValue('follow_up_date', lead.follow_up_date);
                    setValue('referred_by', lead.referred_by);
                    setValue('assign_to', lead.assigned_to_details);
                    setValue('profession', lead.profession);
                    setValue('crm_status', matchedStatus);
                    if (lead?.age_range != null) {
                        setValue('age', {
                            id: 0,
                            name: lead?.age_range
                        });
                    }
                    setValue('country', lead?.country);
                }

                setLoading(false);
            } catch (err) {
                console.log(err.message);

                if (retries > 0) {
                    setTimeout(() => {
                        fetchData(retries - 1);
                    }, 1000);
                } else {
                    setLoading(false);
                    console.log("Failed to fetch details");
                }
            }
        };

        fetchData(retryCount);
    };


    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        const followdate = data.follow_up_date != null ? moment(data.follow_up_date).format("YYYY-MM-DD") : '';

        if (data.status === "New Enquiry") {
            data.status = "Open"
        }

        const dataToSubmit = {
            id: id,
            alt_phone_number: data.alt_phone_number,
            city: data.city,
            preferred_project_id: data.preferred_project_id?.id,
            follow_up_date: followdate,
            crm_status: data.crm_status?.id,
            age_range: data.age?.name,
            country_id: data.country?.id,
            age_range: data.age?.name || null,
        }

        let action;
        if (id > 0) {
            action = Leads.postQuickEdit(dataToSubmit)
        }


        action.then(async (response) => {
            if (response.data.message === "Updated") {
                toast.success("Lead Successfully Saved!", { autoClose: 1000 });
            } else {
                try {
                    toast.success("Lead Successfully Saved!", { autoClose: 1000 });
                } catch (error) {
                    console.error("Error:", error);

                }
            }




            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });


        }).catch(errors => {
            console.log("error", errors);
            toast.error("Internal Server Error, please try again later!", { autoClose: 1000 })
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
        })


    }


    return (
        <div>

            {open && (


                <>
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '30vh',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <DialogContent>
                                <Grid container spacing={2} >
                                    <Grid item sm={6}>
                                        <TextInput control={control}
                                            name="email"
                                            label="Email"
                                            disabled={true}
                                            value={watch('email')} />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <TextInput control={control}
                                            name="phone"
                                            label="Phone Number"
                                            disabled={true}
                                            value={watch('phone')} />
                                    </Grid>

                                </Grid>
                                <Grid container spacing={2} mt={0.3}>
                                    <Grid item sm={6}>
                                        <TextInput control={control}
                                            name="alt_phone_number"
                                            label="Alternate Phone Number
                                "
                                            value={watch('alt_phone_number')} />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <SelectX
                                            options={fetchProjects}
                                            label="Preferred Project"
                                            name={'preferred_project_id'}
                                            defaultValue={watch('preferred_project_id')}
                                            control={control}

                                        />

                                    </Grid>

                                </Grid>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={12} sm={6}>
                                        <SelectX
                                            options={fetchCountries}
                                            label="Country"
                                            name={'country'}
                                            defaultValue={watch('country')}
                                            control={control}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} >
                                        <TextInput control={control} name="City"
                                            label="City"
                                            value={watch('City')} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={12} sm={6}>
                                        <SelectX
                                            options={fetchStatus}
                                            label="Status"
                                            name={'crm_status'}
                                            defaultValue={watch('crm_status')}
                                            control={control}
                                            error2={errors?.crm_status?.message}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} >
                                        <SelectX
                                            options={fetchProfessionals}
                                            label="Profession
                                        "
                                            name={'professions'}

                                            defaultValue={watch('professions')}

                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={12} sm={6}>
                                        <SelectX
                                            options={fetchAgeList}
                                            label="Age"
                                            name={'age'}
                                            defaultValue={watch('age')}
                                            control={control}
                                            error2={errors?.crm_lead_type_id?.message}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} >
                                        <DateIn
                                            control={control}
                                            name="follow_up_date"
                                            label="Next Follow Up Date"
                                            value={watch('follow_up_date')}
                                        // readOnly={props.editId ? true : false}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="error" variant='outlined'>
                                    Close
                                </Button>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            </DialogActions>
                        </form>
                    )}
                </>

            )
            }
        </div >
    );
}
