import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import BasicTabs from './TabMenus';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';


const View = ({ viewID, setViewID, assignees, func, setRefresh, statusList, page, frmSubMen, slug, searchIsEnabled }) => {
    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (viewID > 0) {
            setOpen(true)
        }

    }, [viewID])

    const handleClose = () => {
        if (searchIsEnabled) {
            setOpen(false)
            setViewID(0)
            return
        }
        func && func()
        setRefresh && setRefresh(Math.random)
        setOpen(false)
        setViewID(0)

    }
    return (
        <div>

            <Dialog
                open={open}
                PaperProps={{
                    sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' },
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >

                < DialogContent >
                    <BasicTabs viewID={viewID}
                        setViewID={setViewID}
                        watch={watch}
                        frmSubMen={frmSubMen}
                        control={control}
                        page={page}
                        assignees={assignees}
                        slug={slug}
                        func={func}
                        setValue={setValue}
                        statusList={statusList} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default View;
