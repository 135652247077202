import React, { useState, useEffect } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { Leads } from '../../api/Endpoints/Leads';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Badge from '@mui/material/Badge';
import useAssignees from '../CommonFunctions/Usres';
const AssignTo = ({ id, isAssigned, DtView, isDisable, func, setRefresh }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAssignee, setSelectedAssignee] = useState(isAssigned);
    const { assignees, isLoading, fetchAssignees, } = useAssignees();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        fetchAssignees()
    };


    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleAssigneeClick = (assignee) => {
        setSelectedAssignee(assignee);
        handleChange(assignee);
        handleClose();
    };

    const handleChange = async (data) => {
        const dataToSubmit = {
            assigned_to: data.id,
            id: id,
        };

        try {
            await Leads.assign(dataToSubmit);
            toast.success(<span>Assign successful!</span>, { autoClose: 2000 });
            const sendMail = await Leads.sendEmail({
                lead_id: id,
                assigned_to: 5
            },);

        } catch (err) {
            console.log("error");
        }

        func && func();
        setRefresh && setRefresh(Math.random)
    };


    const open = Boolean(anchorEl);

    return (
        <>
            <Button class="assets-btn" onClick={handleClick} disabled={isDisable} style={{ cursor: 'pointer' }}>

                {selectedAssignee ? (
                    DtView ? (
                        <Button sx={{ color: 'black', }}>{selectedAssignee.name}</Button>
                    ) : (
                        <Badge badgeContent={selectedAssignee?.name.substr(0, 2)} color="primary" />
                    )
                ) : (
                    <PersonAddAltIcon />
                )}

            </Button >
            <Popover
                class="assign-cntr"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        minHeight: '10px',
                        maxHeight: '30vh',
                        overflowY: 'auto',
                    },
                }}
            >
                {
                    isLoading ? (
                        <Typography variant="body1" sx={{ p: 2 }}>
                            Loading...
                        </Typography>
                    ) : (
                        <Typography class="assign-cntr" variant="body1" sx={{ p: 2 }}>
                            {assignees.map((assignee) => (
                                <span key={assignee.id} style={{ fontWeight: 'bold' }}>
                                    <Button
                                        sx={{ width: '100%' }}
                                        onClick={() => handleAssigneeClick(assignee)}
                                        variant={selectedAssignee && selectedAssignee.id === assignee.id ? 'contained' : 'text'}
                                    >
                                        {assignee.name}
                                    </Button>
                                </span>
                            ))}
                        </Typography>
                    )
                }
            </Popover >
        </>
    );
};

export default AssignTo;
