import React, { Fragment, useEffect, useState } from 'react';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import { Box, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DetailPageNavBar from '../../../../containers/DetailPageNavbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { ReportsAPI } from '../../../../api/Endpoints/ReportsAPI';
import MonthPicker from '../../../../components/Form/MonthPicker';
import YearPicker from '../../../../components/Form/YearPicker';
import { useForm } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { Waveform } from '@uiball/loaders';
function CostDetails() {
    const { reportid } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm()
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const month = queryParams.get('month');
    const year = queryParams.get('year');
    const cmp = queryParams.get('campaign')
    const YearFormate = (dateString) => {
        return moment(dateString).isValid() ? moment(dateString).format('YYYY') : 'Invalid Date';
    };
    const formattedFrom = month ? month : null;
    const formattedTo = year ? YearFormate(year) : null;

    const handleNavigate = () => {
        if (window.history.length > 3) {
            const queryParams = new URLSearchParams();
            if (formattedFrom !== null) {
                queryParams.set('month', formattedFrom);
            }
            if (formattedTo !== null) {
                queryParams.set('year', formattedTo);
            }
            if (cmp !== null) {
                queryParams.set('campaign', cmp)
            }
            const queryString = queryParams.toString();
            const url = `/dashboard/reports/cost-report?${queryString}`;

            // Navigate to the dashboard/follow-ups page with 'from' and 'to' parameters
            navigate(url);
        }
    }


    useEffect(() => {
        fetchDetails();
    }, [
        reportid,
        watch('month'),
        watch('year')

    ]);

    const fetchDetails = () => {
        setLoading(true)
        let Month;
        let Year;

        if (moment(watch('month')).format('MMMM') != "Invalid date") {
            Month = moment(watch('month')).format('MMMM')
        }
        if (moment(watch('year')).format('YYYY') != "Invalid date") {
            Year = moment(watch('year')).format('YYYY')
        }
        ReportsAPI.getCostReportDetails({
            id: reportid,
            month: Month,
            year: Year
        })
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    const clearDatePicker = () => {
        setValue('month', '')
        setValue('year', '')
    }
    const rowData = ['Cost', 'Cost Per Lead', 'Cost Per Conversion', 'Reach', 'View', 'impression', 'Total +ve', 'Total -ve'];

    return (
        <>
            {
                loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }} >
                        <CircularProgress />
                    </Box >
                ) : (
                    <Fragment>

                        <div className="cnt-cntr-details">
                            <div className="fixe-menu-detail"><DetailPageNavBar /></div>
                        </div>
                        <div className='go-back-btn'>
                            <IconButton style={{ background: 'rgba(80, 97, 118, 0.04)' }} onClick={handleNavigate}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className="lead-cntr report-up-cntr">  <PageHeader title={`Campaign Performance Report - ${data && data.campaign_name || ""}`} />
                            <div className="title-sec" style={{
                                display: 'flex',
                                gap: '20px',
                                alignItems: 'center',
                                justifyContent: 'end',
                                overflowX: 'hidden',
                                marginRight: '10px'

                            }}>


                                <Grid item xs={12} sm={6}>
                                    <MonthPicker
                                        control={control}
                                        name="month"
                                        placeholder="Month"
                                        value={watch('month')}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <YearPicker
                                        control={control}
                                        name="year"
                                        value={watch('year')}
                                        placeholder="Year"

                                    />
                                </Grid>

                                <Grid item xs={4}  >
                                    <IconButton className=''
                                        sx={{ background: 'white' }}

                                        edge="end"

                                        onClick={clearDatePicker}

                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Grid>

                            </div>

                            {data?.data.length > 0 ? (


                                <Fragment>
                                    <TableContainer component={Paper} sx={{ mt: 4 }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Metrics</TableCell>
                                                    {data &&
                                                        data.data.map(monthData => (
                                                            <TableCell align="left" key={monthData.month_year}>
                                                                {monthData.month_year}
                                                            </TableCell>
                                                        ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowData.map(item => (
                                                    <TableRow key={item}>
                                                        <TableCell align="left">{item}</TableCell>
                                                        {data &&
                                                            data.data.map(monthData => (
                                                                <TableCell key={monthData.month_year} align="left">
                                                                    {item === 'Cost' ? monthData.report['total_cost'] :
                                                                        item === 'Total +ve' ? monthData.report['positive_lead_count'] :
                                                                            item === 'View' ? monthData.report['views'] :
                                                                                item === 'Total -ve' ? monthData.report['negative_lead_count'] :
                                                                                    monthData.report[item.toLowerCase().replace(/\s+/g, '_')]}
                                                                </TableCell>
                                                            ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Fragment>
                            ) : (
                                <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Metrics not found.</Typography>
                                </Box>
                            )}

                        </div >

                    </Fragment>)}
        </>
    );
}

export default CostDetails;
