import { Leads } from '../../../api/Endpoints/Leads'
import { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CustomToolbar from '../../../components/Tools/CustomeToolbar';
import moment from 'moment';
import View from './View'
import DateInput from '../../../components/Form/DateInput';
import { useForm } from 'react-hook-form';
import StatusSelector from '../../../components/Form/Status';
import PageHeader from '../../../components/PageHeader/PageHeader';
import AssignTo from '../../../components/Form/AssignTo';
import useAssignees from '../../../components/CommonFunctions/Usres';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Create from '../Leads/create';
import Delete from '../../../components/Popup/Delete';
import CloseLeadPopup from '../../../components/Popup/ClosedLead';
import BlockIcon from '@mui/icons-material/Block';
import { GlobalRefreshContext } from '../../../contexts/GlobalRefresh/GlobalRefresh';
import { Waveform } from '@uiball/loaders';
import { Fragment } from 'react';
import StatusLIstFunction from '../../../components/CommonFunctions/statusLIst';
import { Statitics } from '../../../api/Endpoints/Statitics';
import DoughnutChart from '../../../components/Charts/DoughnutChart';
import BarChart from '../../../components/Charts/BarChart';
import ArchiveIcon from '@mui/icons-material/Archive';
import LineChart from '../../../components/Charts/LinChart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
export default function BasicTable() {

  const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
  const projectData = JSON.parse(localStorage.getItem('project'));
  const { GlobalRefresh } = GlobalRefreshContext();
  const [chartOptions, setChartOptions] = useState(false);
  const [dailyChartData, setDailyChartData] = useState(false);
  const [statusChartData, setStatusChartData] = useState(false);
  const [CardData, setCardData] = useState()
  const { statusList, fetchStatusList } = StatusLIstFunction();
  const [selectedRow, setSelectedRow] = useState([])
  const location = useLocation();
  const columns = [
    {
      field: 'name',
      headerName: 'Customer Name',
      width: 120,
      renderCell: (params) => (
        <span
          style={{
            cursor: 'pointer'
          }}
          onClick={() => viewTabs(params)}
        >
          {params.row.name}
        </span>

      ),
    },

    {
      field: 'assigned_to_details',
      headerName: 'Assign To',
      width: 100,
      renderCell: (params) => (
        <>
          <Button size='small' style={{ color: '#6f6f6f' }}>
            <AssignTo id={params.id}
              isAssigned={params.row.assigned_to_details}
              name={`assigned_to_details`}
              setValue={setValue}
              watch={watch} />

          </Button>
        </>
      ),



    },


    {
      field: 'created_at',
      headerName: 'Date',
      width: 100,
      renderCell: (params) => {
        const formattedDate = moment.utc(params.row.created_at).format('DD-MM-YYYY');
        return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;

      },
    },
    {
      field: 'project_name',
      headerName: 'Project Name',
      width: 120,
      renderCell: (params) => {
        return <Typography style={{ color: '#6f6f6f' }}>{params.row?.project?.name}</Typography>;
      }
    },
    {
      field: 'source_name',
      headerName: 'Source',
      width: 120,
      renderCell: (params) => {
        return <Typography style={{ color: '#6f6f6f' }}>{params.row?.source}</Typography>;
      }
    },
    {
      field: 'follow_up_date',
      headerName: 'Follow Up',
      width: 150,
      renderCell: (params) => {
        const { id } = params;
        return (
          <DateInput
            id={id} // Pass the unique identifier for this specific date field
            name={`follow_up_date-${id}`}
            // Provide a unique name for each date field
            control={control}
            setValue={setValue}
            // watch={watch}
            value={params.row?.follow_up_date}
            // Update the watch function call here
            func={() => { fetchLeadsForTableUpdate() }
            }
          />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => {
        return (
          <StatusSelector
            statusList={statusList}
            options={params.row?.crm_status_details}
            id={params.id}
            func={fetchLeads}
          />
        )
      }
    },

    {
      width: 50,
      renderCell: (params) => <MoreOptionsDropdown params={params} />,
    },


  ]
  const { watch, formState: { errors }, control, Controller, setValue } = useForm({})


  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([])
  const [ViewID, setViewID] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const { assignees, isLoading, fetchAssignees } = useAssignees();
  const [isMounted, setIsMounted] = useState(false);
  const [editId, setEditId] = useState();
  const [ChartData, setChartData] = useState()
  const [DeleteID, setDeleteID] = useState(0)
  const [CloseID, setCloseID] = useState(0)
  const [showClosePopup, setShowClosePopup] = useState(false)
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);



  const fetchLeads = async () => {
    try {
      setLoading(true);
      const response = await Leads.get({ page: parseInt(paginationModel?.page) + 1, crm_lead_type_id: leadTypeData?.ID, project_id: projectData.ID });
      setLeads(response.data.leads);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  const fetchLeadsForTableUpdate = async () => {
    try {
      const response = await Leads.get({ page: parseInt(paginationModel?.page) + 1, crm_lead_type_id: leadTypeData?.ID, project_id: projectData.ID });
      setLeads(response.data.leads);

    } catch (error) {
      console.log(error);

    }
  };



  const fetchStatitics = async () => {
    let response =
      await Statitics.getStatitics({
        from_date: selectedFromDate && moment(selectedFromDate).format('DD-MM-YYYY'),
        to_date: selectedToDate && moment(selectedToDate).format('DD-MM-YYYY')
      });
    let status = response.data?.data?.status?.map(obj => {
      return obj.status;
    })

    let CardData = response.data
    setCardData(CardData)
    setChartData(CardData)
    // let statusCount = response.data?.data?.status?.map(obj => {
    //   return obj.count;
    // })

    // let colors = response.data?.data?.status?.map(obj => {
    //   return obj.color;
    // })



    // let data = {
    //   labels: status,
    //   datasets: [
    //     {
    //       label: '# of leads',
    //       data: statusCount,
    //       backgroundColor: colors,
    //       borderColor: colors,
    //       borderWidth: 1,
    //     },
    //   ],
    // };

    // setStatusChartData(data);


    // let options = {
    //   responsive: true,
    //   plugins: {
    //     legend: {
    //       position: 'top',
    //     },
    //     title: {
    //       display: true,
    //       text: 'Daily chart',
    //     },

    //   },
    // };

    // setChartOptions(options)



    // let labels = response.data?.chart?.chart.map(obj => {
    //   return obj.date;
    // })
    // let count = response.data?.chart?.chart.map(obj => {
    //   return obj.count;
    // })


    // let dataC = {
    //   labels,
    //   datasets: [
    //     {
    //       label: 'Leads',
    //       data: response.data?.chart?.chart.map((obj) => obj.count),
    //       backgroundColor: '#0053A0',
    //     }
    //   ],
    // };



    // setDailyChartData(dataC);


    // setLoading(false);
  }

  const navigate = useNavigate();
  const getPageNumber = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('page');
  };

  const pageNumber = getPageNumber();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: pageNumber || 0
    ,
  });

  useEffect(() => {
    setIsMounted(true)
    if (isMounted) {
      fetchLeads();
    }
    return () => {
      setIsMounted(false)
    }
  }, [isMounted, refresh, GlobalRefresh]);


  useEffect(async () => {
    await fetchStatusList()
  }, [])

  useEffect(() => {
    // fetchStatitics()
  }, [
    selectedFromDate,
    selectedToDate
  ])


  const handleCreate = () => {
    setEditId(0)
  }
  const handleUpdate = () => {
    setRefresh(Math.random)
    setEditId(undefined)
  }

  const viewTabs = (params) => {
    setViewID(params.id)
  }
  const clearDatePicker = () => {
    setSelectedFromDate(null)
    setSelectedToDate(null)
  }



  // More option Funciton

  const MoreOptionsDropdown = ({ params }) => {
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
      setAnchorEl(null);
    };


    const handleEdit = (id) => {
      setEditId(parseInt(id));
    }

    const handleDelete = (id) => {
      setDeleteID(id)
      handleClose();
    };

    const handleCloseLead = (id) => {
      setCloseID(id)
      setShowClosePopup(true)
    }

    return (
      <>
        <IconButton size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem class="edit-nav-cntr" onClick={() => handleEdit(params.id)}>
            <EditIcon sx={{ fontSize: 14, mr: 1, }} />
            Edit
          </MenuItem>

          <MenuItem class="edit-nav-cntr delete-sec" onClick={() => handleDelete(params.id)}>
            <DeleteIcon sx={{ fontSize: 14, mr: 1, }} />
            Delete
          </MenuItem>

          <MenuItem class="edit-nav-cntr" onClick={() => handleCloseLead(params.id)}>
            <ArchiveIcon sx={{ fontSize: 14, mr: 1, }} />
            Archive
          </MenuItem>
        </Menu >
      </>
    );
  };

  const handleRowSelection = (newSelection) => {
    setSelectedRow(newSelection)
  };
  const handleRowSelectionDelete = () => {
    if (selectedRow.length > 0) {
      setDeleteID(selectedRow)
    } else {
      toast.warning("Please select a lead to delete", { autoClose: 3000 });
    }

  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
      },
    },
  };
  const handlePageChange = (page) => {
    setPaginationModel(page)
    const queryParams = new URLSearchParams(location.search);
    if (page && page.page > 0) {
      queryParams.set('page', page.page);
    } else {
      queryParams.delete('page');
    }
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    setRefresh(Math.random)
  }


  return (
    <div class="lead-cntr">
      <PageHeader title={'Dashboard'} />

      {/* Popup's  */}
      <Card sx={{ display: 'flex', justifyContent: 'start' }}  >


        {showClosePopup &&
          <CloseLeadPopup id={CloseID}
            setShowClosePopup={setShowClosePopup}
            func={fetchLeads} />
        }

        {/* Props Passing  */}

        <View
          viewID={ViewID}
          setViewID={setViewID}
          page={paginationModel.page}
          watch={watch}
          fromHome={true}
          control={control}
          setValue={setValue}
          fetchLeads={fetchLeadsForTableUpdate} //Fetch leads funcion to Update the Dom
          assignees={assignees} //Assignees list ,From AssignUser Api
          statusList={statusList}
        />


        {/* Delete Popup Component */}

        <Delete
          DeleteID={DeleteID}
          setDeleteID={setDeleteID}
          Callfunc={fetchLeadsForTableUpdate}
          url={DeleteID.length > 0 ? '/lead/action/bulk-delete' : '/lead/action/delete'}
          title={DeleteID.length > 0 ? "Leads" : "this Lead"}
        />



      </Card >



      {/* 
      <Homedata CardData={CardData} setLoading={setLoading} /> */}


      <Create
        onNew={handleCreate}
        onUpdate={handleUpdate}
        editId={editId}
        setEditId={setEditId}
        disableCtnBtn={true} />


      {/* <LocalizationProvider dateAdapter={AdapterMoment} >
        <Grid container direction="row"
          mb={2}
        >
          <Grid item xs={4}  >
            <div class="follw-dates">
              <DatePicker
                value={selectedFromDate}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="From" fullWidth />
                )}
                onChange={(newValue) => setSelectedFromDate(newValue)}
                inputFormat="DD/MM/YYYY"
              />
            </div>
          </Grid>

          <Grid item xs={4}  >
            <DatePicker
              value={selectedToDate}

              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="To" fullWidth
                />
              )}
              onChange={(newValue) => setSelectedToDate(newValue)}
              inputFormat="DD/MM/YYYY"
            />
          </Grid> <Grid item xs={4}  >
            <IconButton className=''
              sx={{ ml: 2, mt: 1, background: 'white' }}

              edge="end"

              onClick={clearDatePicker}

            >
              <ClearIcon />
            </IconButton>
          </Grid>

        </Grid>
      </LocalizationProvider> */}

      {/* 
      <Grid container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={8}>
          <Card sx={{
            m: 0,
            p: 5,
            background: "#E7FDF3",
            height: "550px",
          }}>
            <Grid sx={{ pb: 2 }}>


            </Grid>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{
            m: 0,
            p: 5,
            background: "#E9E7FD",
            height: "550px",
          }}>
            <Grid>

              {statusChartData && <DoughnutChart chartData={statusChartData} />}


            </Grid>
          </Card>
        </Grid>
      </Grid> */}


      {/* {console.log("chart data", ChartData)} */}


      {/* <div className='home-lead-chart'>
        <div className='home-lead-chart-big'>
          {ChartData && < BarChart chartOptions={options} data={ChartData.campaign_wise_lead_coun} label={"Campaign Leads"} color={'rgba(53, 162, 235, 0.5)'} />}
        </div>

        <div className='home-lead-chart-right'>
          <div className='home-lead-chart-right-item block-02'>
            {ChartData && < DoughnutChart chartOptions={options} data={ChartData.project_wise_lead_count} label={"Project Wise Leads"} labels={['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange']} />}
          </div>
        </div>

        <div className='home-lead-chart-full-item'>
          {ChartData && < LineChart chartOptions={options} data={ChartData.project_wise_lead_count} label={"Project Wise Leads"} color={'rgba(53, 162, 235, 0.5)'} />}
        </div>


      </div> */}


      {!loading && leads.data ?
        <>

          <IconButton className='dlt-icon-dash dash-dlt-btn'
            sx={{ mr: 2, background: 'white' }}

            edge="end"

            onClick={handleRowSelectionDelete}

          >
            <DeleteIcon className='delete-icon' color='red' />
          </IconButton>
        </>

        : null}


      {
        loading ?
          <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

            <Waveform
              size={40}
              lineWeight={3.5}
              speed={1}
              color="blue"
            />
          </Box>
          : (
            <>
              {
                leads.data?.length > 0 ? (
                  <Fragment>
                    < Card sx={{
                      m: 0,
                    }} variant="outlined" class="lead-table" >

                      {
                        typeof leads.data === "object" &&
                        <DataGrid
                          sx={{
                            width: '100%',
                          }}
                          paginationMode="server"
                          rows={leads?.data}
                          paginationModel={paginationModel}
                          columns={columns}
                          rowCount={leads?.total}
                          rowHeight={70}
                          autoHeight={true}
                          scrollbarSize={10}
                          density="comfortable"
                          checkboxSelection
                          onPaginationModelChange={handlePageChange}
                          onRowSelectionModelChange={handleRowSelection}
                          disableColumnMenu
                          disableRowSelectionOnClick
                          disableExtendRowFullWidth
                        />
                      }

                    </ Card>
                  </Fragment>
                ) : (

                  <Box sx={{ width: '100%', marginTop: 15, height: 40, display: 'flex', justifyContent: 'center' }}>
                    <Typography>Leads not found</Typography>
                  </Box>
                )
              }
            </>
          )
      }


    </div >

  )
};


