import React, { useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import { useEffect } from 'react';
import BasicTabs from '../../Leads/TabMenus';
import { CampaignsAPI } from '../../../../api/Endpoints/CampaignsAPI';
import { makeStyles } from "@material-ui/core"
import moment from 'moment';
import { Waveform } from '@uiball/loaders';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: "1px 2px 2px 2px rgba(0, 0, 0, 0.3)",
    },
    title: {
        fontWeight: "bold",
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}))
const DetailsView = ({ viewID, setViewID, }) => {
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState()
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        if (viewID > 0) {
            fetchCampaignDetails()
            setOpen(true)
        }

    }, [viewID])



    const fetchCampaignDetails = () => {
        setLoading(true)
        CampaignsAPI.getDetails({ id: viewID })
            .then((response) => {
                setDetails(response.data.campaign)
                setLoading(false)
            })
            .catch((errors) => {
                console.log(errors)
            })
    }

    return (
        <div>
            {/* Dialog Box Component */}


            {loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <Waveform
                        size={40}
                        lineWeight={3.5}
                        speed={1}
                        color="blue"
                    />
                </Box >
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body1"
                                component="h2"
                                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                            >
                                Campaign Name :
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "16px", display: 'inline', marginLeft: 10 }}
                                >
                                    {details?.name}
                                </Typography>
                            </Typography>

                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2} sm={12} xs={12}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="body1"
                                component="h2"
                                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                            >
                                Start Date :
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "16px", display: 'inline', marginLeft: 10 }}
                                >
                                    {details?.start_date && moment(details.start_date).format('DD-MM-YYYY')}
                                </Typography>
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="body1"
                                component="h2"
                                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                            >
                                End Date :
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "16px", display: 'inline', marginLeft: 10 }}
                                >
                                    {details?.end_date && moment(details.end_date).format('DD-MM-YYYY')}
                                </Typography>
                            </Typography>

                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2} sm={12} xs={12}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="body1"
                                component="h2"
                                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                            >
                                Total budget :
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "16px", display: 'inline', marginLeft: 10 }}
                                >
                                    {details?.total_budget}
                                </Typography>
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="body1"
                                component="h2"
                                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                            >
                                Status:
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "16px", display: 'inline', marginLeft: 10 }}
                                >
                                    {details?.status == 0 ? "INACTIVE" : "ACTIVE"}
                                </Typography>
                            </Typography>

                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body1"
                                component="h2"
                                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                            >
                                Description :
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "16px", display: 'inline', marginLeft: 10 }}
                                >
                                    {details?.name}
                                </Typography>
                            </Typography>

                        </Grid>
                    </Grid>
                </>
            )
            }


        </div >
    );
};

export default DetailsView;
