import EditIcon from '@mui/icons-material/Edit';
import { Leads } from '../../../../api/Endpoints/Leads';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Card, Container, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import CustomToolbar from '../../../../components/Tools/CustomeToolbar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DateInput from '../../../../components/Form/DateInput';
import { useForm } from 'react-hook-form';
import StatusSelector from '../../../../components/Form/Status';
import DeleteIcon from '@mui/icons-material/Delete';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import AssignTo from '../../../../components/Form/AssignTo';
import useAssignees from '../../../../components/CommonFunctions/Usres';
import Delete from '../../../../components/Popup/Delete';
import View from '../View';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Create from '../create';
import { Waveform } from '@uiball/loaders';
import { Box } from '@material-ui/core';
import { GlobalRefreshContext } from '../../../../contexts/GlobalRefresh/GlobalRefresh';
import { DynamicdropdownsAPI } from '../../../../api/Endpoints/DynamicDropdown';
import { cancelLastRequest } from '../../../../api/Config/config';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseLeadPopup from '../../../../components/Popup/ClosedLead';
import { toast } from 'react-toastify';
export default function SubMenus() {

    const columns = [

        {
            field: 'name',
            headerName: 'Customer Name',
            width: 250,
            renderCell: (params) => (
                <span
                    style={{
                        cursor: 'pointer',
                        whiteSpace: 'break-spaces'
                    }}
                    onClick={() => viewTabs(params)}
                >
                    {params.row.name}
                </span>

            ),
        },


        {
            field: 'assigned_to_details',
            headerName: 'Assign To',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button size='small' style={{ color: '#6f6f6f' }}>
                        <AssignTo id={params.id}
                            isAssigned={params.row.assigned_to_details}
                            name={`assigned_to_details`}
                            assignees={assignees}
                            isLoading={isLoading}
                            setValue={setValue}
                            watch={watch} />

                    </Button>
                </>
            ),



        },

        {
            field: 'created_at',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => {
                const formattedDate = moment.utc(params.row.created_at).format('DD-MM-YYYY');
                return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;
            },
        },
        {
            field: 'project_name',
            headerName: 'Project Name',
            width: 150,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.project?.name}</Typography>;
            }
        },
        {
            field: 'source_name',
            headerName: 'Source',
            width: 120,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.source}</Typography>;
            }
        },
        {
            field: 'follow_up_date',
            headerName: 'Follow Up',
            width: 150,
            renderCell: (params) => {
                const { id } = params;
                return (
                    <DateInput
                        id={id} // Pass the unique identifier for this specific date field
                        name={`follow_up_date-${id}`}
                        // Provide a unique name for each date field
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        value={params.row?.follow_up_date}

                    // setRefresh={setRefresh}
                    // Update the watch function call here
                    // func={() => { fetchLeads() },


                    />
                );
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <StatusSelector
                        statusList={statusList}
                        options={params.row?.crm_status_details}
                        id={params.id}
                        func={TableUpdate}
                    />
                )
            }
        },


        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown params={params} />,
        },

    ]


    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})
    const location = useLocation();
    const navigate = useNavigate();
    const getQueryParameter = (key) => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(key);
    };

    const pageNumber = getQueryParameter('page');


    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: pageNumber || 0,
    });
    const [loading, setLoading] = useState(false);
    const [leads, setLeads] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [editId, setEditId] = useState();
    const [ViewID, setViewID] = useState(0)
    const [DeleteID, setDeleteID] = useState(0)
    const [isMounted, setIsMounted] = useState(false);
    const [showClosePopup, setShowClosePopup] = useState(false)
    const [CloseID, setCloseID] = useState(0)
    const [TableUp, setTableUpdate] = useState(false)
    const [showModal, setModal] = useState(false)
    const [statusList, setStatusList] = useState([])

    const [LeadStatus, setLeadStatus] = useState([])
    const { GlobalRefresh } = GlobalRefreshContext();
    const [selectedRow, setSelectedRow] = useState([])
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));

    const prevSlugRef = useRef('');

    const TableUpdate = () => {
        setTableUpdate(true)
        setRefresh(Math.random)
    }

    const { assignees, isLoading, fetchAssignees, } = useAssignees();

    const handlePageChange = (page) => {
        setPaginationModel(page)
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', page?.page);
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        setRefresh(Math.random)

    }


    const MoreOptionsDropdown = ({ params }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = (id) => {
            setEditId(parseInt(id));
            setModal(true)
        }

        const handleDelete = (id) => {
            setDeleteID(id)
            handleClose();
        };
        const handleCloseLead = (id) => {
            setCloseID(id)
            setShowClosePopup(true)
        }

        return (
            <>
                <IconButton size="small" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem class="edit-nav-cntr" onClick={() => handleEdit(params.id)}>
                        <EditIcon sx={{ fontSize: 14, mr: 1 }} />
                        Edit
                    </MenuItem>
                    <MenuItem class="edit-nav-cntr delete-sec" onClick={() => handleDelete(params.id)}>
                        <DeleteIcon sx={{ fontSize: 14, mr: 1 }} />
                        Delete
                    </MenuItem>
                    <MenuItem class="edit-nav-cntr" onClick={() => handleCloseLead(params.id)}>
                        <ArchiveIcon sx={{ fontSize: 14, mr: 1, }} />
                        Archive
                    </MenuItem>

                </Menu >
            </>
        );
    };


    const { slug: currentSlug } = useParams();



    const fetchLeads = async (status) => {

        if (!TableUp) {
            setLoading(true)
            fetchLeadDropdownStatus()
        }

        // await fetchLeadDropdownStatus()

        try {

            const response = await Leads.newLeads({
                page: parseInt(paginationModel?.page) + 1,
                crm_status: status,
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
            });
            setLeads(response.data.leads);
            setLoading(false)
            setTableUpdate(false);
            // callback()
        } catch (error) {
            console.error("Error fetching leads:", error);
        }
    };

    const fetchLeadDropdownStatus = async () => {

        try {
            const response = await DynamicdropdownsAPI.get();
            if (response.data.crm_status) {
                const dataSet = response.data.crm_status;
                setStatusList(dataSet)
                let currentStatus = null;
                for (const item of dataSet) {
                    if (item.slug === currentSlug) {
                        currentStatus = item;
                        break;
                    }
                }
                if (currentStatus) {
                    setLeadStatus(currentStatus.name);
                } else {
                    console.log('Status not found for slug:', currentSlug);
                }
                setTableUpdate(false);

            }
        } catch (error) {
            console.error("Error fetching lead dropdown status:", error);
        }
    };

    // useEffect(() => {
    //     fetchLeads(slug)
    // }, [paginationModel, refresh, GlobalRefresh, slug]);


    useEffect(() => {

        const storedPrevSlug = prevSlugRef.current;
        if (currentSlug && storedPrevSlug && currentSlug !== storedPrevSlug) {
            setPaginationModel({ page: 0, pageSize: 15 });
            setRefresh(Math.random)
        }

        prevSlugRef.current = currentSlug;


    }, [currentSlug]);


    useEffect(() => {
        fetchLeads(currentSlug);
    }, [refresh, GlobalRefresh,])





    const handleCreate = () => {
        setEditId(0)
    }

    const handleUpdate = () => {
        setEditId(undefined)
        setRefresh(Math.random)

    }

    const viewTabs = (params) => {
        setViewID(params.id)
    }

    const handleRowSelection = (newSelection) => {
        setSelectedRow(newSelection)
    };

    const handleRowSelectionDelete = () => {
        if (selectedRow.length > 0) {
            setDeleteID(selectedRow)
        } else {
            toast.warning("Please select a lead to delete", { autoClose: 3000 });
        }

    }

    return (


        <div class="page-cntr">
            {/* {notFound && <Navigate to={'/not-found'} />} */}



            {/* Popup's  */}


            <Delete
                DeleteID={DeleteID}
                setDeleteID={setDeleteID}
                setRefresh={setRefresh}
                url={DeleteID.length > 0 ? '/lead/action/bulk-delete' : '/lead/action/delete'}
                title={DeleteID.length > 0 ? "these Leads" : "this Lead"}
            />

            {
                !TableUp && loading ? (
                    <Box sx={{ width: '100%', marginTop: 100, height: 100, display: 'flex', justifyContent: 'center' }}>

                        <Waveform
                            size={40}
                            lineWeight={3.5}
                            speed={1}
                            color="blue"
                        />
                    </Box>
                ) : (




                    <>
                        {showClosePopup && <CloseLeadPopup id={CloseID} setShowClosePopup={setShowClosePopup} func={fetchLeads} />}

                        < PageHeader title={LeadStatus} total={leads.total} />
                        {leads?.data?.length > 0 && <Button className='lead-submenu-btn' onClick={handleRowSelectionDelete}> <DeleteIcon className='delete-icon ' color='red' /></Button>}

                        {showModal &&
                            <Create
                                onNew={handleCreate}
                                onUpdate={handleUpdate}
                                editId={editId}
                                setEditId={setEditId}
                                disableCtnBtn={true}
                                setModal={setModal}
                            />

                        }


                        < Card sx={{ display: 'flex', justifyContent: 'start' }}  >

                            <View
                                viewID={ViewID}
                                setViewID={setViewID}
                                watch={watch}
                                control={control}
                                setFormValue={setValue}
                                assignees={assignees}
                                statusList={statusList}
                                page={paginationModel.page}
                                frmSubMen={true}
                                slug={currentSlug}
                                func={TableUpdate} />


                        </Card >

                        {leads.data?.length > 0 ? (
                            <Fragment>
                                < Card sx={{
                                    m: 0,
                                }} variant="outlined" class="lead-table" >

                                    {
                                        typeof leads.data === "object" &&
                                        <DataGrid

                                            sx={{
                                                width: '100%',
                                            }}
                                            paginationMode="server"
                                            rows={leads?.data}
                                            paginationModel={paginationModel}
                                            columns={columns}
                                            rowCount={leads?.total}
                                            rowHeight={70}
                                            autoHeight={true}
                                            scrollbarSize={10}
                                            density="comfortable"
                                            checkboxSelection
                                            onPaginationModelChange={handlePageChange}
                                            onRowSelectionModelChange={handleRowSelection}
                                            disableColumnMenu
                                            disableRowSelectionOnClick
                                            disableExtendRowFullWidth
                                        />
                                    }

                                </ Card>
                            </Fragment>
                        ) : (

                            <Box sx={{ width: '100%', marginTop: 100, height: 100, display: 'flex', justifyContent: 'center' }}>
                                <Typography>{LeadStatus} leads not found</Typography>
                            </Box>
                        )}
                    </>
                )
            }

        </div >


    )
};
