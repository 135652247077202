import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Notes } from '../../../../api/Endpoints/Notes';
import { toast } from 'react-toastify';
import moment from 'moment';
import Delete from '../../../../components/Popup/Delete';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import CurrentDateInput from '../../../../components/Form/CurrenDateInput';


export default function DataTable({ viewID }) {
    const schema = yup.object().shape({
        notes: yup.string().required('Field is required'),
    });


    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const [listNotes, setListNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [DeleteID, setDeleteID] = useState(0)
    const [refresh, setRefresh] = useState()
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const fetchNotes = () => {
        setLoading(true);
        Notes.getNotes({ id: viewID })
            .then((response) => {
                setListNotes(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const TableUpdate = () => {
        setRefresh(Math.random)
    }
    useEffect(() => {
        fetchNotes();
    }, [viewID, refresh]);

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const dataToSubmit = {
            id: viewID,
            notes: data.notes,
        };

        Notes.add(dataToSubmit)
            .then((response) => {
                if (response.data.status === true) {
                    toast.success('Note assigned successfully');
                    setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                    reset(); // Reset the form after successful submission
                    TableUpdate()
                }
            })
            .catch((err) => {
                console.error(err);
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            });
    };


    const handleDelete = (id) => {
        setDeleteID(id)

    };
    return (
        <>

            <Delete
                DeleteID={DeleteID}
                setDeleteID={setDeleteID}
                Callfunc={fetchNotes}
                url={'/lead/delete-note'}
                title={'Note'}
            />

            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (

                <div className="pop-note">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container alignItems="center" sx={{ p: 1 }} item xs={12}>

                            <TextField
                                sx={{ width: '70%' }}
                                {...register('notes')}
                                label="Add Note"
                                size="medium"
                                multiline
                                rows={4}
                                id="outlined-multiline-static"
                                error={Boolean(errors.notes)

                                }
                            />
                            <LoadingButton id="notes-add-btn" loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>
                    </form>

                    <div className='talk-bubble-block'>
                        {listNotes.map((item) => (
                            < div class="talk-bubble tri-right round border right-top" >
                                <div class="talktext">
                                    <p>{item.notes}</p>
                                    <div className='author'>
                                        <Box>
                                            <h4>{item.created_user.name}</h4>
                                            <h5>{moment(item.created_at).format('DD-MM-YYYY')}</h5>
                                        </Box>
                                        <IconButton onClick={() => handleDelete(item.id)}>
                                            <DeleteIcon className='delete-icon' style={{ color: 'red' }} />
                                        </IconButton>
                                    </div>



                                </div>
                            </div>
                        ))}
                    </div>

                </div >
            )
            }
        </>
    );
}
