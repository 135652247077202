import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import CustomToolbar from '../../../../components/Tools/CustomeToolbar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import Create from '../../Leads/create'
import PageHeader from '../../../../components/PageHeader/PageHeader';
import Delete from '../../../../components/Popup/Delete';
import { Waveform } from '@uiball/loaders'
import GroupsIcon from '@mui/icons-material/Groups';
import { Lables } from '../../../../api/Endpoints/Label';
import { GlobalRefreshContext } from '../../../../contexts/GlobalRefresh/GlobalRefresh';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DateInput from '../../../../components/Form/DateInput';
import AssignTo from '../../../../components/Form/AssignTo';
import useAssignees from '../../../../components/CommonFunctions/Usres';
import StatusLIstFunction from '../../../../components/CommonFunctions/statusLIst';
import StatusSelector from '../../../../components/Form/Status';
import View from '../../Leads/View';



export default function Index() {
    const columns = [

        {
            field: 'name',
            headerName: 'Customer Name',
            width: 250,
            renderCell: (params) => (
                params.row.label ? (
                    <span
                        id='label_text'
                        style={{
                            backgroundColor: params.row?.label?.bg_color,
                            color: params.row?.label?.text_color,
                            padding: 5,
                            borderRadius: 12,
                            fontWeight: 600,
                            cursor: 'pointer'
                        }}
                        onClick={() => viewTabs(params)}
                    >
                        {params.row.name}
                    </span>
                ) : (
                    <span onClick={() => viewTabs(params)} style={{ cursor: 'pointer' }}>{params.row.name}</span>
                )
            ),
        },



        {
            field: 'assigned_to_details',
            headerName: 'Assign To',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button size='small' style={{ color: '#6f6f6f' }}>
                        <AssignTo id={params.id}
                            isAssigned={params.row.assigned_to_details}
                            name={`assigned_to_details`}
                            assignees={assignees}
                            setValue={setValue}
                            setRefresh={setRefresh}
                            watch={watch} />

                    </Button>
                </>
            ),



        },

        {
            field: 'created_at',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => {
                const formattedDate = moment.utc(params.row.created_at).format('DD-MM-YYYY');
                return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;
            },
        },
        {
            field: 'project_name',
            headerName: 'Project Name',
            width: 150,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.project?.name}</Typography>;
            }
        },
        {
            field: 'source_name',
            headerName: 'Source',
            width: 120,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.source}</Typography>;
            }
        },
        {
            field: 'follow_up_date',
            headerName: 'Follow Up',
            width: 160,
            renderCell: (params) => {
                const { id } = params;
                return (
                    <DateInput
                        id={id} // Pass the unique identifier for this specific date field
                        name={`follow_up_date-${id}`}
                        // Provide a unique name for each date field
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        value={params.row?.follow_up_date}
                        func={TableLoading}

                    />

                );
            }
        },

        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <StatusSelector
                        statusList={statusList}
                        options={params.row?.crm_status_details}
                        id={params.id}
                        func={TableUpdate}
                    />
                )
            }
        },

        {
            width: 50,
            renderCell: (params) => <MoreOptionsDropdown params={params} />,
        },

    ]

    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})
    const location = useLocation();

    const getPageNumber = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('page');
    };

    const pageNumber = getPageNumber();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: pageNumber || 0
        ,
    });


    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [editId, setEditId] = useState();
    const [DeleteID, setDeleteID] = useState(0)
    const [isMounted, setIsMounted] = useState(false);
    const [showClosePopup, setShowClosePopup] = useState(false)
    const [CloseID, setCloseID] = useState(0)
    const [ViewID, setViewID] = useState(0)
    const [LabelType, setLabelType] = useState('')
    const [TableLoading, setTableLoading] = useState(false)
    const [showModal, setModal] = useState(false)
    const { GlobalRefresh } = GlobalRefreshContext();
    const { slug } = useParams();
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const navigate = useNavigate();


    const viewTabs = (params) => {
        setViewID(params.id)
    }



    const MoreOptionsDropdown = ({ params }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleEdit = (id) => {
            setEditId(parseInt(id));
            setModal(true)
        }

        const handleDelete = (id) => {
            setDeleteID(id)
            handleClose();
        };
        const handleCloseLead = (id) => {
            setCloseID(id)
            setShowClosePopup(true)
        }

        return (
            <>
                <IconButton size="small" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} sx={{ cursor: 'pointer' }}>

                    <MenuItem class="edit-nav-cntr" onClick={() => handleEdit(params.id)}>
                        <EditIcon sx={{ fontSize: 14, mr: 1, }} />
                        Edit
                    </MenuItem>
                    <MenuItem class="edit-nav-cntr delete-sec" onClick={() => handleDelete(params.id)}>
                        <DeleteIcon sx={{ fontSize: 14, mr: 1 }} />
                        Delete
                    </MenuItem>

                </Menu >
            </>
        );
    };

    useEffect(() => {
        const abortController = new AbortController();
        setIsMounted(true)
        if (isMounted) {

            fetchLabelLeads(slug);

        }

        return () => {
            setIsMounted(false)
            abortController.abort();
        }

    }, [paginationModel, isMounted, GlobalRefresh, refresh, slug]);


    useEffect(() => {
        const abortController = new AbortController();
        fetchStatusList()
        return () => {
            abortController.abort();
        }
    }, [])

    const { assignees, isLoading, fetchAssignees, } = useAssignees();
    const { statusList, fetchStatusList, } = StatusLIstFunction();

    const fetchLabelLeads = async (slug) => {
        try {
            if (!TableLoading) {
                setLoading(true);
            }
            const response = await Lables.getLabelizedLeads({
                page: parseInt(paginationModel?.page) + 1,
                crm_lead_type_id: leadTypeData?.ID,
                project_id: projectData.ID,
                labels_id: slug
            });
            setList(response.data.leads);
            setLabelType(response.data.label.title)
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        } finally {
            setTableLoading(false)
        }
    };



    const TableUpdate = () => {
        setTableLoading(true)
        fetchLabelLeads(slug)
    }


    const handleCreate = () => {
        setEditId(0)
    }
    const handleUpdate = () => {
        setRefresh(Math.random)
    }

    const handlePageChange = (page) => {
        setPaginationModel(page)
        const queryParams = new URLSearchParams(location.search);
        if (page && page.page > 0) {
            queryParams.set('page', page.page);
        } else {
            queryParams.delete('page');
        }
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        setRefresh(Math.random)
    }



    return (

        <div class="page-cntr">



            <div class="lead-table labelleads lead-page-cntr">

                {/* Popup's  */}



                <Delete
                    DeleteID={DeleteID}
                    setDeleteID={setDeleteID}
                    Callfunc={fetchLabelLeads}
                    url={'/label/delete'}
                    title={'Label'}
                />

                <View viewID={ViewID}
                    setViewID={setViewID}
                    watch={watch}
                    frmLabel={true}
                    control={control}
                    setFormValue={setValue}
                    assignees={assignees}
                    statusList={statusList}
                    page={0}
                    func={TableUpdate} />



                {!TableLoading && loading ?
                    <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                        <Waveform
                            size={40}
                            lineWeight={3.5}
                            speed={1}
                            color="blue"
                        />
                    </Box>
                    : (
                        <>

                            <div class="title-sec">

                                {
                                    list.data ?
                                        <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >
                                            <PageHeader title={LabelType && LabelType} total={list.data?.length} />

                                            {showModal &&
                                                <Create key={refresh}
                                                    onNew={handleCreate}
                                                    onUpdate={handleUpdate}
                                                    editId={editId}
                                                    setEditId={setEditId}
                                                    disableCtnBtn={true}
                                                    setModal={setModal}
                                                />
                                            }
                                        </Card>
                                        : null
                                }
                            </div>

                            <Card sx={{
                                m: 0,
                            }} variant="outlined" class="lead-table" >
                                {typeof list.data === "object" &&
                                    <DataGrid
                                        sx={{
                                            width: '100%',
                                        }}
                                        paginationMode="server"
                                        rows={list.data}
                                        paginationModel={paginationModel}
                                        columns={columns}
                                        rowCount={list?.total}
                                        rowHeight={70}
                                        autoHeight={true}
                                        scrollbarSize={10}
                                        density="comfortable"
                                        onPaginationModelChange={handlePageChange}
                                        disableColumnMenu
                                        disableRowSelectionOnClick
                                        disableExtendRowFullWidth
                                        loading={TableLoading}
                                    />
                                }
                            </Card >
                        </>
                    )}
            </div >


        </div >

    )
};
