import React from 'react';
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { alpha } from "@mui/material/styles";
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Leads } from '../../api/Endpoints/Leads';
import { toast } from 'react-toastify';
const DateInput = (props) => {
    const { id, name, control, label, setValue, func } = props;
    let textFiledStyles = {
        '& .MuiFilledInput-root': {
            backgroundColor: 'background.paper',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'neutral.300',
            borderRadius: 1,
            boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
            overflow: 'hidden',
            p: 0,
            transition: (theme) => theme.transitions.create([
                'border-color',
                'box-shadow'
            ]),
            '&:before': {
                borderBottom: 0
            },
            '&:hover': {
                backgroundColor: 'background.paper'
            },
            '&.Mui-focused': {
                backgroundColor: 'background.paper',
                boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
            },
            '&.Mui-disabled': {
                backgroundColor: 'action.disabledBackground',
                boxShadow: 'none',
                borderColor: alpha('#D6DBE1', 0.5)
            },
            '.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
                mt: 0,
                ml: 1.5
            }
        },
    };

    let InputLabelProps = {
        shrink: true,
        sx: {
            color: 'text.primary',
            fontSize: 14,
            fontWeight: 500,
            mb: 0.5,
            position: 'relative',
            transform: 'none'
        }
    };

    let inputProps = {
        sx: {
            alignItems: 'center',
            display: 'flex',
            fontSize: 14,
            height: 'unset',
            lineHeight: 1.6,
            px: 1.5,
            py: 0.75,
            '&.MuiInputBase-inputAdornedStart': {
                pl: 0
            }
        }
    };

    const handleDateChange = (date) => {
        let dateformate = moment(date).format('YYYY-MM-DD');
        setValue(name, dateformate); // Set the value in the form state using the provided name and date
        let action
        const followupDateChanger = {
            id: id,
            follow_up_date: dateformate
        }
        action = Leads.update(followupDateChanger).then((response) => {
            let dateformate = moment(response.data.lead.follow_up_date).format('DD-MM-YYYY');
            toast.success(
                <span>
                    The Follow Up Date has been updated <br />to <strong>{dateformate}</strong>
                </span>,
                { autoClose: 2000 }
            );
            props.func && props.func()
        }).catch((err) => {

        })




    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
                name={name}
                control={control}
                defaultValue={props.value}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DesktopDatePicker
                        label={label}
                        value={value}
                        onChange={handleDateChange} // Call the handleDateChange function when the date is changed
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={textFiledStyles}
                                InputLabelProps={InputLabelProps}
                                placeholder='DD-MM-YYYY'
                                variant="filled"
                                value={value ? moment(value).format('DD-MM-YYYY') : ''}
                                helperText={error ? error.message : null}
                                error={!!error}
                                inputProps={inputProps}
                                style={{ cursor: 'none' }}
                            />
                        )}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default DateInput;

