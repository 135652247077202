import React from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import { RefreshOutlined } from "@mui/icons-material";

const PageHeader = (props) => {
    return (
        <Grid class="sec-hd" sx={{ mx: 2, my: 2 }}>


            <Typography
                color="textPrimary"
                variant="h4"
            >
                {props.title} {props.total > 0 && <span>({props.total})</span>}

                {props.refresh &&
                    <Button onClick={() => props.onRefresh()}> <RefreshOutlined /></Button>
                }
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

        </Grid >
    );
};

export default PageHeader;
