import React, { Fragment, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, Grid,
    InputLabel,
    TextField,
    Slide, Typography, Box, CircularProgress
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from '../../../components/Form/TextInput';
import DateIn from '../../../components/Form/Date';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import SelectX from '../../../components/Form/SelectX';
import { ErrorMessage } from '@hookform/error-message';
import AddIcon from "@mui/icons-material/Add";
import CurrentDateInput from '../../../components/Form/CurrenDateInput';
import { Leads } from '../../../api/Endpoints/Leads'
import moment from 'moment';
import { fetchAssignees, fetchProjects, fetchLeads, fetchStatus, fetchLeadSources, fetchCountries, fetchAges, fetchAgeList, fetchProfessionals, fetchCampaginName } from '../../../components/CommonFunctions/DropdownLists';
import LabelsList from '../../../components/CommonFunctions/LabelsList';
import { DynamicdropdownsAPI } from '../../../api/Endpoints/DynamicDropdown';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});







const Create = (props) => {


    const scheme = yup.object().shape({
        name: yup.string().required("customer name is required"),
        crm_lead_type_id: yup.object().required("Please choose a Lead Type").typeError("Please choose a Lead Type"),
        project: yup.object().required("Please choose a Lead Type").typeError("Please choose a project"),
        crm_status: yup.object().required("status is required").typeError("status is required"),

    })


    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, clearErrors, getValues, reset } = useForm({
        resolver: yupResolver(scheme), defaultValues: {
            ip_address: '',

        },
        mode: 'all'
    })

    const [open, setOpen] = React.useState(false);
    const [ipAddress, setIpAddress] = useState('');
    const [Loading, setLoading] = useState(false)
    const [statusLIst, setStatusList] = useState([])

    const isManager = localStorage.getItem('def_') === "Manager";
    const isEdit = props.editId > 0;

    const { fetchLabels } = LabelsList();

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
            props.setModal && props.setModal(false)
        }, 200)

        reset()
    };

    const handleClickOpen = () => {
        props.onNew()
    };
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });









    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });
        const followdate = data.follow_up_date != null ? moment(data.follow_up_date).format("YYYY-MM-DD") : '';
        const requestDate = data.requested_date != null ? moment(data.requested_date).format("YYYY-MM-DD") : '';
        if (data.status === "New Enquiry") {
            data.status = "Open"
        }

        const dataToSubmit = {
            id: props.editId,
            ip_address: ipAddress,
            user_agent: navigator.userAgent,
            assigned_to_user: data.assign_to?.id,
            name: data.name,
            phone_number: data.phone_number,
            email: data.email,
            location: data.location,
            remarks: data.remarks,
            crm_lead_type_id: data.crm_lead_type_id?.id || null,
            project_id: data.project?.id,
            follow_up_date: followdate,
            remarks: data.remarks,
            source: data?.source?.name || null,
            referred_by: data.referred_by,
            assigned_to: data.assign_to?.id,
            requested_date: requestDate,
            crm_status: data.crm_status?.slug,
            labels_id: data.lables_id && data.lables_id.length > 0 ? data.lables_id.map((item) => item.id) : null,
            age_range: data.age?.name || null,
            country_id: data.country?.id || null,
            profession: data?.profession?.name || null,
            campaign_name: data?.campaign_name?.name || null
        }

        let action;
        if (props.editId > 0) {
            action = Leads.update(dataToSubmit)
        } else {

            action = Leads.add(dataToSubmit);
        }

        action.then(async (response) => {
            if (response.data.message === "Updated") {
                toast.success("Lead Successfully Updated!", { autoClose: 1000 });
            } else {
                try {
                    toast.success("Lead Successfully Saved!", { autoClose: 1000 });
                } catch (error) {
                    console.error("Error:", error);

                }
            }


            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
            handleClose()
            props.onUpdate();
        }).catch(errors => {
            toast.error("Internal Server Error, please try again later!", { autoClose: 1000 })
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
        })


    }


    async function getIP(retryCount = 0) {
        try {
            const response = await fetch('https://api.ipify.org/?format=json');
            const data = await response.json();
            setIpAddress(data.ip);
        } catch (err) {
            console.log(err);
            if (retryCount < 2) {
                retryCount++;
                getIP(retryCount);
            } else {
                console.log("Something went wrong. Failed to retrieve IP address.");
            }
        }
    }




    const fetchLeadDetails = async (retryCount = 2) => {
        setLoading(true);

        const fetchData = async (retries) => {
            try {
                const response = await Leads.getDetails({ id: props.editId });
                const statusLIst = await DynamicdropdownsAPI.get();
                if (response.data.lead) {
                    const lead = response.data.lead;
                    const crmStatus = statusLIst.data.crm_status;

                    const matchedStatus = crmStatus.find(status => status.id === Number(lead.crm_status));
                    let labels;
                    if (lead.label) {
                        labels = lead.label.map((item) => item);
                    }

                    setValue('email', lead?.email);
                    setValue('crm_lead_type_id', lead?.crm_lead_type);
                    setValue('name', lead?.name);
                    setValue('phone_number', lead?.phone_number);
                    setValue('project', lead?.project);
                    setValue('source_url', lead.source_url);
                    setValue('location', lead?.location);
                    setValue('remarks', lead?.remarks);
                    setValue('created_at', lead.created_at);
                    setValue('follow_up_date', lead.follow_up_date);
                    setValue('profession', lead.profession);
                    setValue('referred_by', lead.referred_by);
                    setValue('assign_to', lead.assigned_to_details);
                    setValue('crm_status', matchedStatus);
                    setValue('country', lead?.country);
                    setValue('city', lead?.city);
                    setValue('campaign_name', lead?.campaign_name);
                    if (lead?.age_range != null) {
                        setValue('age', {
                            id: 0,
                            name: lead?.age_range
                        });
                    }
                    if (lead?.profession != null) {
                        setValue('profession', {
                            id: 0,
                            name: lead?.profession
                        });
                    }
                    if (lead.label) {
                        setValue("lables_id", labels);
                    }
                    if (lead.campaign_name) {
                        setValue('campaign_name',
                            {
                                id: 0,
                                name: lead?.campaign_name
                            })
                    }
                    if (lead.source) {
                        setValue('source',
                            {
                                id: 0,
                                name: lead?.source
                            })
                    }
                }

                setLoading(false);
            } catch (err) {
                console.log(err.message);

                if (retries > 0) {
                    setTimeout(() => {
                        fetchData(retries - 1);
                    }, 1000);
                } else {
                    setLoading(false);
                    console.log("Failed to fetch details");
                }
            }
        };

        fetchData(retryCount);
    };


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchLeadDetails()
            fetchStatus().then(res => setStatusList(res))
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        } else {
            return
        }

    }, [props.editId])


    useEffect(() => {
        getIP()
    }, []);

    return (
        <div>


            {!props.disableCtnBtn &&


                <Button class="create-btn2" variant="contained" onClick={handleClickOpen} >
                    {props.icon ? <AddIcon style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }} /> : "Create Lead"}
                </Button>

            }
            {open && (
                <Dialog
                    open={open}
                    PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    {Loading ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '30vh'
                        }}>
                            <CircularProgress />
                        </Box >
                    ) : (
                        <div class="create-frm" >

                            <DialogTitle class="form-title">
                                <Button onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="rgba(0,0,0,1)"></path></svg></Button>
                                {parseInt(props.editId) > 0 ? "Edit Lead" : "Create Lead"}</DialogTitle>

                            <DialogContent class="right-form">

                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <ErrorMessage
                                        errors={errors}
                                        name="multipleErrorInput"
                                        render={({ messages }) =>
                                            messages &&
                                            Object.entries(messages).map(([type, message]) => (
                                                <p key={type}>{message}</p>
                                            ))
                                        }
                                    />{open &&
                                        <>
                                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                                <Grid item sm={6}>
                                                    <SelectX
                                                        options={fetchLeads}
                                                        label="Lead Type"
                                                        name={'crm_lead_type_id'}
                                                        defaultValue={watch('crm_lead_type_id')}
                                                        control={control}
                                                        error2={errors?.crm_lead_type_id?.message}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <SelectX
                                                        options={fetchProjects}
                                                        label="Project"
                                                        name={'project'}
                                                        defaultValue={watch('project')}
                                                        control={control}
                                                        // type={isManager === "Manager" && props.editId > 0 ? '' : 'read-only'}
                                                        error2={errors?.project?.message}
                                                        type={isEdit && !isManager && "read-only"}
                                                    />
                                                    {isEdit && !isManager && <Typography fontSize={12} mt={0.5}>only the manager is able to edit the project</Typography>}
                                                </Grid>

                                            </Grid>

                                            <Grid sx={{ mt: 1 }} item xs={12} mb={2}>

                                                <SelectX
                                                    // ismultiple
                                                    options={fetchAssignees}
                                                    label="Assign To"
                                                    name={'assign_to'}
                                                    defaultValue={watch('assign_to')}
                                                    control={control}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {errors.lead_type && <Typography sx={{ color: "red", mt: 1, fontSize: '13px' }}>{errors.lead_type.message}</Typography>}


                                    <Grid container spacing={2} sx={{ display: 'flex' }}>

                                        <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                                            <TextInput control={control} name="name"
                                                label="Customer Name"
                                                value={watch('name')} />
                                        </Grid>

                                        <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                                            <TextInput control={control}
                                                name="phone_number"
                                                label="Phone Number"
                                                value={watch('phone_number')} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ display: 'flex', mb: 1 }}>
                                        <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                                            <TextInput control={control} name="email"
                                                label="Email"
                                                value={watch('email')}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <SelectX
                                                options={fetchAgeList}
                                                label="Age"
                                                name={'age'}
                                                defaultValue={watch('age')}
                                                control={control}

                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} >

                                        <Grid item xs={12} sm={6} mb={2}>
                                            <SelectX
                                                options={fetchProfessionals}
                                                label="Professions
                                        "
                                                name={'profession'}

                                                defaultValue={watch('profession')}

                                                control={control}
                                            />
                                        </Grid>
                                        {open &&
                                            <>
                                                <Grid Grid item xs={12} sm={6}>

                                                    <SelectX
                                                        ismultiple
                                                        options={fetchLabels}
                                                        label="Labels"
                                                        name={'lables_id'}
                                                        defaultValue={watch('lables_id')}
                                                        control={control}

                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    <Grid container spacing={2} mb={2}>
                                        <Grid item xs={12} sm={6}>
                                            <SelectX
                                                options={fetchCountries}
                                                label="Country"
                                                name={'country'}
                                                defaultValue={watch('country')}
                                                control={control}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} >
                                            <TextInput control={control} name="city"
                                                label="City"
                                                value={watch('city')} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} >
                                        {open &&
                                            <Grid Grid sx={{ mt: 1 }} item xs={12} sm={6}>

                                                <SelectX
                                                    // ismultiple
                                                    options={fetchStatus}
                                                    label="Status"
                                                    name={'crm_status'}
                                                    defaultValue={watch('crm_status')}
                                                    control={control}
                                                    error2={errors?.crm_status?.message}
                                                />
                                            </Grid>
                                        }
                                        <Grid Grid sx={{ mt: 1 }} item xs={12} sm={6}>

                                            <SelectX
                                                // ismultiple
                                                options={fetchLeadSources}
                                                type={isEdit && "read-only"}
                                                label="Lead Source"
                                                name={'source'}
                                                isSearchable={false}
                                                defaultValue={watch('source')}
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>
                                        <Grid item xs={12} sm={6}>
                                            <CurrentDateInput
                                                control={control}
                                                name="requested_date"
                                                label="Requested Date"
                                                value={watch('requested_date')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <DateIn
                                                control={control}
                                                name="follow_up_date"
                                                label="Next Follow Up Date"
                                                value={watch('follow_up_date')}
                                            // readOnly={props.editId ? true : false}
                                            />
                                        </Grid>

                                    </Grid>
                                    {/* <Grid Grid sx={{ mt: 1 }} item xs={12} sm={6}>

                                        <SelectX
                                            // ismultiple
                                            options={''}
                                            label="Agent"
                                            name={'agent'}
                                            defaultValue={watch('agent')}
                                            control={control}
                                        />
                                    </Grid> */}



                                    <Grid container spacing={2} mt={1}>
                                        <Grid item sm={6}>
                                            <SelectX
                                                options={fetchCampaginName}
                                                label="Campaign Name"
                                                name={'campaign_name'}
                                                defaultValue={watch('campaign_name')}
                                                control={control}

                                            />
                                        </Grid>
                                        <Grid sx={{ pt: 5 }} item xs={12} sm={6} >
                                            <TextInput control={control} name="referred_by"
                                                label="Referred By"
                                                value={watch('referred_by')} />
                                        </Grid>





                                    </Grid>
                                    < Grid sx={{ mt: 2 }} item xs={12}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: 1
                                        }}>Description </InputLabel>
                                        <TextField
                                            {...register('remarks')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            sx={{ width: '100%', }}

                                        />

                                    </Grid>
                                    <Grid sx={{ p: 1 }} item xs={12}>
                                        <LoadingButton class="main-btn2" loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                    </Grid>



                                </form>


                            </DialogContent>
                        </div >
                    )
                    }
                </Dialog >

            )
            }
        </div >

    );
};

export default Create
