import { Button, Grid, IconButton, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import Create from '../create';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

function ExtraData({ data }) {
    if (data?.extra_data == null) {
        return null
    }
    const parsedData = JSON.parse(data?.extra_data);
    const keyValuePairs = Object.entries(parsedData).map(([key, value]) => (
        { key, value }
    ));

    return (
        <div className="pop-det-cntr source extradata sourse-list">
            <div className="source-list extradata">

                {keyValuePairs.map(({ key, value }, index) => (
                    <Fragment key={index}>
                        <div class="pop-det-text"  >
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                {key}:</Typography>
                            <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                {value}
                            </Typography>

                        </div>
                    </Fragment>

                ))}
                <div class="pop-det-text"  >
                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                        Source URL:</Typography>
                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                        {data.source_url}
                    </Typography>

                </div>

            </div>
        </div >
    );
}

export default ExtraData;
