import {
  Box,
  Divider, Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import logimg from '../../assets/log-right.png';
import useimg1 from '../../assets/Male.png';
import useimg2 from '../../assets/Male2.png';
import useimg3 from '../../assets/Female.png';
import useimg4 from '../../assets/Female2.png';


import { CheckCircleOutlined as CheckCircleIcon } from '../../icons/check-circle-outlined';

const features = [
  'Task Management',
  'Daily Attendance',
  'Project Management',
]



export const ProductFeatures = () => (
  <div class="login-left">
    <h4 >
      Desai Lead Management
    </h4>

    <p  >
      As you enter your username and password, you're one step closer to unlocking the full potential of our website. Enter your credentials confidently, knowing that our robust security measures safeguard your information.
    </p>


    <img src={logimg} class="log-img" />
    {/* <Typography
      color="textSecondary"
      sx={{
        mb: 3,
        mt: 1
      }}
      variant="body2"
    >
      For us, handling tasks is a piece of cake
    </Typography> */}
    {/* <List sx={{ py: 2 }}>
      {features.map((feature) => (
        <ListItem
          disableGutters
          key={feature}
        >
          <ListItemIcon
            sx={{
              minWidth: 'auto',
              mr: 1
            }}
          >
            <CheckCircleIcon sx={{ color: 'success.main' }} />
          </ListItemIcon>
          <ListItemText primary={feature} />
        </ListItem>
      ))}
    </List> */}
    <Divider />

    <Box
      sx={{
        alignItems: 'end',
        display: 'flex',
        '& img': {
          ml: 1
        }
      }}
    >



    </Box>
  </div >
);
