import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Notes } from '../../../../api/Endpoints/Notes';
import { toast } from 'react-toastify';
import moment from 'moment';
import Delete from '../../../../components/Popup/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import CurrentDateInput from '../../../../components/Form/CurrenDateInput';
import DateIn from '../../../../components/Form/Date';


export default function AddNotes({ viewID }) {
    const schema = yup.object().shape({
        notes: yup.string().required('Field is required'),
    });


    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const [listNotes, setListNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [DeleteID, setDeleteID] = useState(0)
    const [refresh, setRefresh] = useState()
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [editedNote, setEditedNote] = useState("");

    const fetchNotes = () => {
        setLoading(true);
        Notes.getNotes({ id: viewID })
            .then((response) => {
                setListNotes(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const TableUpdate = () => {
        setRefresh(Math.random)
    }
    useEffect(() => {
        fetchNotes();
    }, [viewID, refresh]);

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        const noteDate = data.note_date != null ? moment(data.note_date).format("YYYY-MM-DD") : '';
        const dataToSubmit = {
            id: viewID,
            notes: data.notes,
            note_date: noteDate
        };

        Notes.add(dataToSubmit)
            .then((response) => {
                if (response.data.status === true) {
                    toast.success('Note assigned successfully');
                    setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
                    reset(); // Reset the form after successful submission
                    TableUpdate()
                }
            })
            .catch((err) => {
                console.error(err);
                setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            });
    };


    const handleDelete = (id) => {
        setDeleteID(id)
    };

    const handleEdit = (id, note, date) => {
        setValue('edit_date', date)
        setEditingNoteId(id);
        setEditedNote(note);
    };

    const handleSaveEdit = (id) => {
        const noteDate = watch('edit_date') != null ? moment(watch('edit_date')).format("YYYY-MM-DD") : '';

        Notes.UpdateNotes({ id: id, notes: editedNote, note_date: noteDate })
            .then((response) => {
                if (response.data.status === true) {
                    fetchNotes();
                    setEditingNoteId(null);
                    setEditedNote("");
                    toast.success('Note updated successfully');
                }
            })
            .catch((err) => {
                console.error(err);
                setEditingNoteId(null);
                setEditedNote("");
            });
    };

    return (
        <>
            <Delete
                DeleteID={DeleteID}
                setDeleteID={setDeleteID}
                Callfunc={fetchNotes}
                url={'/lead/delete-note'}
                title={'Note'}
            />
            <Grid item xs={12} sm={6} className='tab-notes'>
                <CurrentDateInput
                    control={control}
                    name="note_date"
                // readOnly={props.editId ? true : false}
                />
            </Grid>
            <div className="pop-note">
                <form className='add-note-block-content' onSubmit={handleSubmit(onSubmit)}>

                    <Grid className='add-note-block' container alignItems="center" sx={{ p: 1 }} item xs={12}>
                        <TextField className='add-note-block-textarea'
                            sx={{ width: '70%' }}
                            {...register('notes')}
                            label="Add Note"
                            size="medium"
                            multiline
                            rows={4}
                            id="outlined-multiline-static"
                            error={Boolean(errors.notes)}
                        />
                        <LoadingButton id="notes-add-btn" loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                    </Grid>
                </form>
                <div className='talk-bubble-block'>
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '30vh',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <TableContainer className='note-result-block'>
                            <Table>
                                <TableBody>
                                    {listNotes.length > 0 && <Typography textTransform={'uppercase'}>Notes</Typography>}
                                    {listNotes.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell className='note-content'>

                                                {editingNoteId === item.id ? (
                                                    <>
                                                        <TextField
                                                            value={editedNote}
                                                            onChange={(e) => setEditedNote(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                            variant="outlined"
                                                        />
                                                        <Grid item xs={12} sm={6}>
                                                            <DateIn
                                                                control={control}
                                                                name="edit_date"
                                                                value={watch('edit_date')}
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    item.notes
                                                )}

                                            </TableCell>
                                            <TableCell className='note-name'>
                                                {item.created_user.name}
                                                <TableCell>{moment(item.note_date).format('DD MMM, YYYY')}</TableCell>
                                            </TableCell>
                                            <TableCell>
                                                {editingNoteId === item.id ?
                                                    <Button onClick={() => handleSaveEdit(item.id)} variant='outlined'>Save</Button> :
                                                    <IconButton onClick={() => handleEdit(item.id, item.notes, item?.note_date)}>
                                                        <EditIcon className="edit-icon" style={{ color: 'blue' }} />
                                                    </IconButton>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleDelete(item.id)}>
                                                    <DeleteIcon className="delete-icon" style={{ color: 'red' }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer >
                    )}
                </div >
            </div >
        </>
    );
}
