import React from 'react';
import {
    Chart as ChartJS, ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from "react-chartjs-2";


ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {

    const data = {
        labels: props.data.map(item => item.name),
        datasets: [
            {
                data: props.data.map(item => item.count),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],

                borderWidth: 2,
            },
        ],
    };

    return (
        <Doughnut data={data} />
    );
};

export default DoughnutChart;
