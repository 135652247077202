
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { DeleteApI } from '../../api/Endpoints/DeleteAPI';
import { toast } from 'react-toastify';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function Delete({ DeleteID, setDeleteID, Callfunc, url, title, setRefresh, SidebarRefreshContext, close }) {

    const [open, setOpen] = React.useState(false);
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: 'Delete',
        loading: false,
        disabled: false,
    });

    const handleClose = () => {


        setOpen(false);
        // close(false)
        setTimeout(() => {
            setDeleteID(0);
        }, 100)

    };

    useEffect(() => {
        if (DeleteID > 0 || DeleteID.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [DeleteID]);

    const DeleteHandler = async () => {

        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

        try {

            let response = await DeleteApI.deletedata({ url: url, id: DeleteID });

            if (response.data.status) {
                toast.success(`${title} has been successfully removed.`, { autoClose: 1000 });
                handleClose()
                Callfunc && Callfunc()
                setRefresh && setRefresh(Math.random)
                SidebarRefreshContext && SidebarRefreshContext(Math.random)
                setFormButtonStatus({ label: "Delete", loading: false, disabled: false });
            } else {
                toast.error('An error occurred. Please try again later', { autoClose: 2000 })
                setFormButtonStatus({ label: "Delete", loading: false, disabled: false });
            }

        }


        catch (error) {
            console.log("err", error);
            toast.error('An error occurred. Please try again later.', { autoClose: 2000 });
            setFormButtonStatus({ label: "Delete", loading: false, disabled: false });
        }


    };


    const memoizedOpen = useMemo(() => open, [open]);

    return (
        <div>
            <Dialog
                open={memoizedOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        transform: 'translateY(-75%)',
                    },
                }}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete {title}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="inherit">
                        Cancel
                    </Button>
                    <LoadingButton onClick={() => DeleteHandler()} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" color='error' type="submit">{formButtonStatus.label}</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
