
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { DeleteApI } from '../../api/Endpoints/DeleteAPI';
import { toast } from 'react-toastify';
import { TriageLeadsAPI } from '../../api/Endpoints/TriageLeadsApi';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function LeadConvertPopup({ ProjectID, setProjectID, triagID, setTriageID, setShowPopup, func, frmDetailsPage }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: 'Convert',
        loading: false,
        disabled: false,
    });

    const handleClose = () => {


        setOpen(false);

        setTimeout(() => {
            setProjectID(0);
            setTriageID(0)
            setShowPopup(false)
        }, 100)

    };

    useEffect(() => {
        if (ProjectID > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [ProjectID]);



    const confirmConvertion = async () => {

        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

        const res = await TriageLeadsAPI.save({ triage_id: triagID, project_id: ProjectID })
        if (res.data.status == true) {
            handleClose(false)
            toast.success('Successfully Converted', { autoClose: 1000 })
            func && func()
            if (frmDetailsPage) {
                navigate(-1)
            }
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        } else {
            toast.error('Error: Conversion Failed')
            handleClose(false)
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }
    }


    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        transform: 'translateY(-75%)',
                    },
                }}
            >
                <DialogTitle>Confirm Conversion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to convert this into Lead?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='inherit'>Cancel</Button>
                    <LoadingButton onClick={() => confirmConvertion()} loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                        variant="outlined" color='primary' type="submit">{formButtonStatus.label}</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
