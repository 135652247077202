import { useEffect, useState } from 'react';
import { Leads } from '../../api/Endpoints/Leads';
import { Projects } from '../../api/Endpoints/project';

const useProjects = () => {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchProjects = async () => {
        setIsLoading(true);
        try {
            const response = await Projects.get();
            setProjects(response.data.projects);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    return { projects, isLoading, fetchProjects };
};

export default useProjects;


