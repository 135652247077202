import { Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import Create from '../create';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
function Source({ viewID, sources, func, hideEditBtn }) {
    const [editId, setEditId] = useState(undefined);

    const [Modal, setModal] = useState(false);

    const handleEdit = () => {
        setEditId(parseInt(viewID));
        setModal(true)

    }
    const handleUpdate = () => {
        func()
    }
    return (
        <div class="pop-det-cntr source sourse-list">
            {hideEditBtn !== true &&
                <Grid display={'flex'} justifyContent={'end'}>
                    <IconButton onClick={handleEdit} sx={{
                        border: '1px solid #5046e4',
                        padding: '4px'
                    }}><ModeEditOutlineIcon sx={{ color: '#5046e4' }} /></IconButton>
                </Grid>
            }

            {
                Modal &&
                <Create
                    onUpdate={handleUpdate}
                    editId={editId}
                    setEditId={setEditId}
                    setModal={setModal}
                    disableCtnBtn={true} />
            }



            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Landing Page URL:
                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.landing_page_url}
                </Typography>
            </div>





            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Source:

                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.source}
                </Typography>
            </div>
            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Adset:

                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.adset}
                </Typography>
            </div>
            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Ad Name:

                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.ad_name}
                </Typography>
            </div>
            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Og Source URL:

                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.og_source_url}
                </Typography>
            </div>
            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Utm source:
                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.utm_source}
                </Typography>
            </div>
            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    User Agent:

                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.user_agent}
                </Typography>
            </div>
            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Agent:

                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>

                </Typography>
            </div>
            <div class="pop-det-text source-list">
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Referred by:

                </Typography>
                <Typography variant="body2" style={{ paddingLeft: 10, fontSize: '16px' }}>
                    {sources?.referred_by}
                </Typography>
            </div>
        </div >
    )
}

export default Source
