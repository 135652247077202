import { Leads } from '../../../api/Endpoints/Leads'
import { Fragment, useEffect, useState } from 'react';
import { Backdrop, Box, Button, Card, CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import CustomToolbar from '../../../components/Tools/CustomeToolbar';
import { useForm } from 'react-hook-form';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Restore from '../../../components/Popup/Restore';
import RestoreIcon from '@material-ui/icons/Restore';
import StatusSelector from '../../../components/Form/Status';
import { RecycleAPI } from '../../../api/Endpoints/RecycleBin';
import { Waveform } from '@uiball/loaders';
import { GlobalRefreshContext } from '../../../contexts/GlobalRefresh/GlobalRefresh';
import StatusLIstFunction from '../../../components/CommonFunctions/statusLIst';


export default function Recyclebin() {


    const columns = [

        {
            field: 'name',
            headerName: 'Customer Name',
            width: 300,
            renderCell: (params) => (

                <span
                    style={{ whiteSpace: 'break-spaces' }}                >

                    {params.row.name}
                </span>
            ),
        },

        {
            field: 'assigned_to',
            headerName: 'Assign To',
            width: 280,
            renderCell: (params) => (
                <>
                    <Button size='small' style={{ color: '#6f6f6f' }}>
                        {params.row?.assigned_to_details?.name || "Not Assigned"}
                    </Button>
                </>
            ),



        },

        {
            field: 'created_at',
            headerName: 'Date',
            width: 300,
            renderCell: (params) => {
                const formattedDate = moment.utc(params.row.created_at).format('DD-MM-YYYY');
                return <Typography style={{ color: '#6f6f6f' }}>{formattedDate}</Typography>;
            },
        },
        {
            field: 'project_name',
            headerName: 'Project Name',
            width: 150,
            renderCell: (params) => {
                return <Typography style={{ color: '#6f6f6f' }}>{params.row?.project?.name}</Typography>;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 230,
            renderCell: (params) => {
                return (
                    <StatusSelector
                        statusList={statusList}
                        options={params.row?.crm_status_details}
                        id={params.id}
                        isDisabled={true}
                    />
                )
            }
        },
        {
            width: 50,
            renderCell: (params) => (
                <RestoreIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setRestoreID(params.id);
                    }}
                />
            )
        }
    ]


    const { watch, formState: { errors }, control, Controller, setValue, } = useForm({})



    const [Bin, setRecyclebin] = useState([])
    const [RestoreID, setRestoreID] = useState(0)
    const [loading, setLoading] = useState(false);
    const { GlobalRefresh } = GlobalRefreshContext();
    const { statusList, fetchStatusList, } = StatusLIstFunction();
    const leadTypeData = JSON.parse(localStorage.getItem('Lead_type'));
    const projectData = JSON.parse(localStorage.getItem('project'));
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 15,
        page: 0,
    });
    const fetchRecycleBin = () => {
        setLoading(true);
        RecycleAPI.get({
            page: parseInt(paginationModel.page) + 1,
            crm_lead_type_id: leadTypeData?.ID,
            project_id: projectData.ID
        }).then(response => {
            setRecyclebin(response.data?.leads)
            setLoading(false);
        }).catch(error => {
            console.log(error.message);
            setLoading(false);
        })
    }




    useEffect(() => {
        fetchRecycleBin();
        fetchStatusList()
    }, [paginationModel, GlobalRefresh]);




    return (

        <div class="page-cntr">



            <div class="lead-table recyclebin-table recy-page-cntr">

                {/* Popup's  */}


                <Restore RestoreID={RestoreID} setRestoreID={setRestoreID} fetchRecycleBin={fetchRecycleBin} />

                <div class="title-sec">
                    <PageHeader title={'Recycle Bin'} total={!loading && Bin.total} />
                    {
                        Bin.data ?
                            <Card sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}  >

                            </Card>
                            : null
                    }
                </div>


                {
                    loading ?
                        <Box sx={{ width: '100%', marginTop: 10, height: 80, display: 'flex', justifyContent: 'center' }}>

                            <Waveform
                                size={40}
                                lineWeight={3.5}
                                speed={1}
                                color="blue"
                            />
                        </Box>
                        : (
                            <>
                                {Bin.data?.length > 0 ? (
                                    <Fragment>
                                        < Card sx={{
                                            m: 0,
                                        }} variant="outlined" class="lead-table" >


                                            {
                                                typeof Bin === "object" &&
                                                <DataGrid
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                    paginationMode="server"
                                                    rows={Bin?.data}
                                                    paginationModel={paginationModel}
                                                    columns={columns}
                                                    rowCount={Bin?.total}
                                                    rowHeight={70}
                                                    autoHeight={true}
                                                    scrollbarSize={10}
                                                    density="comfortable"
                                                    onPaginationModelChange={setPaginationModel}
                                                    disableColumnMenu
                                                    disableRowSelectionOnClick
                                                    disableExtendRowFullWidth
                                                />
                                            }

                                        </ Card>
                                    </Fragment>
                                ) : (

                                    <Box sx={{ width: '100%', marginTop: 15, height: 20, display: 'flex', justifyContent: 'center' }}>
                                        <Typography fontWeight={500}> Recycle Bin is empty</Typography>
                                    </Box>
                                )}

                            </>
                        )}
            </div >
        </div >

    )
};
